import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';

import CloseIcon from '@material-ui/icons/Close';

import useStyles from './MockResponseDialogueStyle';
import { SERVER_PATH, MOCK_AUDIO_PATH, MOCK_RESPONSE_PATH } from '../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const MockResponseDialogue = (props) => {
	const classes = useStyles();
	const { open, onClose, dialogueData } = props;
	const [pageTitle, setPageTitle] = useState('Dialogue Details');

	const responsePath = SERVER_PATH + MOCK_RESPONSE_PATH;
	const audioPath = SERVER_PATH + MOCK_AUDIO_PATH;

	useEffect(() => {
		// console.log(dialogueData);
		setPageTitle((dialogueData.title) ? dialogueData.title : 'Dialogue Details');
	}, [dialogueData]);


	return (
		<Dialog fullScreen open={open} onClose={() => onClose()} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography color="inherit" variant="h5" className={classes.title}>
						{ pageTitle } 
					</Typography>
				</Toolbar>
			</AppBar>
			<div className={classes.root}>
				<Paper className={classes.content}>
					<form className={classes.form}>
						<Typography variant="subtitle2" className={classes.fieldTitle}>Dialogue Title</Typography>
						<Typography variant="h6">{dialogueData.title}</Typography>
						<Typography variant="subtitle2" className={classes.fieldTitle}>Description</Typography>
						<Typography variant="h6">{dialogueData.description}</Typography>
						
						{dialogueData.mock_dialogue_segments ? (
							dialogueData.mock_dialogue_segments.map((audioSegment, index) => (
								<div className={classes.segment} key={index}>
									<Typography variant="subtitle2" className={classes.fieldTitle}>Name</Typography>
									<Typography variant="h6">{audioSegment.name}</Typography>

									<Typography variant="subtitle2" className={classes.fieldTitle}>Question Audio</Typography>
									{ audioSegment.audio_path ? (
										<audio controls src={audioPath + audioSegment.audio_path} controlsList="nodownload" className={classes.audioComponent} />
									) : null }
									<Typography variant="subtitle2" className={classes.fieldTitle}>Transcript</Typography>
									<Typography variant="h6">{audioSegment.transcript ? audioSegment.transcript : '-'}</Typography>

									<Typography variant="subtitle2" className={classes.fieldTitle}>Sample Response</Typography>
									{ audioSegment.sample_response ? (
										<audio controls src={audioPath + audioSegment.sample_response} controlsList="nodownload" className={classes.audioComponent} />
									) : <Typography>None</Typography> }

									<Typography variant="subtitle2" className={classes.fieldTitle}>Sample Response Transcript</Typography>
									<Typography variant="h6">{audioSegment.sample_transcript ? audioSegment.sample_transcript : '-'}</Typography>

									<Typography variant="subtitle2" className={classes.fieldTitle}>Users Response</Typography>
									{ audioSegment.user_mock_test_response && audioSegment.user_mock_test_response.response ? (
										<audio controls src={responsePath + audioSegment.user_mock_test_response.response} controlsList="nodownload" className={classes.audioComponent} />
									) : <Typography variant="h6">Not Attempted</Typography> }
								</div>
							))
						) : null}
					</form>
				</Paper>
			</div>
		</Dialog>
	);
};

MockResponseDialogue.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSnackToogle: PropTypes.func.isRequired,
	dialogueData: PropTypes.object.isRequired,
};

export default MockResponseDialogue;