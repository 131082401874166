import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	pageHeader: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		}
	},
	spacer: {
		flexGrow: 1
	},
	pageTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2)
		}
	},
	titleIcon: {
		marginRight: theme.spacing(1),
	},
	input: {
		display: 'none',
	},
	content: {
		width: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	form: {
		width: '100%',
		paddingTop: theme.spacing(2),
		padding: theme.spacing(4),
	},
	textField: {
		marginTop: theme.spacing(3)
	},
	dialogueTableHeaderConatiner: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: theme.spacing(2),
	},
	dialogueTableHeader: {
		display: 'flex',
		flexDirection: 'row',
	},
	dialogueTableContainer: {
		padding: theme.spacing(1),
		paddingTop: theme.spacing(4)
	},
	dialogueTableTitle: {
		paddingLeft: theme.spacing(1),
	},
	audio: {
		marginTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	uploadButton: {
		padding: '11px 20px',
		borderRadius: 30,
		marginRight: theme.spacing(3)
	},
	audioComponent: {
		outline: 'none'
	},
	rowHover: {
		cursor: "pointer"
	},
	infoMessage: {
		paddingTop: theme.spacing(1),
		textAlign: 'center',
	},
	noDialogues: {
		backgroundColor: theme.palette.background.default,
		borderRadius: theme.spacing(1),
		padding: theme.spacing(2),
		textAlign: 'center',
	}
}));