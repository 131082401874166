import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import clsx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import useStyles from './ResetPasswordStyle';
import API from '../../axios/axiosApi'

const schema = {
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
		maximum: 64
		}
	},
	password: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 128,
			minimum: 6
		}
	},
	confirm: {
		presence: true,
		equality: {
			attribute: "password",
			message: "^Password and Confirm Password doesn't match"
		}
	},
};


const ResetPassword = props => {
	const classes = useStyles();
	const { match: { params } } = props;
	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {}
	});
	const [ errorMessage, setErrorMessage ] = useState('');
	const [ successMessage, setSuccessMessage ] = useState('');
	
	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
		...formState,
		isValid: errors ? false : true,
		errors: errors || {}
		}));
	}, [formState.values]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
		...formState,
		values: {
			...formState.values,
			[event.target.name]:
			event.target.type === 'checkbox'
				? event.target.checked
				: event.target.value
		},
		touched: {
			...formState.touched,
			[event.target.name]: true
		}
		}));
	};

	/**
	 * Handle Login request
	 * 
	 * @param {*} event 
	 */
	const handlePassReset = async (event) => {
		event.preventDefault();
		setErrorMessage('');
		try {
			const postData = { ...formState.values, hash: params.hash }
			const response = await API.post('reset_password', postData);
			if (response.data.success) {
				setSuccessMessage(response.data.message);
				// props.history.push('/');
			} else {
				const errorMsg = response.data.message || 'Invalid Credentials';
				setErrorMessage(errorMsg);
			}
		} catch (error) {
			console.log("ERROR in handlePassReset : ", error);
		}
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;


  	return (
		<Grid container component="main" className={clsx(classes.root, 'loginouter')}>
			<Container component="main" maxWidth="sm">
				<Card className={classes.card} component="main" elevation={20}>
					<CardContent className={classes.paper}>
						<Avatar className={classes.avatar}>
							<SettingsEthernetIcon />
						</Avatar>
						<Typography variant="h2">
							Reset Password
						</Typography>
						<Typography variant="subtitle1" className={classes.errorMessgae}>
							{ errorMessage }
						</Typography>
						<Typography variant="subtitle1" className={classes.successMessage}>
							{ successMessage }
						</Typography>

						<form className={classes.form} onSubmit={handlePassReset}>
							<TextField
								className={classes.textField}
								error={hasError('email')}
								fullWidth
								helperText={
									hasError('email') ? formState.errors.email[0] : null
								}
								label="Email address"
								name="email"
								onChange={handleChange}
								type="text"
								value={formState.values.email || ''}
								variant="outlined"
								required
							/>
							<TextField
								className={classes.textField}
								error={hasError('password')}
								fullWidth
								helperText={
									hasError('password') ? formState.errors.password[0] : null
								}
								label="Password"
								name="password"
								onChange={handleChange}
								type="password"
								value={formState.values.password || ''}
								variant="outlined"
								required
							/>
							<TextField
								className={classes.textField}
								error={hasError('confirm')}
								fullWidth
								helperText={
									hasError('confirm') ? formState.errors.confirm[0] : null
								}
								label="Confirm Password"
								name="confirm"
								onChange={handleChange}
								type="password"
								value={formState.values.confirm || ''}
								variant="outlined"
								required
							/>
							<Button
								className={classes.signInButton}
								color="primary"
								disabled={!formState.isValid}
								fullWidth
								size="large"
								type="submit"
								variant="contained"
							>
								Reset
							</Button>
							<Grid container>
								<Grid item xs>
									<Link href="/login" variant="body2">
										Login
									</Link>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Container>
		</Grid>
  	);
};

export default ResetPassword;
