import { Subject } from 'rxjs';

const profileChange = new Subject();
// const snackChange = new Subject();

export const handleProfileChange = {
	sendData: data => profileChange.next(data),
	receiveData: () => profileChange.asObservable(),
};

/* export const handleSnackChange = {
	sendData: (data) => snackChange.next(data),
	receiveData: () => snackChange.asObservable(),
}; */