import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import DeleteIcon from '@material-ui/icons/Delete';

import useStyles from './UserMockResponseDetailStyle';
import useCommonStyles from '../../common/style';
import API from '../../axios/axiosApi';
import { formatDate } from '../../utils/formatter';
import MockResponseDialogue from '../../components/MockResponseDialogue';
import { SERVER_PATH, MOCK_FEEDBACK_PATH } from '../../config';
import UploadingProgress from '../../components/UploadingProgress';

const UserMockResponseDetails = (props) => {
	const { match: { params }, history } = props;
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [feedbackForm, setFeedbackForm] = useState({		
		feedback: '',
		feedbackImages: [],
		errors: {
			feedback: '',
		}
	});
	const [userResponses, setUserResponses] = useState({});
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [openDialogueModal, setOpenDialogueModal] = useState(false);
	const [selectedDialogue, setSelectedDialogue] = useState({});
	const [feedbackImgs, setFeedbackImgs] = useState([]);
	const [progress, setProgress] = useState(0);
	const [openProgressDialogue, setOpenProgressDialogue] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const imageAssetPath = SERVER_PATH + MOCK_FEEDBACK_PATH;

	useEffect(() => {
		const fetchMockResponses = async () => {
			setLoading(true);
			try {
				const response = await API.get('mock_test_reponses/' + params.id);
				if (response.data.success) {
					if (!response.data.data.reponse_details) {
						handleSnackToogle("Record for this ID don't exists!");
						return history.goBack();
					}
					const responseDetails = (response.data.data.reponse_details) ? response.data.data.reponse_details : {};
					setUserResponses(responseDetails);
					setFeedbackForm(feedbackForm => ({
						...feedbackForm,
						feedback: responseDetails.feedback,
						feedbackImages: responseDetails.user_mock_feedback_images ? responseDetails.user_mock_feedback_images : [],
					}));
					const images = responseDetails.user_mock_feedback_images ? responseDetails.user_mock_feedback_images.map(img => {
						return imageAssetPath + img.path;
					}) : [];
					setFeedbackImgs(images);
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchMockResponses : ", error);
				setLoading(false);
			}
		};
		fetchMockResponses();
	}, [params, history, imageAssetPath, refresh]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = (fieldName, value) => {
		validate(fieldName, value);
		setFeedbackForm(video => ({ ...video, [fieldName]: value }));
	};

	const validate = (fieldName, value) => {
		let form = { ...feedbackForm };
		if (typeof value === 'string') {
			value = value.trim();
		}
		form.errors[fieldName] = (value === '' || value === null) ? fieldName.charAt(0).toUpperCase() + fieldName.slice(1)  + ' is required' : '';
		setFeedbackForm(form);
	};

	/**
	 * Save feedback
	 */
	const saveFeedback = async () => {
		const { feedback, feedbackImages } = feedbackForm;
		validate('feedback', feedback);
		const data = {
			id: params.id,
			feedback,
		};

		if (feedback) {
			try {
				const formData = new FormData();
				feedbackImages.forEach((img, index) => {
					if (img instanceof File) { formData.append(`img_${index}`, img, img.name); }
				});
				
				formData.append('data', JSON.stringify(data));
				try {
					setLoading(true);
					setOpenProgressDialogue(true);
					const response = await API.put('mock_test_reponses', formData, {
						headers: {'Content-Type': 'multipart/form-data'},
						onUploadProgress: (progressEvent) => {
							const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
							setProgress(progressPercentage);
						},
					});
					setOpenProgressDialogue(false);
					setProgress(0);
					if (response.data.success) {
						// console.log("response.data ==> ",response.data);
						handleSnackToogle(response.data.message);
						setRefresh(refresh => !refresh);
					} else {
						console.log("response saveFeedback ==> ",response.data);
						handleSnackToogle(response.data.message)
					}
					setLoading(false);
				} catch (error) {
					setOpenProgressDialogue(false);
					console.log("ERROR in saveFeedback : ", error.response);
					setLoading(false);
					let msg = 'Something went wrong. Please try again later.';
					if (error.response && error.response.data &&  error.response.data.error_message) {
						msg = error.response.data.error_message;
					} else if (error.response && error.response.data &&  error.response.data.message) {
						msg = error.response.data.message;
					} else if (error.message) {
						msg = error.message;
					}
					handleSnackToogle(msg);
					setProgress(0);
				}
				// setLoading(false);
			} catch (error) {
				setLoading(false);			
				console.log("ERROR in updateDialogueDetails : ", error);
			}
		}
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	/**
	 * Convert time in words format
	 * 
	 * @param {*} time 
	 */
	const convertTimeInWords = (time) => {
		if (!time) { return '0 minutes' }
		let returnTime = '';
		const split = time.split(":");
		if (split[0] && parseInt(split[0]) > 0) { returnTime = parseInt(split[0])  + ' hours ' }
		if (split[1]) { returnTime += parseInt(split[1])  + ' minutes ' }
		if (split[2] && parseInt(split[2]) > 0) { returnTime += parseInt(split[2])  + ' seconds' }
		return returnTime;		
	};

	/**
	 * Calculate number of attempted segments
	 * 
	 * @param {Array} segments 
	 * @returns {Number} attemped
	 */
	const calculateAttempedSegments = (segments) => {
		if (!segments || !segments.length) { return 0; }
		let attemped = 0
		segments.forEach(segment => {
			if (segment.user_mock_test_response && segment.user_mock_test_response.response) { attemped++; }
		})
		return attemped;
	};

	const handleDialogueToggle = (dialogue) => {
		dialogue = dialogue || {};
		setSelectedDialogue(dialogue);
		setOpenDialogueModal(openDialogueModal => !openDialogueModal);
	};

	/**
	 * Handle file change
	 * 
	 * @param {*} e 
	 */
	const handleFileChange = (e) => {
		const maxUploadSize = 10; // In MB
		const byteToMb = 1000000; //  1MB = 1000000 Bytes = 1000 * 1000 (in decimal format)

		const files = e.target.files;
		// console.log(files);	
		const fileKeys = Object.keys(files);

		if (fileKeys.length > 5) { e.target.value = null; return this.setState({ fileErrorMessage: 'Maximum of 5 images can be attached.' }); }
		if (fileKeys.length) {
			// let dataUrls = [];
			fileKeys.forEach(fileKey => {					
				if (files.hasOwnProperty(fileKey)) {
					const file = files[fileKey];				
					const fileSize = parseInt(file.size);

					const fileType = file.type;
					const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

					if (!validImageTypes.includes(fileType)) {
						return handleSnackToogle("Invalid image format");
					}
			
					if (fileSize > maxUploadSize * byteToMb) { // Number of MegaBytes;
						e.target.value = null;
						return this.setState({ fileErrorMessage: "Image size shouldn't exceed " + maxUploadSize + " MB" });
					}
					
					const reader = new FileReader();
					// reader.readAsBinaryString(file);
					reader.readAsDataURL(file);
					reader.onload = () => {
						setFeedbackImgs(feedbackImgs => [ ...feedbackImgs, reader.result]);
						setFeedbackForm(feedbackForm => ({ ...feedbackForm, feedbackImages: [ ...feedbackForm.feedbackImages, file] }));
						// console.log(reader.result);
						// console.log(`data:${file.type};base64,${btoa(reader.result)}`);
					};
					reader.onerror = (error) => {
						console.log(error);
					};
				}
			});
		}
		e.target.value = null;
		

		// uploadProfileImage(file);
		
       /*  const reader = new FileReader();
        // reader.readAsBinaryString(file);
        reader.readAsDataURL(file);
        reader.onload = () => {
			setAudioSource(reader.result)
			// console.log(`data:${file.type};base64,${btoa(reader.result)}`);
			if (type === 'edit') {
				uploadAudio(file);
			}
        };
        reader.onerror = function () {
            handleSnackToogle("Error in loading audio");
		}; */
	};

	const removeImage = (index) => {
		let feedBackFormImages = [ ...feedbackForm.feedbackImages ];
		if (feedBackFormImages[index].id) {
			deleteFeedbackImage(feedBackFormImages[index]);
		}
		let feedbackImgArray = [ ...feedbackImgs ];

		feedbackImgArray.splice(index, 1);
		feedBackFormImages.splice(index, 1);
		setFeedbackImgs(feedbackImgArray);
		setFeedbackForm(feedbackForm => ({ ...feedbackForm, feedbackImages: feedBackFormImages }));
	};

	const deleteFeedbackImage = async (imageDetails) => {
		try {
			setLoading(true);
			const response = await API.delete('mock_test_reponses/' + imageDetails.id + '/' + imageDetails.path);
			if (response.data.success) {
				handleSnackToogle(response.data.message);
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in deleteFeedbackImage : ", error);
			setLoading(false);
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<IconButton size="small" aria-label="go-back" onClick={() => props.history.goBack() }>
							<ArrowBackIcon />
						</IconButton>
						<AccessTimeIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">Mock Test Response</Typography>
					</div>
				</div>
			</div>
			<Card className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<CardHeader title="Mock Test Details"/>
				<Divider />
				<CardContent className={classes.cardContentStyle}>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<Typography variant="subtitle2">Mock Test Title</Typography>
							<Typography variant="h6">{userResponses.title}</Typography>
						</Grid>
						<Grid item md={6} xs={12}>
							<Typography variant="subtitle2">User</Typography>
							<Typography variant="h6">{(userResponses.user) ? (userResponses.user.first_name + ' ' + userResponses.user.first_name) : ''}</Typography>
						</Grid>
						<Grid item md={6} xs={12}>
							<Typography variant="subtitle2">Test Duration</Typography>
							<Typography variant="h6">{userResponses.mock_test ? userResponses.mock_test.test_duration + ' minutes' : ''}</Typography>
						</Grid>
						<Grid item md={6} xs={12}>
							<Typography variant="subtitle2">Time Taken</Typography>
							<Typography variant="h6">{convertTimeInWords(userResponses.time_taken)}</Typography>
						</Grid>
						<Grid item md={6} xs={12}>
							<Typography variant="subtitle2">Language</Typography>
							<Typography variant="h6">{(userResponses.language) ? userResponses.language.name : ''}</Typography>
						</Grid>
						<Grid item md={6} xs={12}>
							<Typography variant="subtitle2">Submitted At</Typography>
							<Typography variant="h6">{formatDate(userResponses.createdAt)}</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<Card className={clsx(classes.content, commonClasses.paperContainer)}>
				<CardHeader title="Response Details"/>
				<Divider />
				<CardContent className={classes.cardContentStyle}>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Dialogue Title</TableCell>
									<TableCell>No. of segments</TableCell>
									<TableCell>Attemped segments</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
							{userResponses.mock_test && userResponses.mock_test.mock_test_dialogues ? (
								userResponses.mock_test.mock_test_dialogues.map((dialogue, index) => (
									<TableRow
										key={index}
										hover
										onClick={() => handleDialogueToggle(dialogue)}
										className={classes.rowHover}
									>
										<TableCell component="th" scope="row">
											{dialogue.title}
										</TableCell>
										<TableCell>{dialogue.mock_dialogue_segments ? dialogue.mock_dialogue_segments.length : 0}</TableCell>
										<TableCell>
											{calculateAttempedSegments(dialogue.mock_dialogue_segments)}
										</TableCell>
									</TableRow>
								))
							) :  null}
							</TableBody>
						</Table>
					</TableContainer>
					<form className={classes.form}>
						<TextField
							className={classes.textField}
							fullWidth
							label="Feedback"
							name="feedback"
							onChange={(event) => handleChange('feedback', event.target.value)}
							type="text"
							value={feedbackForm.feedback || ''}
							variant="outlined"
							multiline={true}
							rows={3}
							required
							error={feedbackForm.errors.feedback ? true : false}
							helperText={feedbackForm.errors.feedback}
						/>

						<div className={classes.images} >
							<input
								accept="image/*"
								className={classes.input}
								id={"feedback-images"}
								type="file"
								multiple={true}
								onChange={(event) => handleFileChange(event, false, 'description', 'start_audio')}
							/>
							<label htmlFor={"feedback-images"}>
								<Button variant="outlined" color="primary" size="large" component="span" startIcon={<AddPhotoAlternateIcon />}>
									Add Feedback Images
								</Button>
							</label>
							<div className={classes.imgsContainer}>
								{feedbackImgs.map((imgUrl, index) => (
									<div className={classes.imgBox} key={index} >
										<img src={imgUrl} alt={index} className={classes.img} />
										<IconButton aria-label="delete" onClick={() => removeImage(index)} className={classes.deleteIcon}>
											<DeleteIcon />
										</IconButton>
									</div>
								))}
							</div>
						</div>
					</form>
				</CardContent>
				<CardActions className={classes.actions}>
					<Button
						color="primary"
						variant="contained"
						onClick={saveFeedback}
						disabled={feedbackForm.errors.feedback ? true : false}
					>
						Save Feedback
					</Button>
				</CardActions>
			</Card>

			<MockResponseDialogue
				open={openDialogueModal}
				onClose={handleDialogueToggle}
				handleSnackToogle={handleSnackToogle}
				dialogueData={selectedDialogue}
			/>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={2000}
			/>
			<UploadingProgress
				open={openProgressDialogue}
				// onClose={progressOpen}
				progressPercentage={progress}
			/>
		</div>
	);
};

export default UserMockResponseDetails;