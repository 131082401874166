import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import AppBar  from '@material-ui/core/AppBar';
import Toolbar  from '@material-ui/core/Toolbar';
// import Badge  from '@material-ui/core/Badge';
import Hidden  from '@material-ui/core/Hidden';
import IconButton  from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AuthService from '../../../../services/authService';

const useStyles = makeStyles(theme => ({
	flexGrow: {
		flexGrow: 1
	},
	logo: {
		height: 40,
	},
	signOutButton: {
		marginLeft: theme.spacing(1)
	},
	titleConatiner: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	logoTitle: {
		color: theme.palette.primary.contrastText,
		paddingLeft: theme.spacing(2),
	},
}));

const Topbar = props => {
	const { className, onSidebarOpen, ...rest } = props;

	const classes = useStyles();

	// const [notifications] = React.useState([]);

	const logoutUser = () => {
		AuthService.logout();
	};

	return (
		<AppBar
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Toolbar>
				<Hidden lgUp>
					<IconButton
						color="inherit"
						onClick={onSidebarOpen}
					>
						<MenuIcon />
					</IconButton>
				</Hidden>
				<RouterLink to="/">
					<div className={classes.titleConatiner}>
						<img
							alt="Logo"
							src="/images/logos/logo.png"
							className={classes.logo}
						/>
						<Typography variant="h3" className={classes.logoTitle}>
							Naati Language Academy
						</Typography>
					</div>
				</RouterLink>
				<div className={classes.flexGrow} />
				{/* <Hidden mdDown> */}
					{/* <IconButton color="inherit">
						<Badge
							badgeContent={notifications.length}
							color="primary"
							variant="dot"
						>
							<NotificationsIcon />
						</Badge>
					</IconButton> */}
					<Tooltip title="Logout">
						<IconButton
							className={classes.signOutButton}
							color="inherit"
							onClick={logoutUser}
						>
							<InputIcon />
						</IconButton>
					</Tooltip>
				{/* </Hidden> */}			
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func
};

export default Topbar;
