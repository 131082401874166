
// for live
export const BASE_API_PATH = 'https://naatiapi.languageacademy.com.au/';//'https://naatiapi.languageacademy.com.au/';  // http://localhost:8080/  https://naatiapi.languageacademy.com.au/
export const SERVER_PATH = 'https://assets.languageacademy.com.au/naati/api/';//'http://192.168.1.9:5019/app/';//'https://assets.languageacademy.com.au/naati/api/'; // http://localhost/naati/api/    https://assets.languageacademy.com.au/naati/api/

export const AUDIO_PATH = 'assets/audio_files/';
export const PROFILE_IMAGE_PATH = 'assets/profile_images/';
export const MOCK_RESPONSE_PATH = 'assets/mock_responses/';
export const EXAM_AUDIO_PATH = 'assets/exam_memory_audio_files/';
export const MOCK_AUDIO_PATH = 'assets/mock_test_audio_files/';
export const MOCK_FEEDBACK_PATH = 'assets/feedback_images/';
export const RESULT_PATH = 'assets/recent_results/';
export const VOCAB_AUDIO_SERVER_PATH = 'https://assets.languageacademy.com.au/naati/api/';//'https://dev-assets.languageacademy.com.au/naati/api/';
export const VOCAB_AUDIO_PATH = 'assets/vocab_audio_files/'; // vocabulary (words) audio path
/*
// for staging
export const BASE_API_PATH = 'https://dev-naatiapi.languageacademy.com.au/';
export const SERVER_PATH = 'https://dev-naatiapi.languageacademy.com.au/app/';
*/
/*
// for local
export const BASE_API_PATH = 'http://localhost:5019';
export const SERVER_PATH = 'http://localhost:5019/api/';
*/
export const DRAWER_WIDTH = 256 ;
