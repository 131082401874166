import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';

import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import useStyles from './DialogueDetailStyle';
import useCommonStyles from '../../common/style';
import API from '../../axios/axiosApi';
import { SERVER_PATH, AUDIO_PATH } from '../../config';
import { formatUnderscore } from '../../utils/formatter';
import UploadingProgress from '../../components/UploadingProgress';
// import { handleSnackChange } from '../../services/handlerService';

const DialogueDetails = (props) => {
	const { match: { params }, location: { state }, history } = props;
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const languageId = (state && state.languageId) ? state.languageId : 0;
	const [loading, setLoading] = useState(false);
	const [snack, setSnack] = useState({ open: false, message: '', duration: 2000 });
	const defaultSegment = {
		name: '',
		audio_path: '',
		transcript: '',
		sample_response: '',
		sample_transcript: '',
		is_new: true
	};
	const [audioForm, setAudioForm] = useState({
		title: '',
		start_transcript:'',
		end_transcript:'',
		plan_type_id: '',
		description: '',
		segments: [defaultSegment],
		errors: {
			title: '',
			description: '',
		}
	});
	const audioAssetPath = SERVER_PATH + AUDIO_PATH;
	const [planTypes, setPlanTypes] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [progress, setProgress] = useState(0);
	const [openProgressDialogue, setOpenProgressDialogue] = useState(false);

	const type = (params.id && params.id === 'add') ? 'add' : 'edit';
	const [pageTitle, setPageTitle] = useState((type === 'add') ? 'Add Dialogue' : 'Update Dialogue');
	// console.log(audioAssetPath);
	const maximumSegments = 20;

	useEffect(() => {
		if (!languageId) { return history.goBack(); }
		const fetchDialogueDetails = async () => {
			setLoading(true);
			try {
				const response = await API.get('dialogue/' + params.id);
				// console.log("response ==> ",response.data);
				if (response.data.success) {
					if (!response.data.data.dialogue_details) {
						handleSnackToogle("Record for this ID don't exists!");
						return history.goBack();
					}
					const dialogueDetails = (response.data.data.dialogue_details) ? response.data.data.dialogue_details : {};
					const segments = dialogueDetails.dialogue_segments.map(segment => {
						return {
							id: segment.id,
							name: segment.name,
							transcript: segment.transcript,
							audio_path: segment.audio_path,
							sample_response: segment.sample_response,
							audioSource: audioAssetPath + segment.audio_path,
							responseSource: segment.sample_response ? audioAssetPath + segment.sample_response : null,
							sample_transcript: segment.sample_transcript
						};
					});
					setAudioForm((audioForm) => ({
						...audioForm,
						title: dialogueDetails.title,
						description: dialogueDetails.description,
						start_audio: dialogueDetails.start_audio,
						original_start_audio: dialogueDetails.start_audio,
						start_transcript: dialogueDetails.start_transcript,
						end_transcript: dialogueDetails.end_transcript,
						end_audio:  dialogueDetails.end_audio,
						original_end_audio:  dialogueDetails.end_audio,
						start_audioFile: audioAssetPath + dialogueDetails.start_audio,
						end_audioFile: audioAssetPath + dialogueDetails.end_audio,
						plan_type_id: dialogueDetails.plan_type_id,
						segments
					}));
					// setAudioSource(audioAssetPath + dialogueDetails.audio_path);
					setPageTitle(dialogueDetails.title);
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchDialogueDetails : ", error);
				setLoading(false);
			}
		};
		const fetchPlanTypes = async () => {
			try {
				const response = await API.get('plan_types');
				// console.log("default_plan_type_id ==> ",response.data.data.default_plan_type_id);
				if (response.data.success) {
					setPlanTypes(response.data.data.plan_types);
					if (params.id && params.id === 'add') {
						setAudioForm((audioForm) => ({
							...audioForm,
							plan_type_id: response.data.data.default_plan_type_id,
						}));
					} else {
						fetchDialogueDetails();
					}
				} else {
					console.log("response fetchPlanTypes ==> ",response.data);
				}
			} catch (error) {
				console.log("ERROR in fetchPlanTypes : ", error);
			}
		};
		fetchPlanTypes();
	}, [params, audioAssetPath, history, languageId, refresh]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = (fieldName, value) => {
		validate(fieldName, value);
		setAudioForm(audioForm => ({ ...audioForm, [fieldName]: value }));
	};

	const validate = (fieldName, value) => {
		let form = { ...audioForm };
		if (typeof value === 'string') {
			value = value.trim();
		}
		form.errors[fieldName] = (value === '' || value === null) ? formatUnderscore(fieldName)  + ' is required' : '';
		setAudioForm(form);
	};

	/**
	 * Handle segment field change
	 * 
	 * @param {*} event 
	 */
	const handleSegmentChange = (fieldName, index, value) => {
		// console.log(fieldName + " === " + value) ;
		if (fieldName === 'transcript') {
			validateSegment(fieldName, index, value);
		}
		let form = { ...audioForm };
		form.segments[index] = { ...form.segments[index], [fieldName]: value };
		setAudioForm(form);
	};

	const validateSegment = (fieldName, index, value) => {
		let form = { ...audioForm };
		if (typeof value === 'string') {
			value = value.trim();
		}
		if (!form.segments[index].errors) { form.segments[index].errors = []; }
		form.segments[index].errors[fieldName] = (value === '' || value === null) ? formatUnderscore(fieldName) + ' is required' : '';
		setAudioForm(form);
	};
	
	const checkErrors = () => {
		let form = { ...audioForm }, haveError = false;
		for (const errors in form.errors) {
			if (form.errors.hasOwnProperty(errors)) {
				if (form.errors[errors]) { haveError = true; }
			}
		}
		return haveError;
	};

	const validateAllSegments = async () => {
		let form = { ...audioForm }, haveError = false;
		const endAudio = form.end_audio;
		const startAudio = form.start_audio;
		if (!startAudio) {
			haveError = true;
			form.errors.start_audio = 'Start Audio is required';
		} else { form.errors.start_audio = ''; }

		if (!endAudio) {
			haveError = true;
			form.errors.end_audio = 'End Audio is required';
		} else { form.errors.end_audio = ''; }
		
		form.segments.forEach((segment, index) => {
			let transcript = segment.transcript, audioFile = segment.audioFile, audioPath = segment.audio_path;
			if (typeof transcript === 'string') {
				transcript = transcript.trim();
			}
			if (!form.segments[index].errors) { form.segments[index].errors = []; }
			if (transcript === '' || transcript === null) {
				haveError = true;
				form.segments[index].errors.transcript = 'Transcript is required';
			} else {
				form.segments[index].errors.transcript = '';				
			}
			if (!audioFile && !audioPath) {
				haveError = true;
				form.segments[index].errors.audioFile = 'Audio is required';
			} else {
				form.segments[index].errors.audioFile = '';				
			}
		});
		setAudioForm(form);
		return haveError;
	};

	/**
	 * Add and update dialogue
	 */
	const saveDialogue = async () => {
		if (type === 'add') {
			createAudioDialogue();
		} else {
			updateDialogueDetails();
		}
	};
	
	/**
	 * Create a new audio dialogue
	 */
	const createAudioDialogue = async () => {
		const { title, description, start_audio, end_audio, plan_type_id, start_transcript, end_transcript } = audioForm;
		validate('title', title);
		validate('start_transcript', start_transcript);
		validate('end_transcript', end_transcript);
		const segmentsInvalid = await validateAllSegments();
		// console.log("segmentsInvalid : ",segmentsInvalid);
		if (segmentsInvalid) {
			return handleSnackToogle("Please add required fields in audio segments.");
		}

		if (!checkErrors()) {
			console.log("audioForm : ",audioForm);			

			const formData = new FormData();
			formData.append('title', title);
			formData.append('plan_type_id', plan_type_id);
			formData.append('description', description);
			formData.append('start_transcript', start_transcript);
			formData.append('end_transcript', end_transcript);
			formData.append('language_id', languageId);
			formData.append('start_audio', start_audio);
			formData.append('end_audio', end_audio);
			formData.append('type', type);
			let audioSegments = [];
			audioForm.segments.forEach((segment, index) => {
				formData.append(`audioFile_${index}`, segment.audioFile, segment.audioFile.name);
				if (segment.responseFile) {
					formData.append(`responseFile_${index}`, segment.responseFile, segment.responseFile.name);
				}
				audioSegments.push({
					name: segment.name,
					transcript: segment.transcript,
					audio_name: segment.audioFile.name,
					response_name: (segment.responseFile) ? segment.responseFile.name : null,
					sample_transcript: segment.sample_transcript
				});
			});
			formData.append('audio_segments', JSON.stringify(audioSegments));
			setLoading(true);
			setOpenProgressDialogue(true);
			try {
				const response = await API.post('save_dialogue', formData, {
					headers: {'Content-Type': 'multipart/form-data'},
					onUploadProgress: (progressEvent) => {
						const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
						setProgress(progressPercentage);
					},
				});			
				setOpenProgressDialogue(false);
				setProgress(0);
				if (response.data.success) {
					// console.log("response.data ==> ",response.data);
					handleSnackToogle(response.data.message);
					setPageTitle(title);
					if (type === 'add') { props.history.goBack(); }
				} else {
					console.log("response ==> ",response.data);
					handleSnackToogle(response.data.message)
				}
				setLoading(false);
			} catch (error) {
				setOpenProgressDialogue(false);
				console.log("ERROR in createAudioDialogue : ", error);
				let msg = 'Something went wrong. Please try again later.';
				if (error.response && error.response.data &&  error.response.data.error_message) {
					msg = error.response.data.error_message;
				} else if (error.response && error.response.data &&  error.response.data.message) {
					msg = error.response.data.message;
				} else if (error.message) {
					msg = error.message;
				}
				setLoading(false);
				handleSnackToogle(msg);
				setProgress(0);
			}
		}
	};

	/**
	 * Update dialogue and upload new audio segments
	 */
	const updateDialogueDetails = async () => {
		validate('title', audioForm.title);
		validate('start_transcript', audioForm.start_transcript);
		validate('end_transcript', audioForm.end_transcript);
		const segmentsInvalid = await validateAllSegments();
		// console.log("segmentsInvalid : ",segmentsInvalid);
		if (segmentsInvalid) {
			return handleSnackToogle("Please add required fields in audio segments.");
		}

		if (!checkErrors()) {
			// console.log("audioForm : ",audioForm);
			const formData = new FormData();
			let audioSegments = [], newSegments = [];
			audioForm.segments.forEach((segment, index) => {
				if (!segment.id) {
					formData.append(`audioFile_${index}`, segment.audioFile, segment.audioFile.name);
					if (segment.responseFile) {
						formData.append(`responseFile_${index}`, segment.responseFile, segment.responseFile.name);
					}
					newSegments.push({
						name: segment.name,
						transcript: segment.transcript,
						audio_name: segment.audioFile.name,
						response_name: (segment.responseFile) ? segment.responseFile.name : null,
						sort_index: index,
						sample_transcript: segment.sample_transcript
					});
				} else {
					audioSegments.push({
						id: segment.id,
						name: segment.name,
						transcript: segment.transcript,
						sort_index: index,
						sample_transcript: segment.sample_transcript
					});
				}
			});
			
			const { title, plan_type_id, description, start_audio, end_audio, start_transcript, end_transcript, original_start_audio, original_end_audio } = audioForm;
			const data = {
				title,
				plan_type_id,
				description,
				start_transcript,
				end_transcript,
				id: params.id,
				language_id: languageId,
				audioSegments,
				newSegments,
				original_start_audio,
				original_end_audio
			};
			formData.append('data', JSON.stringify(data));
			if (start_audio instanceof File) { formData.append("start_audio", start_audio); }
			if (end_audio instanceof File) { formData.append("end_audio", end_audio); }
			try {
				setLoading(true);
				setOpenProgressDialogue(true);
				const response = await API.put('save_dialogue/details', formData, {
					headers: {'Content-Type': 'multipart/form-data'},
					onUploadProgress: (progressEvent) => {
						const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
						setProgress(progressPercentage);
					},
				});
				setOpenProgressDialogue(false);
				setProgress(0);
				if (response.data.success) {
					// console.log("response.data ==> ",response.data);
					handleSnackToogle(response.data.message);
					setRefresh(refresh => !refresh);
					// setPageTitle(title);
				} else {
					console.log("response updateDialogueDetails ==> ",response.data);
					handleSnackToogle(response.data.message)
				}
				setLoading(false);
			} catch (error) {
				setOpenProgressDialogue(false);
				console.log("ERROR in updateDialogueDetails : ", error.response);
				setLoading(false);
				let msg = 'Something went wrong. Please try again later.';
				if (error.response && error.response.data &&  error.response.data.error_message) {
					msg = error.response.data.error_message;
				} else if (error.response && error.response.data &&  error.response.data.message) {
					msg = error.response.data.message;
				} else if (error.message) {
					msg = error.message;
				}
				handleSnackToogle(msg);
				setProgress(0);
			}
		}
	};

	const uploadAudio = async (file, segment, audioType) => {
		if (!file) {
			return handleSnackToogle("Please add an audio file first.");
		}
		const data = {
			audioType,
			segmentId: segment.id,
			old_audio_path: segment.audio_path,
			old_sample_response: segment.sample_response,
		};
		const formData = new FormData();
		formData.append('file', file, file.name);
		formData.append('data', JSON.stringify(data));
		try {
			setOpenProgressDialogue(true);
			setLoading(true);
			const response = await API.put('save_dialogue', formData, {
				headers: {'Content-Type': 'multipart/form-data'},
				onUploadProgress: (progressEvent) => {
					const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
					setProgress(progressPercentage);
				},
			});
			setLoading(false);
			setOpenProgressDialogue(false);
			setProgress(0);
			if (response.data.success) {
				setRefresh(refresh => !refresh);
				handleSnackToogle(response.data.message);
			} else {
				console.log("ERROR response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
		} catch (error) {
			setOpenProgressDialogue(false);
			console.log("ERROR in uploadAudio : ", error.response);
			setLoading(false);
			let msg = 'Something went wrong. Please try again later.';
			if (error.response && error.response.data &&  error.response.data.error_message) {
				msg = error.response.data.error_message;
			} else if (error.response && error.response.data &&  error.response.data.message) {
				msg = error.response.data.message;
			} else if (error.message) {
				msg = error.message;
			}
			handleSnackToogle(msg);
			setProgress(0);
		}
	};

	const handleFileChange = (e, index, audioType, descriptionKey) => {
		const maxUploadSize = 10; // In MB
		const byteToMb = 1000000; //  1MB = 1000000 Bytes = 1000 * 1000 (in decimal format)
		let form = { ...audioForm };
		const file = e.target.files[0];

		if ( !file ) { return; }
		const fileSize = parseInt(file.size);

		if(fileSize > maxUploadSize * byteToMb) { // Number of MegaBytes;
			return handleSnackToogle("Image Size Shouldn't Exceed " + maxUploadSize + "MB");
		}
		
		const reader = new FileReader();
		// reader.readAsBinaryString(file);
		reader.readAsDataURL(file);
		reader.onload = () => {
			let fileName = '', sourceName = '';
			if (audioType === 'audio') {
				fileName = 'audioFile';
				sourceName = 'audioSource';
			} else {
				fileName = 'responseFile';
				sourceName = 'responseSource';
			} 
			if (index !== false && !descriptionKey) {
				form.segments[index] = {
					...form.segments[index],
					[fileName]: file,
					[sourceName]: reader.result,
					errors: {
						...form.segments[index].errors,
						audioFile: '',
					}
				};
			} else if (descriptionKey) {
				form = {
					...form,
					[descriptionKey]: file,
					[`${descriptionKey}File`]: reader.result
				};
			}
			setAudioForm(form);
			// console.log(`data:${file.type};base64,${btoa(reader.result)}`);
			if (type === 'edit' && index !== false && !descriptionKey && form.segments[index].id) {
				uploadAudio(file, form.segments[index], audioType);
			}
		};
		reader.onerror = function () {
			handleSnackToogle("Error in loading audio");
		};
		e.target.value = null;
	};

	/* const handleFileChangeForm = (e, index, audioType) => {
		const maxUploadSize = 10; // In MB
		const byteToMb = 1000000; //  1MB = 1000000 Bytes = 1000 * 1000 (in decimal format)
		let form = { ...audioForm };
		const file = e.target.files[0];

		if ( !file ) { return; }
		const fileSize = parseInt(file.size);

		if(fileSize > maxUploadSize * byteToMb) { // Number of MegaBytes;
			return handleSnackToogle("Image Size Shouldn't Exceed " + maxUploadSize + "MB");
		}
		
		const reader = new FileReader();
		// reader.readAsBinaryString(file);
		reader.readAsDataURL(file);
		reader.onload = () => {
			// let fileName = '', sourceName = '';
			let sourceName = '';
			if (index === 'start_audio') {
				// fileName = 'audioFile';
				sourceName = 'start_audioFile';
			} else {
				// fileName = 'responseFile';
				sourceName = 'end_audioFile';
			}
			form = {
				...form,
				[index]: file,
				[sourceName]:reader.result
			};
			setAudioForm(form);
			// console.log(`data:${file.type};base64,${btoa(reader.result)}`);
			console.log(form)
		};
		reader.onerror = function () {
			handleSnackToogle("Error in loading audio");
		};
		e.target.value = null;
	}; */

	/**
	 * Add a new audio segment
	 */
	const addSegment = (index) => {
		if (audioForm.segments.length < maximumSegments) {
			let audioSegments = audioForm.segments;
			audioSegments.splice(index + 1, 0, { ...defaultSegment });
			setAudioForm(audioForm => ({
				...audioForm,
				segments: audioSegments
			}));
		} else {
			handleSnackToogle('Maximum of ' + maximumSegments + ' audio segments are per dialogue');
		}
	};

	/**
	 * Delete audio segment
	 * 
	 * @param {*} index 
	 */
	const deleteSegment = (index) => {
		// console.log(audioForm.segments[index]);
		if (audioForm.segments.length > 1) {
			if (type === 'edit' && audioForm.segments[index].id) {
				deleteAudioSegment(audioForm.segments[index]);
			}
			let form = { ...audioForm };
			form.segments.splice(index, 1);
			setAudioForm(form);
		} else {
			handleSnackToogle('Minimum 1 audio segments is required per dialogue');
		}
	};

	/**
	 * Delete audio segment
	 * 
	 * @param {*} audioSegment 
	 */
	const deleteAudioSegment = async (audioSegment) => {
		try {
			setLoading(true);
			const response = await API.delete('delete_dialogue/' + audioSegment.id);
			setLoading(false);
			if (response.data.success) {
				// console.log("response.data ==> ",response.data);
				handleSnackToogle(response.data.message);
				setRefresh(refresh => !refresh);
				// setPageTitle(title);
			} else {
				console.log("response deleteAudioSegment ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
		} catch (error) {
			setLoading(false);			
			console.log("ERROR in deleteAudioSegment : ", error);
		}
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};


	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<IconButton size="small" aria-label="go-back" onClick={() => props.history.goBack()}>
							<ArrowBackIcon />
						</IconButton>
						<AudiotrackIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">{pageTitle}</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={saveDialogue} disabled={loading || checkErrors()}>Save Dialogue</Button>
				</div>
			</div>
			<Paper className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<form className={classes.form}>
					<TextField
						className={classes.textField}
						fullWidth
						label="Title"
						name="title"
						onChange={(event) => handleChange('title', event.target.value)}
						type="text"
						value={audioForm.title}
						variant="outlined"
						required
						error={audioForm.errors.title ? true : false}
						helperText={audioForm.errors.title}
						inputProps={{ maxLength: 255 }}
					/>					
					
					<TextField
						className={classes.textField}
						fullWidth
						label="Description"
						name="description"
						onChange={(event) => handleChange('description', event.target.value)}
						type="text"
						value={audioForm.description || ''}
						variant="outlined"
						multiline
						rows={3}
						// inputProps={{ maxLength: 499 }}
					/>
					<FormControl required className={classes.textField} fullWidth variant="outlined">
						<InputLabel id="select-label">Associated Plan</InputLabel>
						<Select
							labelId="select-label"
							id="demo-simple-select-outlined"
							name="associated_plan"
							fullWidth
							value={audioForm.plan_type_id}
							onChange={(event) => handleChange('plan_type_id', event.target.value)}
							labelWidth={110}
						>
						{
							planTypes.map(plan => (
								<MenuItem key={plan.id} value={plan.id}>{formatUnderscore(plan.name)}</MenuItem>
							))
						}
						</Select>
					</FormControl>
					{/* START DESCRIPTION */}
					<TextField
						className={classes.textField}
						fullWidth
						label="Start Audio Transcript"
						name="start_transcript"
						onChange={(event) => handleChange('start_transcript', event.target.value)}
						type="text"
						value={audioForm.start_transcript || ''}
						variant="outlined"
						required
						error={audioForm.errors.start_transcript ? true : false}
						helperText={audioForm.errors.start_transcript}
						inputProps={{ maxLength: 255 }}
					/>
					<div className={classes.audio} >
						<input
							accept="audio/*"
							className={classes.input}
							id={"button-audio-file-start"}
							type="file"
							multiple={false}
							onChange={(event) => handleFileChange(event, false, 'description', 'start_audio')}
						/>
						<label htmlFor={"button-audio-file-start"}>
							<Button variant="outlined" color="primary" size="large" className={classes.uploadButton} component="span" startIcon={<CloudUploadIcon />}>
								{ type === 'add' ? 'Add Start Audio' : 'Upload Start Audio'}
							</Button>
						</label>
						{ audioForm.start_audioFile ? (
							<audio controls src={audioForm.start_audioFile} controlsList="nodownload" className={classes.audioComponent} />
						) : null }
						{audioForm.errors && audioForm.errors.start_audio ? (
							<Typography className={classes.emptyAudioError} variant="caption">Please upload audio file</Typography>
						) : null}
					</div>
					{/* START DESCRIPTION */}
					{/* END DESCRIPTION */}
					<TextField
						className={classes.textField}
						fullWidth
						label="End Audio Transcript"
						name="end_transcript"
						onChange={(event) => handleChange('end_transcript', event.target.value)}
						type="text"
						value={audioForm.end_transcript || ''}
						variant="outlined"
						required
						error={audioForm.errors.end_transcript ? true : false}
						helperText={audioForm.errors.end_transcript}
						inputProps={{ maxLength: 255 }}
					/>
					<div className={classes.audio} >
						<input
							accept="audio/*"
							className={classes.input}
							id={"button-audio-file-end"}
							type="file"
							multiple={false}
							onChange={(event) => handleFileChange(event, false, 'description', 'end_audio')}
						/>
						<label htmlFor={"button-audio-file-end"}>
							<Button variant="outlined" color="primary" size="large" className={classes.uploadButton} component="span" startIcon={<CloudUploadIcon />}>
								{ type === 'add' ? 'Add End Audio' : 'Upload End Audio'}
							</Button>
						</label>
						{ audioForm.end_audioFile ? (
							<audio controls src={audioForm.end_audioFile} controlsList="nodownload" className={classes.audioComponent} />
						) : null }
						{audioForm.errors && audioForm.errors.end_audio ? (
							<Typography className={classes.emptyAudioError} variant="caption">Please upload audio file</Typography>
						) : null}
					</div>
					{/* END DESCRIPTION */}
					
					{audioForm.segments.map((audioSegment, index) => (
						<div className={classes.segmentContainer} key={index}>
							<div className={classes.segment}>
								<TextField
									fullWidth
									label="Name (Recommended)"
									name={"name_" + index}
									onChange={(event) => handleSegmentChange('name', index, event.target.value)}
									type="text"
									value={audioSegment.name}
									variant="outlined"
									inputProps={{ maxLength: 255 }}
								/>

								<div className={classes.audio} >
									<input
										accept="audio/*"
										className={classes.input}
										id={"button-audio-file-" + index}
										type="file"
										multiple={false}
										onChange={(event) => handleFileChange(event, index, 'audio')}
									/>
									<label htmlFor={"button-audio-file-" + index}>
										<Button variant="outlined" color="primary" size="large" className={classes.uploadButton} component="span" startIcon={<CloudUploadIcon />}>
											{ type === 'add' ? 'Add Audio' : 'Upload Audio'}
										</Button>
									</label>
									{ audioSegment.audioSource ? (
										<audio controls src={audioSegment.audioSource} controlsList="nodownload" className={classes.audioComponent} />
									) : null }
									{audioSegment.errors && audioSegment.errors.audioFile ? (
										<Typography className={classes.emptyAudioError} variant="caption">Please upload audio file</Typography>
									) : null}
								</div>
								<TextField
									className={classes.textField}
									fullWidth
									label="Transcript"
									name={"transcript_" + index}
									onChange={(event) => handleSegmentChange('transcript', index, event.target.value)}
									type="text"
									value={audioSegment.transcript || ''}
									variant="outlined"
									multiline={true}
									rows={3}
									required
									error={audioSegment.errors && audioSegment.errors.transcript ? true : false}
									helperText={audioSegment.errors && audioSegment.errors.transcript}
								/>
								<div className={classes.audio} >
									<input
										accept="audio/*"
										className={classes.input}
										id={"button-response-file-" + index}
										multiple={false}
										type="file"
										onChange={(event) => handleFileChange(event, index, 'sample_response')}
									/>
									<label htmlFor={"button-response-file-" + index}>
										<Button
											variant="outlined"
											color="primary"
											size="large"
											className={classes.uploadButton}
											component="span"
											startIcon={<CloudUploadIcon />}
										>
											{ type === 'add' ? 'Add Sample Response' : 'Upload Sample Response'}
										</Button>
									</label>
									{ audioSegment.responseSource ? (
										<audio controls src={audioSegment.responseSource} controlsList="nodownload" className={classes.audioComponent} />
									) : null }									
								</div>
								<TextField
									className={classes.textField}
									fullWidth
									label="Sample Response Transcript"
									name={"sample_transcript_" + index}
									onChange={(event) => handleSegmentChange('sample_transcript', index, event.target.value)}
									type="text"
									value={audioSegment.sample_transcript || ''}
									variant="outlined"
									multiline={true}
									rows={3}
								/>
							</div>
							<div className={classes.actionBtns}>
								<IconButton aria-label="delete" onClick={() => deleteSegment(index)}>
									<DeleteIcon />
								</IconButton>
								<Tooltip title="Add an audio segment after this one">
									<IconButton color="primary" aria-label="add" onClick={() => addSegment(index)}>
										<AddIcon />
									</IconButton>
								</Tooltip>
							</div>
						</div>
					))}
				</form>
			</Paper>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={2000}
			/>
			<UploadingProgress
				open={openProgressDialogue}
				// onClose={progressOpen}
				progressPercentage={progress}
			/>
		</div>
	);
}; 

export default DialogueDetails;