import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import API from '../../axios/axiosApi';
import useStyles from './VocabOnlineDetailStyle';
import useCommonStyles from '../../common/style';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';
import UploadingProgress from '../../components/UploadingProgress';

import { VOCAB_AUDIO_SERVER_PATH, VOCAB_AUDIO_PATH } from '../../config';

const schema = {
	word: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	translation: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	/*vocabulary_category_id: {
		presence: { allowEmpty: false, message: '^Category is required' },
	},*/
};

const VocabOnlineDetailsDialog = (props) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const { open, onClose, dialogData } = props;
	const [formState, setFormState] = useState({
		isValid: false,
		values: {
			word: '',
			translation: '',
			//vocabulary_category_id: '',
		},
		touched: {},
		errors: {}
	});
	const [vocabCategories, setVocabCategories] = useState([]);
	const [autocompleteValue, setAutocompleteValue] = useState(null);
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	//const [refresh, setRefresh] = useState(false);
	const [openProgressDialogue, setOpenProgressDialogue] = useState(false);

	const [audioSrc, setAudioSrc] = useState("");
	useEffect(() => {
		const fetchVocabCategories = async () => {
			try {
				const response = await API.get('vocabulary_categories');
				// console.log("default_plan_type_id ==> ",response.data.data);
				if (response.data.success && response.data.data.vocabulary_categories) {
					setVocabCategories(response.data.data.vocabulary_categories);
				} else {
					console.log("response fetchVocabCategories ==> ",response.data);
				}
			} catch (error) {
				console.log("ERROR in fetchVocabCategories : ", error);
			}
		};
		fetchVocabCategories();
	}, []);
	
	
	useEffect(() => {
		const vocabAudioAssetPath = VOCAB_AUDIO_SERVER_PATH + VOCAB_AUDIO_PATH;
		setFormState(formState => ({
			...formState,
			values: {
				id: (dialogData && dialogData.id) ? dialogData.id : '',
				word: (dialogData && dialogData.word) ? dialogData.word : '',
				translation: (dialogData && dialogData.translation) ? dialogData.translation : '',
				//vocabulary_category_id: (dialogData && dialogData.vocabulary_category_id) ? dialogData.vocabulary_category_id : '',
			},
			touched: {}
		}));
		//setAutocompleteValue(dialogData);
		console.log("HEE",dialogData)
		setAudioSrc(vocabAudioAssetPath+"online_"+dialogData.id+".mp3");
		setAutocompleteValue(dialogData && dialogData.vocabulary_category ? dialogData.vocabulary_category : null);
	}, [open, dialogData]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
		...formState,
		isValid: errors ? false : true,
		errors: errors || {}
		}));
	}, [formState.values]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
		...formState,
		values: {
			...formState.values,
			[event.target.name]:
			event.target.type === 'checkbox'
				? event.target.checked
				: event.target.value
		},
		touched: {
			...formState.touched,
			[event.target.name]: true
		}
		}));
	};

	/**
	 * Handle autocomplete change
	 * 
	 * @param {*} fieldName 
	 * @param {*} event 
	 * @param {*} value 
	 */
	const handleAutocompleteChange = (fieldName, event, value) => {
		console.log("value: ",value);
		console.log(vocabCategories);
		event.persist();
		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[fieldName]: value ? value.id : null
			},
			touched: {
				...formState.touched,
				[fieldName]: true
			}
		}));
		setAutocompleteValue(value);
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;
   
	/**
	 * Close and pass data on close
	 */
	const handleSave = () => {
		console.log("HAND",formState)
		onClose(formState.values);  
	};

	const uploadAudio = async (file, form, vocabId, blobData) => {
		if (!file) {
			console.error("Please add an audio file first.");
			//return handleSnackToogle("Please add an audio file first.");
		}
		const data = {
			vocabId: vocabId,
		};
		const formData = new FormData();
		formData.append('file', file, "online_"+vocabId+".mp3");
		formData.append('data', JSON.stringify(data));
		try {
			setOpenProgressDialogue(true);
			setLoading(true);
			const response = await API.put('save_online_audio', formData, {
				headers: {'Content-Type': 'multipart/form-data'},
				onUploadProgress: (progressEvent) => {
					const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
					setProgress(progressPercentage);
				},
			});
			setLoading(false);
			setOpenProgressDialogue(false);
			setProgress(0);
			if (response.data.success) {
				//setRefresh(refresh => !refresh);
				console.log("Success:-", response.data.message, file);
				setAudioSrc(blobData)
				//handleSnackToogle(response.data.message);
			} else {
				console.log("ERROR response ==> ",response.data);
				//handleSnackToogle(response.data.message)
			}
		} catch (error) {
			setOpenProgressDialogue(false);
			console.log("ERROR in uploadAudio : ", error.response);
			setLoading(false);
			let msg = 'Something went wrong. Please try again later.';
			if (error.response && error.response.data &&  error.response.data.error_message) {
				msg = error.response.data.error_message;
			} else if (error.response && error.response.data &&  error.response.data.message) {
				msg = error.response.data.message;
			} else if (error.message) {
				msg = error.message;
			}
			//handleSnackToogle(msg);
			console.log("ERROR", msg)
			setProgress(0);
		}
	};

	const handleFileChange = (e, vocabId) => {
		const maxUploadSize = 10; // In MB
		const byteToMb = 1000000; //  1MB = 1000000 Bytes = 1000 * 1000 (in decimal format)
		let form = { ...formState };
		const file = e.target.files[0];
		if ( !file ) { return; }
		const fileSize = parseInt(file.size);
		if(fileSize > maxUploadSize * byteToMb) { // Number of MegaBytes;
			console.log("Audio Size Shouldn't Exceed " + maxUploadSize + "MB");
			//return handleSnackToogle("Image Size Shouldn't Exceed " + maxUploadSize + "MB");
		}
		
		const reader = new FileReader();
		let newFile = new File([file], "online_"+vocabId+".mp3");
		reader.readAsDataURL(newFile);
		reader.onload = () => {
			let fileName = 'audioFile', sourceName = 'audioSource';
			form = {
				...formState,
				[fileName]:newFile,
				[sourceName]: reader.result,
			}
			let base64 = reader.result.split("base64,")[1];
			let blobData = `data:${file.type};base64,${base64}`;
			uploadAudio(newFile, form, vocabId, blobData);
		};
		reader.onerror = function () {
			//handleSnackToogle("Error in loading audio");
			console.log("Error in loading audio");
		};
		e.target.value = null;
	};
	return (
		<Dialog classes={{ paper: commonClasses.dialogueFullwidth }} onClose={() => onClose()} aria-labelledby="customized-dialog-title" open={open}>
			<AppBar position="static" className={classes.dialogHeader} elevation={2}>
				<Typography variant="h5" color="inherit" className={classes.dialogTitle}>
					{dialogData.type} Online Vocabulary
				</Typography>
				<IconButton aria-label="delete" onClick={() => onClose()} color="inherit">
					<CloseIcon />
				</IconButton>
			</AppBar>
			<DialogContent className={classes.dialogContent}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<form className={classes.form} onSubmit={handleSave}>
					<TextField
						className={classes.inputField}
						error={hasError('word')}
						fullWidth
						helperText={
							hasError('word') ? formState.errors.word[0] : null
						}
						label="Word"
						name="word"
						onChange={handleChange}
						type="text"
						value={formState.values.word || ''}
						variant="outlined"
						required
					/>
					<TextField
						className={classes.inputField}
						error={hasError('translation')}
						fullWidth
						helperText={
							hasError('translation') ? formState.errors.translation[0] : null
						}
						label="Translation"
						name="translation"
						onChange={handleChange}
						type="text"
						value={formState.values.translation || ''}
						variant="outlined"
						required
					/>

					<Autocomplete
						required
						fullWidth
						options={vocabCategories}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField className={classes.inputField} {...params} label="Associated Category *" variant="outlined" margin="normal" />
						)}
						value={autocompleteValue}
						onChange={(event, value) => handleAutocompleteChange('vocabulary_category_id', event, value)}
						renderOption={(option, { inputValue }) => {
							const matches = match(option.name, inputValue);
							const parts = parse(option.name, matches);

							return (
								<div>
									{parts.map((part, index) => (
										<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
											{part.text}
										</span>
									))}
								</div>
							);
						}}
					/>

					<div className={classes.audio} >
						<input
							//accept="audio/*"
							accept=".mp3,audio/*"
							className={classes.input}
							id={"button-audio-file-start"}
							type="file"
							multiple={false}
							onChange={(event) => handleFileChange(event, formState.values.id)}
						/>
						<label htmlFor={"button-audio-file-start"}>
							<Button variant="outlined" color="primary" size="large" className={classes.uploadButton} component="span" startIcon={<CloudUploadIcon />}>
								Upload Vocabulary Audio
							</Button>
						</label>
					</div>
					<div className={classes.audio} >
						<audio controls src={audioSrc} controlsList="nodownload" className={classes.audioComponent} />
					</div>
					

					
					{/* <FormControl
						required
						className={classes.inputField}
						fullWidth
						variant="outlined"
						error={hasError('vocabulary_category_id')}
					>
						<InputLabel id="select-vocab-category-label">Associated Category</InputLabel>
						<Select
							labelId="select-vocab-category-label"
							id="vocab-category-outlined"
							name="vocabulary_category_id"
							fullWidth
							value={formState.values.vocabulary_category_id || ''}
							onChange={handleChange}
							labelWidth={140}
						>
						{
							vocabCategories.map(category => (
								<MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
							))
						}
						</Select>
						<FormHelperText>{hasError('vocabulary_category_id') ? formState.errors.vocabulary_category_id[0] : null}</FormHelperText>
					</FormControl> */}
				</form>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button autoFocus onClick={handleSave} color="primary" disabled={!formState.isValid}>
					Save
				</Button>
			</DialogActions>
			<UploadingProgress
				open={openProgressDialogue}
				// onClose={progressOpen}
				progressPercentage={progress}
			/>
		</Dialog>
	);
};

VocabOnlineDetailsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	dialogData: PropTypes.object.isRequired,
};

export default VocabOnlineDetailsDialog;