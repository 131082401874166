import React from 'react';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/core/styles';
import validate from 'validate.js';
// import Snackbar from '@material-ui/core/Snackbar';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
// import { handleSnackChange } from './services/handlerService';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
	draw: chartjs.draw
});

validate.validators = {
	...validate.validators,
	...validators
};

export default function App(props) {
	// const [snack, setSnack] = useState({ open: false, message: '', duration: 2000 });

	// useEffect(() => {
	// 	handleSnackChange.receiveData().subscribe(data => {
	// 		handleSnackToogle(data);
	// 	});
	// }, []);

	// /**
	//  * Handle a common snack message
	//  * 
	//  * @param {*} data 
	//  */
	// const handleSnackToogle = (data) => {
	// 	const message = (data && data.message) ? data.message : '';
	// 	const duration = (data && data.duration) ? data.duration : 2000;
	// 	setSnack(snack => ({ open: !snack.open, message, duration }));
	// };
	return (
		<ThemeProvider theme={theme}>
			<Routes />
			{/* <Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={snack.duration}
			/> */}
		</ThemeProvider>
	);
}
	