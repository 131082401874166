import React from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';

import history from './utils/history';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';

import {
	Login,
	Language,
	Users,
	UserDetails,
	Videos,
	VideoDetails,
	VocabDashboard,
	Vocabulary,
	DialogueDashboard,
	Dialogues,
	DialogueDetails,
	Profile,
	Faqs,
	FaqDetails,
	Enquiries,
	Plans,
	PlanDetails,
	VocabCategories,
	AccessCodes,
	AccessCodeDetails,
	MockTests,
	MockTestDetails,
	UserMockResponses,
	UserMockResponseDetails,
	ExamMemories,
	ExamMemoryDetails,
	ResetPassword,
	RecentResults,
	PrivacyPolicy,
	Dashboard as DashboardView,
	SignUp as SignUpView,
	NotFound as NotFoundView,
	VocabOnline,
	VocabSaved,
	MobileAppSettings,
} from './screens';

const Routes = () => {
  return (
	<Router history={history}>
		<Switch>
			<Redirect exact from="/" to="/dashboard" />
			<UnauthenticatedRoute exact path="/login" component={Login} />
			<UnauthenticatedRoute exact path="/sign-up" component={SignUpView} />
			<UnauthenticatedRoute exact path="/reset-pasword/:hash" component={ResetPassword} />
			<AuthenticatedRoute exact path="/users" component={Users} />
			<AuthenticatedRoute exact path="/users/:id" component={UserDetails} />
			<AuthenticatedRoute exact path="/videos" component={Videos} />
			<AuthenticatedRoute exact path="/videos/:id" component={VideoDetails} />
			<AuthenticatedRoute exact path="/vocabulary" component={VocabDashboard} />
			<AuthenticatedRoute exact path="/vocabulary/:langId" component={Vocabulary} />
			<AuthenticatedRoute exact path="/dialogues" component={DialogueDashboard} />
			<AuthenticatedRoute exact path="/dialogues/list" component={Dialogues} />
			<AuthenticatedRoute exact path="/dialogues/list/:id" component={DialogueDetails} />
			<AuthenticatedRoute exact path="/profile" component={Profile} />
			<AuthenticatedRoute exact path="/mock-tests" component={DialogueDashboard} />
			<AuthenticatedRoute exact path="/mock-tests/list" component={MockTests} />
			<AuthenticatedRoute exact path="/mock-tests/list/:id" component={MockTestDetails} />
			<AuthenticatedRoute exact path="/mock-responses" component={UserMockResponses} />
			<AuthenticatedRoute exact path="/mock-responses/:id" component={UserMockResponseDetails} />
			<AuthenticatedRoute exact path="/enquiries" component={Enquiries} />
			<AuthenticatedRoute exact path="/exam-memories" component={DialogueDashboard} />
			<AuthenticatedRoute exact path="/exam-memories/list" component={ExamMemories} />
			<AuthenticatedRoute exact path="/exam-memories/list/:id" component={ExamMemoryDetails} />
			<AuthenticatedRoute exact path="/manage/access_codes" component={AccessCodes} />
			<AuthenticatedRoute exact path="/manage/access_codes/:id" component={AccessCodeDetails} />
			<AuthenticatedRoute exact path="/manage/languages" component={Language} />
			<AuthenticatedRoute exact path="/manage/faq" component={Faqs} />
			<AuthenticatedRoute exact path="/manage/faq/:id" component={FaqDetails} />
			<AuthenticatedRoute exact path="/manage/vocabulary-categories" component={VocabCategories} />
			<AuthenticatedRoute exact path="/manage/plans" component={Plans} />
			<AuthenticatedRoute exact path="/manage/plans/:id" component={PlanDetails} />
			<AuthenticatedRoute exact path="/manage/recent-results" component={RecentResults} />
			<AuthenticatedRoute exact path="/dashboard" component={DashboardView} />
			<AuthenticatedRoute exact path="/online-vocabulary" component={VocabOnline} />
			<AuthenticatedRoute exact path="/saved-vocabulary" component={VocabSaved} />
			<AuthenticatedRoute exact path="/manage/mobile_app_settings" component={MobileAppSettings} />

			<Route path="/not-found" component={NotFoundView} />
			<Route path="/privacy-policy" component={PrivacyPolicy} />
			<Redirect to="/not-found" />
		</Switch>
	</Router>
  );
};

export default Routes;
