import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import API from '../../axios/axiosApi';
import useStyles from './DialogueDashboardStyle';

const DialogueDashboard = (props) => {
	const { location: { pathname } } = props;
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [dialogueLanguages, setDialogueLanguges] = useState([]);
	
	useEffect(() => {
		const fetchVocabLanguages = async () => {
			setLoading(true);
			try {
				// console.log(pathname);
				let url = '';
				switch (pathname) {
					case '/exam-memories'	: url = 'exam_memories';	break;
					case '/mock-tests'		: url = 'mock_tests';		break;
					default					: url = 'dialogue';			break;
				}
				const response = await API.get(url);
				if (response.data.success) {
					setDialogueLanguges(response.data.data.dialogue_languages)
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in handleSubmit : ", error);
				setLoading(false);
			}
		};
		fetchVocabLanguages();
	}, [pathname]);

	/**
	 * Go to dialogue listing
	 * 
	 * @param {Object} language 
	 */
	const gotoDialogueList = (language) => {
		let url = '';
		switch (pathname) {
			case '/exam-memories'	: url = 'exam-memories/list';	break;
			case '/mock-tests'		: url = 'mock-tests/list';		break;
			default					: url = 'dialogues/list';		break;
		}
		props.history.push({ pathname: url, state: { languageName: language.name, languageId: language.id } });
	};
	
	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Backdrop className={classes.backdrop} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				{!loading? (
					<Grid
						container
						spacing={3}
						className={classes.gridContainer}
					>
					{dialogueLanguages.map((language, index) => (
						<Grid item key={index} lg={4} md={6} xs={12}>
							<Card>
								<CardContent>
									<Typography
										align="center"
										gutterBottom
										variant="h1"
										className={classes.languageLabel}
										color="primary"
									>
										{language.label}
									</Typography>
								</CardContent>
								<Divider />
								<CardActions>
									<Grid container justify="space-between" alignItems="center">
										<Button size="small" onClick={() => gotoDialogueList(language)}>View All</Button>
										<Typography color="textSecondary" className={classes.cardActionGrid}>
											Total: {language.dialogue_count}
										</Typography>
									</Grid>
								</CardActions>
							</Card>
						</Grid>
					))}
					{(dialogueLanguages.length === 0) ? (
						<Grid container direction="column" justify="center" alignItems="center">
							<Typography
								align="center"
								variant="h2"
								className={classes.languageLabel}
							>
								Please create a language first
							</Typography>
							<Link to="/manage/languages">Go to language settings</Link>
						</Grid>
					) : null}
					</Grid>
				) : null}
			</div>
		</div>
	);
};

export default DialogueDashboard;
		