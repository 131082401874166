import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import useStyles from './MockTestStyle';
import useCommonStyles from '../../common/style';
import useDebounce from '../../hooks/useDebounce';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import API from '../../axios/axiosApi';
import { formatDate } from '../../utils/formatter';

const headCells = [
	{ key: 'title', numeric: false, disablePadding: false, label: 'Title', sortable: true },
	{ key: 'createdAt', numeric: false, disablePadding: false, label: 'Created At', sortable: true },
];

const MockTests = (props) => {
	const { location: { state }, history } = props;
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const languageName = (state && state.languageName) ? state.languageName : '';
	const languageId = (state && state.languageId) ? state.languageId : 0;
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [records, setRecords] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const debouncedSearch = useDebounce(search, 500);

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('createdAt');
	const [selected, setSelected] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [refreshRecords, setRefreshRecords] = useState(false);


	useEffect(() => {
		if (!state) { return history.push('/mock-tests'); }
		const fetchMockTest = async () => {
			setLoading(true);
			try {
				let postData = {
					languageId,
					order,
					orderBy,
					pageOffset: page * pageSize,
					pageSize,
					searchText: debouncedSearch,
				};
				const response = await API.post('mock_tests', postData);
				if (response.data.success) {
					// console.log("videos data ==> ",response.data.data);
					setRecords(response.data.data.rows);
					setTotalRecords(response.data.data.count);
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchMockTest : ", error);
				setLoading(false);
			}
		};
		fetchMockTest();
	}, [state, history, languageId, debouncedSearch, page, pageSize, order, orderBy, refreshRecords]);

	/**
	 * Handle on search change
	 * 
	 * @param {*} event 
	 */
	const handleSearch = (event) => {
		setSearch(event.target.value)
	};

	/**
	 * Handle sorting change
	 * 
	 * @param {*} event 
	 * @param {*} property 
	 */
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
		setSelected([]);
	};

	/**
	 * Toogle select all
	 * 
	 * @param {*} event 
	 */
	const handleSelectAll = event => {
		let newSelected = [];
		if (event.target.checked) {
			newSelected = records.map((item, index) => index);
		}
		setSelected(newSelected);
	};

	/**
	 * Toogle checkbox selection
	 * 
	 * @param {*} event 
	 * @param {*} index 
	 */
	const handleCheck = (event, index) => {
		const selectedIndex = selected.indexOf(index);
		let newSelected = [ ...selected];

		if (event.target.checked && selectedIndex === -1) {
			newSelected = [ ...newSelected, index ];

		} else if (selectedIndex > -1) {
			newSelected.splice(selectedIndex, 1);
		}
		// console.log("newSelected : ",newSelected);
		setSelected(newSelected);
	};

	/**
	 * Handle page change and refeacth data
	 * 
	 * @param {*} event 
	 * @param {*} newPage 
	 */
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setSelected([]);
	};

	/**
	 * On change page size
	 * 
	 * @param {*} event 
	 */
	const handleChangeRowsPerPage = event => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
		setSelected([]);
	};

	/**
	 * Solft delete mock tests
	 */
	const handleDelete = async () => {
		if (selected.length > 0) {
			let deleteIds = selected.map(item => records[item].id);
			// console.log("deleteIds : ",deleteIds);
			try {
				setLoading(true);
				const response = await API.post('delete_mock_tests', { deleteIds });
				if (response.data.success) {
					// console.log("response asdsadsadsa ==> ",response.data);
					handleSnackToogle(response.data.message)
					setSelected([]);
					setRefreshRecords(refreshRecords => (!refreshRecords));
				} else {
					console.log("response ==> ",response.data);
					handleSnackToogle(response.data.message)
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in handleDelete : ", error);
				setLoading(false);
			}
		}
	};

	const openAddMockTest = () => {
		props.history.push({ pathname: '/mock-tests/list/add', state: { languageId } });
	};

	const openDetailPage = (index) => {
		// console.log("records[index]: ",records[index]);
		if (records[index]) {
			props.history.push({ pathname: '/mock-tests/list/' + records[index].id, state: { languageId: records[index].language_id } });
		}
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	// const emptyRows = rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);
	const emptyRows = pageSize - records.length;

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<QuestionAnswerIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">{languageName} Mock Tests</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={openAddMockTest}>Add Mock Test</Button>
				</div>
			</div>
			<div className={classes.content}>
				<Paper className={clsx(classes.paper, commonClasses.paperContainer)}>
					{ loading ? <LinearProgress className={commonClasses.progressBar} /> : null }
					<EnhancedTableToolbar
						numSelected={selected.length}
						onSearchChange={handleSearch}
						handleDelete={handleDelete}
						searchComment="Search Mock Test"
					/>
					<TableContainer>
						<Table className={classes.table} size='medium'>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAll={handleSelectAll}
								onRequestSort={handleRequestSort}
								rowCount={records.length}
								headCells={headCells}
							/>
							<TableBody>
							{ records.length === 0 ? (
								<TableRow>
									<TableCell colSpan={headCells.length}>No records found</TableCell>
								</TableRow> ): null }
							{ records.map((row, index) => {
								// const labelId = `enhanced-table-checkbox-${index}`;
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={selected.includes(index)}
										key={index}
										selected={selected.includes(index)}
										onClick={() => openDetailPage(index)}
										className={classes.rowHover}
									>
										<TableCell padding="checkbox">
											<Checkbox
												checked={selected.includes(index)}
												onClick={event => event.stopPropagation()}
												onChange={event => handleCheck(event, index)}
											/>
										</TableCell>
										{/* <TableCell component="th" id={labelId} scope="row" padding="none">
											{row.word}
										</TableCell> */}
										<TableCell>{row.title}</TableCell>
										<TableCell>{formatDate(row.createdAt)}</TableCell>
									</TableRow>
								);
							}) }
							{emptyRows > 0 && (
								<TableRow style={{ height:  53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 100]}
						component="div"
						count={totalRecords}
						rowsPerPage={pageSize}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>
		</div>
	);
};

export default MockTests;