import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import useStyles from './EnquiryDialogStyle';
import useCommonStyles from '../../common/style';

const EnquiryDialog = (props) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const { open, onClose, dialogData } = props;
    // console.log(dialogData);

	return (
        <Dialog classes={{ paper: commonClasses.dialogueFullwidth }} onClose={() => onClose()} aria-labelledby="customized-dialog-title" open={open}>
            <AppBar position="static" className={classes.dialogHeader} elevation={2}>
                <Typography variant="h5" color="inherit" className={classes.dialogTitle}>
                    Enquiry
                </Typography>
                <IconButton aria-label="delete" onClick={() => onClose()} color="inherit">
                    <CloseIcon />
                </IconButton>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                {dialogData.user ? (
                    <Grid container spacing={3} className="detail-container">
                        <Grid item md={12} xs={12} className="detail-container-fields">
                            <label>First Name</label> { dialogData.user.first_name }
                        </Grid>
                        <Grid item md={12} xs={12} className="detail-container-fields">
                            <label>Last Name</label> { dialogData.user.last_name }
                        </Grid>
                        <Grid item md={12} xs={12} className="detail-container-fields">
                            <label>Email</label> { dialogData.user.email }
                        </Grid>
                        <Grid item md={12} xs={12} className="detail-container-fields">
                            <label>Mobile</label> { dialogData.user.mobile }
                        </Grid>
                        <Grid item md={12} xs={12} className="detail-container-fields">
                            <label>Enquiry</label> { dialogData.enquiry }
                        </Grid>
                    </Grid>
                ) : null }
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button autoFocus onClick={() => onClose()} color="primary" >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
	);
};

EnquiryDialog.propTypes = {
	open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dialogData: PropTypes.object.isRequired,
};

export default EnquiryDialog;