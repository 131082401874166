import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	pageHeader: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		marginTop: theme.spacing(2),
		width: '70%',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		}
	},
	bannerImg: {
		padding: theme.spacing(3),
		borderRadius: theme.spacing(2),
	},
	list: {
		paddingLeft: theme.spacing(2),
	},
	rowHover: {
		cursor: 'pointer',
	},
}));