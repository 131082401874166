import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';

import VpnKeyIcon from '@material-ui/icons/VpnKey';

import * as moment from 'moment';

import useStyles from './AccessCodeStyle';
import useCommonStyles from '../../../common/style';
import useDebounce from '../../../hooks/useDebounce';
import EnhancedTableToolbar from '../../../components/EnhancedTableToolbar';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import API from '../../../axios/axiosApi';
import { formatDate } from '../../../utils/formatter';


const headCells = [
	{ key: 'code', numeric: false, disablePadding: false, label: 'Code', sortable: true },
	{ key: 'user_id', numeric: false, disablePadding: false, label: 'User', sortable: true },
	{ key: 'email', numeric: false, disablePadding: false, label: 'Email', sortable: true },
	{ key: 'plan_id', numeric: false, disablePadding: false, label: 'Plan', sortable: true },
	{ key: 'is_used', numeric: false, disablePadding: false, label: 'Used', sortable: true },
	{ key: 'createdAt', numeric: false, disablePadding: false, label: 'Created At', sortable: true },
	{ key: 'valid_till;', numeric: false, disablePadding: false, label: 'Valid Till', sortable: true },
	{ key: 'is_active', numeric: false, disablePadding: false, label: 'Status', sortable: true },
];

const AccessCodes = (props) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [records, setRecords] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const debouncedSearch = useDebounce(search, 500);

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('createdAt');
	const [selected, setSelected] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [snack, setSnack] = useState({ open: false, message: '' });
	// const [refreshRecords, setRefreshRecords] = useState(false);
	

	useEffect(() => {
		const fetchCodes = async () => {
			setLoading(true);
			try {
				let postData = {
					order,
					orderBy,
					pageOffset: page * pageSize,
					pageSize,
					searchText: debouncedSearch,
				};
				const response = await API.post('access_codes', postData);
				if (response.data.success) {
					// console.log("videos data ==> ",response.data.data);
					setRecords(response.data.data.rows);
					setTotalRecords(response.data.data.count);
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchCodes : ", error);
				setLoading(false);
			}
		};
		fetchCodes();
	}, [debouncedSearch, page, pageSize, order, orderBy]); // refreshRecords

	/**
	 * Handle on search change
	 * 
	 * @param {*} event 
	 */
	const handleSearch = (event) => {
		setSearch(event.target.value)
	};

	/**
	 * Handle sorting change
	 * 
	 * @param {*} event 
	 * @param {*} property 
	 */
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
		setSelected([]);
	};

	/**
	 * Handle page change and refeacth data
	 * 
	 * @param {*} event 
	 * @param {*} newPage 
	 */
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setSelected([]);
	};

	/**
	 * On change page size
	 * 
	 * @param {*} event 
	 */
	const handleChangeRowsPerPage = event => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
		setSelected([]);
	};

	const openGenerateCode = () => {
		props.history.push('/manage/access_codes/add');
	};

	/**
	 * Copy to clipboard
	 * 
	 * @param {String} code 
	 */
	const copyCode = (code) => {
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = code;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		handleSnackToogle('Copied to clipboard');
	};

	const toogleStatus = async (value, index) => {
		// console.log("value ==> ",value);
		// console.log("record ==> ", records[index]);
		try {
			setLoading(true);
			const data = {
				status: value,
				id: records[index].id,
			};
			const response = await API.put('toggle_code_status', data);
			if (response.data.success) {
				// console.log("response asdsadsadsa ==> ",response.data);
				handleSnackToogle(response.data.message);
				records[index].is_active = value;
				setRecords(records);

				// setRefreshRecords(refreshRecords => (!refreshRecords));
			} else {
				console.log("response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in toogleStatus : ", error);
			setLoading(false);
		}
	};

	/**
	 * Deactivate / activate access code
	 * @param {*} event 
	 * @param {*} index 
	 */
	const handleStatusChange = (event, index) => {
		if (!checkDisableStatus(index)) {
			toogleStatus(event.target.checked, index);
		}
	};

	const checkDisableStatus = (index) => {
		let item = records[index];
		return item.is_used || moment(item.valid_till).isBefore(moment());
	}

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	// const emptyRows = rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);
	const emptyRows = pageSize - records.length;

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<VpnKeyIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">Access Codes</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={openGenerateCode}>Generate Code</Button>
				</div>
			</div>
			<div className={classes.content}>
				<Paper className={clsx(classes.paper, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar} /> : null }
					<EnhancedTableToolbar
						numSelected={selected.length}
						onSearchChange={handleSearch}
						handleDelete={() => {}}
						searchComment="Search Access Code"
					/>
					<TableContainer>
						<Table className={classes.table} size='medium'>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAll={() => {}}
								onRequestSort={handleRequestSort}
								rowCount={records.length}
								headCells={headCells}
								haveMultiSelect={false}
							/>
							<TableBody>
							{ records.length === 0 ? (
								<TableRow>
									<TableCell colSpan={headCells.length}>No records found</TableCell>
								</TableRow> ): null }
							{ records.map((row, index) => {
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={selected.includes(index)}
										key={index}
										selected={selected.includes(index)}
										// onClick={() => openDetailPage(index)}
										// className={classes.rowHover}
									>
										<TableCell>
											<Tooltip title="Copy to clipboard">
												<span 
													onClick={() => copyCode(row.code)}
													className={classes.rowHover}
												>
													{row.code}
												</span>
											</Tooltip>
										</TableCell>
										<TableCell>{row.user ? row.user.first_name + ' ' + row.user.last_name : '-'}</TableCell>
										<TableCell>{row.user.email ? row.user.email : '-'}</TableCell>
										<TableCell>{row.plan ? row.plan.name : '-'}</TableCell>
										<TableCell>{row.is_used ? 'Used' : 'Not Used'}</TableCell>
										<TableCell>{formatDate(row.createdAt)}</TableCell>
										<TableCell>{formatDate(row.valid_till)}</TableCell>
										<TableCell>
											<Switch
												checked={row.is_active}
												disabled={checkDisableStatus(index)}
												onChange={(event) => handleStatusChange(event, index) }
											/>
											{/* {row.is_active ? 'Active' : 'Inactive'} */}
										</TableCell>
									</TableRow>
								);
							}) }
							{emptyRows > 0 && (
								<TableRow style={{ height:  53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 100]}
						component="div"
						count={totalRecords}
						rowsPerPage={pageSize}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>

		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={snack.open}
			onClose={() => handleSnackToogle()}
			message={snack.message}
			autoHideDuration={3000}
		/>
		</div>
	);
};

export default AccessCodes;