import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import validate from 'validate.js';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useStyles from './PlanDetailStyle';
import useCommonStyles from '../../../common/style';
import API from '../../../axios/axiosApi';
import { formatUnderscore } from '../../../utils/formatter';

let schema = {
	name: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	plan_type_id: {
		presence: { allowEmpty: false, message: '^Plan type is required' },
	},
	description: {
		presence: { allowEmpty: false, message: 'is required' },
	}
};


const PlanDetails = (props) => {
	const { match : { params }, history } = props;
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const type = (params.id && params.id === 'add') ? 'add' : 'edit';

	const [pageTitle, setPageTitle] = useState((type === 'add') ? 'Add Plan' : 'Update Plan');
	const [formState, setFormState] = useState({
		isValid: false,
		values: {
			name: '',
			is_active: true,
			plan_type_id: 0,
			price: '',
			validity: '',
		},
		touched: {},
		errors: {}
	});
	const [planTypes, setPlanTypes] = useState([]);
	const [selectedPlanType, setSelectedPlanType] = useState({});

	useEffect(() => {
		// console.log(params);
		const fetchPlanTypes = async () => {
			try {
				const response = await API.get('plan_types');
				if (response.data.success) {
					const resData = response.data.data;
					setPlanTypes(resData.plan_types);
					if (params.id && params.id !== 'edit') {
						setFormState(formState => ({
							...formState,
							values: {
								...formState.values,
								plan_type_id: resData.default_plan_type_id,
							}
						}));

						const planTypeIndex = resData.plan_types.map(item => item.id).indexOf(resData.default_plan_type_id);
						setSelectedPlanType(resData.plan_types[planTypeIndex]);
						if (params.id && params.id !== 'add') {
							fetchPlanDetails(resData.plan_types);
						}
					}
				} else {
					handleSnackToogle(response.data.message);
				}
			} catch (error) {
				console.log("ERROR in fetchPlanTypes : ", error);
				setLoading(false);
			}
		};
		fetchPlanTypes();
		const fetchPlanDetails = async (planTypes) => {
			setLoading(true);
			try {
				const response = await API.get('plans/' + params.id);
				if (response.data.success) {
					if (!response.data.data.plan_details) {
						handleSnackToogle("Record for this ID don't exists!");
						return history.goBack();
					}
					const planDetails = (response.data.data.plan_details) ? response.data.data.plan_details : {};
					setFormState(formState => ({
						...formState,
						values: {
							name 		: planDetails.name,
							price 		: planDetails.price,
							validity 	: planDetails.validity,
							plan_type_id: planDetails.plan_type_id,
							is_active	: planDetails.is_active,
							description	: planDetails.description,
							include_mock_tests: planDetails.include_mock_tests,
							include_in_app: planDetails.include_in_app,
						}
					}));
					setPageTitle(planDetails.name);
					const selectedPlanType = planTypes.filter(item => item.id === planDetails.plan_type_id).shift();
					setSelectedPlanType(selectedPlanType);
				} else {
					handleSnackToogle(response.data.message);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchVideoDetails : ", error);
				setLoading(false);
			}
		};
	}, [params, history]);

	useEffect(() => {
		if (selectedPlanType.name && selectedPlanType.name.toLowerCase() === 'paid') {
			schema = {
				...schema,
				price: {
					presence: { allowEmpty: false, message: 'is required' },
					numericality: { greaterThan: 0 , message: 'must be a positve number'}, // greaterThanOrEqualTo
				},
				validity: {
					presence: { allowEmpty: false, message: 'is required' },
					numericality: { onlyInteger: true, greaterThan: 0 , message: 'must be a positve integer'},
				},
			};
		} else {
			delete schema.price;
			delete schema.validity;
		}
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		}));
	}, [formState.values, selectedPlanType]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = event => {
		event.persist();
		// console.log(event.target.name + "  ===  " + event.target.value);
		setFormState(formState => ({
		...formState,
		values: {
			...formState.values,
			[event.target.name]:
			event.target.type === 'checkbox'
				? event.target.checked
				: event.target.value
		},
		touched: {
			...formState.touched,
			[event.target.name]: true
		}
		}));
		if (event.target.name === 'plan_type_id') {
			const planTypeIndex = planTypes.map(item => item.id).indexOf(event.target.value);
			setSelectedPlanType(planTypes[planTypeIndex]);
		}
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	/**
	 * Add and update plan
	 */
	const savePlan = async (event) => {
		event.preventDefault();
		// console.log(formState);
		try {
			setLoading(true);
			let postData = { ...formState.values, type, selectedPlanType };
			if (type === 'edit') {
				postData = { ...postData, id: params.id };
			}
			const response = await API.post('save_plan', postData);
			if (response.data.success) {
				// console.log("response.data ==> ",response.data);
				handleSnackToogle(response.data.message);
				setPageTitle(formState.values.name);
				if (type === 'add') { props.history.goBack(); }
			} else {
				console.log("response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in handleSubmit : ", error);
		}
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<IconButton size="small" aria-label="go-back" onClick={() => props.history.goBack() }>
							<ArrowBackIcon />
						</IconButton>
						<MonetizationOnOutlinedIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">{pageTitle}</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={savePlan} disabled={!formState.isValid}>Save Plan</Button>
				</div>
			</div>
			<Paper className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<form className={classes.form}>
					<Grid container spacing={3} >
						<Grid item md={12} xs={12} >
							<CustomTextField
								error={hasError('name')}
								helperText={
									hasError('name') ? formState.errors.name[0] : null
								}
								label="Name"
								name="name"
								type="text"
								value={formState.values.name || ''}
								onChange={handleChange}
								required
							/>
						</Grid>
						<Grid item md={12} xs={12} >
							<FormControl className={classes.textField} fullWidth variant="outlined">
								<InputLabel id="select-label">Status</InputLabel>
								<Select
									labelId="select-label"
									id="status-select-outlined"
									name="is_active"
									fullWidth
									value={formState.values.is_active}
									onChange={handleChange}
									labelWidth={40}
								>
									<MenuItem value={true}>Active</MenuItem>
									<MenuItem value={false}>Inactive</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={12} xs={12} >
							<FormControl className={classes.textField} fullWidth variant="outlined" required>
								<InputLabel id="plan-type-label">Plan Type</InputLabel>
								<Select
									labelId="plan-type-label"
									id="plan-type-outlined"
									name="plan_type_id"
									fullWidth
									value={formState.values.plan_type_id || ''}
									onChange={handleChange}
									labelWidth={70}
								>
								{
									planTypes.map((planType, index) => (
										<MenuItem key={index} value={planType.id}>{formatUnderscore(planType.name)}</MenuItem>
									))
								}
								</Select>
							</FormControl>
						</Grid>
						{ (selectedPlanType.name && selectedPlanType.name.toLowerCase() === 'paid') ? (
							<Grid container spacing={3} className={classes.paidPlanFields} >
								<Grid item md={6} xs={12} >
									<CustomTextField
										error={hasError('price')}
										helperText={
											hasError('price') ? formState.errors.price[0] : null
										}
										label="Price"
										name="price"
										type="number"
										value={formState.values.price || ''}
										onChange={handleChange}
										required
									/>
								</Grid>
								<Grid item md={6} xs={12} >
									<CustomTextField
										error={hasError('validity')}
										helperText={
											hasError('validity') ? formState.errors.validity[0] : "Validity in days"
										}
										label="Validity"
										name="validity"
										type="number"
										value={formState.values.validity || ''}
										onChange={handleChange}
										required
									/>
								</Grid>
							</Grid>
						) : null }
						<Grid item md={12} xs={12} >
							<CustomTextField
								error={hasError('description')}
								helperText={
									hasError('description') ? formState.errors.description[0] : null
								}
								label="Description"
								name="description"
								type="text"
								value={formState.values.description || ''}
								onChange={handleChange}
								required
								multiline={true}
								rows={3}
							/>
							{/* <TextField
								className={classes.textField}
								fullWidth
								label="Description"
								name="description"
								onChange={(event) => handleChange('description', event.target.value)}
								type="text"
								value={videoForm.description}
								variant="outlined"
								multiline={true}
								rows={3}
							/> */}
						</Grid>
						<Grid container spacing={3} className={classes.paidPlanFields} >
							<Grid item md={6} xs={12} >
								<FormControlLabel
									control={
										<Checkbox
											checked={formState.values.include_mock_tests || false}
											onChange={handleChange}
											name="include_mock_tests"
											color="primary"
										/>
									}
									label="Include Mock Tests"
								/>
							</Grid>
							<Grid item md={6} xs={12} >
								<FormControlLabel
									control={
										<Checkbox
											checked={formState.values.include_in_app || false}
											onChange={handleChange}
											name="include_in_app"
											color="primary"
										/>
									}
									label="Include In App"
								/>
							</Grid>
						</Grid>						
					</Grid>
				</form>
			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>
		</div>
	);
};

const CustomTextField = (props) => {
	const classes = useStyles();
	return (
		<TextField
			{ ...props }
			className={classes.textField}
			fullWidth
			variant="outlined"
		/>
	);
};

export default PlanDetails;