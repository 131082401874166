import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		justifyContent: 'center',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	root: {
		padding: theme.spacing(3),
		position: 'relative',
	},
	content: {
		width: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	form: {
		width: '100%',
		paddingTop: theme.spacing(2),
		padding: theme.spacing(4),
	},
	textField: {
		marginTop: theme.spacing(3)
	},
	input: {
		display: 'none',
	},
	audio: {
		marginTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	uploadButton: {
		padding: '11px 20px',
		borderRadius: 30,
		marginRight: theme.spacing(3)
	},
	audioComponent: {
		outline: 'none'
	},
	segmentContainer: {
		display: 'flex'	,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	segment: {
		borderRadius: theme.spacing(1),
		border: '1px gray solid',
		padding: theme.spacing(2),
		marginTop: theme.spacing(4),
		width: '90%'
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	emptyAudioError: {
		color: theme.palette.error.main
	}
}));