import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import TranslateIcon from '@material-ui/icons/Translate';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HistoryIcon from '@material-ui/icons/History';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
	drawer: {
		width: 240,
		[theme.breakpoints.up('lg')]: {
			marginTop: 64,
			height: 'calc(100% - 64px)'
		}
	},
	root: {
		backgroundColor: theme.palette.white,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: theme.spacing(2)
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	nav: {
		marginBottom: theme.spacing(2)
	}
}));

const Sidebar = props => {
	const { open, variant, onClose, className, ...rest } = props;

	const classes = useStyles();

	const pages = [
		{
			title: 'Dashboard',
			href: '/dashboard',
			icon: <DashboardIcon />
		},
		{
			title: 'Users',
			href: '/users',
			icon: <PeopleIcon />
		},
		{
			title: 'Videos',
			href: '/videos',
			icon: <VideoLibraryIcon />
		},
		{
			title: 'Vocabulary',
			href: '/vocabulary',
			icon: <TranslateIcon />
		},
		{
			title: 'Online Search Vocabulary',
			href: '/online-vocabulary',
			icon: <TranslateIcon />
		},
		{
			title: 'User Saved Vocabulary',
			href: '/saved-vocabulary',
			icon: <TranslateIcon />
		},
		{
			title: 'Dialogues',
			href: '/dialogues',
			icon: <LibraryMusicIcon />
		},
		{
			title: 'Last Minute Practice',
			href: '/exam-memories',
			icon: <HistoryIcon />
		},
		{
			title: 'Mock Tests',
			href: '/mock-tests',
			icon: <QuestionAnswerIcon />
		},
		{
			title: 'Mock Test Responses',
			href: '/mock-responses',
			icon: <AccessTimeIcon />
		},
		{
			title: 'Enquiries',
			href: '/enquiries',
			icon: <PermPhoneMsgIcon />
		},
		{
			title: 'Manage',
			icon: <SettingsIcon />,
			children: [
				{
					title: 'Mobile App Settings',
					href: '/manage/mobile_app_settings',
				},
				{
					title: 'Languages',
					href: '/manage/languages',
				},
				{
					title: 'FAQs',
					href: '/manage/faq',
				},
				{
					title: 'Vocabulary Categories',
					href: '/manage/vocabulary-categories',
				},
				{
					title: 'Plans',
					href: '/manage/plans',
				},
				{
					title: 'Access Codes',
					href: '/manage/access_codes',
				},
				{
					title: 'Recent Results',
					href: '/manage/recent-results',
				},
			]
		},
		
	];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
	variant: PropTypes.string.isRequired
};

export default Sidebar;
