import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useStyles from './VideoDetailStyle';
import useCommonStyles from '../../common/style';
import API from '../../axios/axiosApi';


const VideoDetails = (props) => {
	const { match: { params }, history } = props;

	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [videoForm, setVideoForm] = useState({		
		title: '',
		link: '',
		is_active: true,
		category:0,
		description: '',
		errors: {
			title: '',
			link: '',
			description: '',
		}
	});
	const [snack, setSnack] = useState({ open: false, message: '' });


	const type = (params.id && params.id === 'add') ? 'add' : 'edit';
	const [pageTitle, setPageTitle] = useState((type === 'add') ? 'Add Video' : 'Update Video');

	useEffect(() => {
		// console.log(params);
		if (params.id && params.id !== 'add') {
			const fetchVideoDetails = async () => {
				setLoading(true);
				try {
					const response = await API.get('videos/' + params.id);
					if (response.data.success) {
						if (!response.data.data.video_details) {
							handleSnackToogle("Record for this ID don't exists!");
							return history.goBack();
						}
						const videoDetails = (response.data.data.video_details) ? response.data.data.video_details : {};
						setVideoForm((videoForm) => ({
							...videoForm,
							title: videoDetails.title,
							description: videoDetails.description,
							link: videoDetails.link,
							is_active: videoDetails.is_active,
							category: videoDetails.type
						}));
						setPageTitle(videoDetails.title);
					} else {
						console.log("response ==> ",response.data);
					}
					setLoading(false);
				} catch (error) {
					console.log("ERROR in fetchVideoDetails : ", error);
					setLoading(false);
				}
			};
			fetchVideoDetails();
		}
	}, [params, history]);


	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = (fieldName, value) => {
		if (fieldName === 'title' || fieldName === 'link') {
			validate(fieldName, value);
		}
		setVideoForm(video => ({ ...video, [fieldName]: value }));
	};

	const validate = (fieldName, value) => {
		let form = { ...videoForm };
		value = value.trim();
		form.errors[fieldName] = (value === '') ? fieldName.charAt(0).toUpperCase() + fieldName.slice(1)  + ' is required' : '';
		setVideoForm(form);
	};

	const checkErrors = () => {
		let form = { ...videoForm }, haveError = false;
		for (const errors in form.errors) {
			if (form.errors.hasOwnProperty(errors)) {
				if (form.errors[errors]) { haveError = true; }
			}
		}
		return haveError;
	};

	/**
	 * Add and update video vimeo link
	 */
	const saveVideo = async () => {
		validate('title', videoForm.title);
		validate('link', videoForm.link);
		const { title, link, is_active, category, description } = videoForm;
		let postData = { title, link, is_active, category, description, type };
		
		if (!checkErrors()) {
			setLoading(true);
			try {
				if (type === 'edit') {
					postData = { ...postData, id: params.id };
				}
				const response = await API.post('save_video', postData);
				if (response.data.success) {
					// console.log("response.data ==> ",response.data);
					handleSnackToogle(response.data.message);
					setPageTitle(title);
					if (type === 'add') { props.history.goBack(); }
				} else {
					console.log("response ==> ",response.data);
					handleSnackToogle(response.data.message)
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in saveLanguage : ", error);
				setLoading(false);
			}
		}
	};


	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};


	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<IconButton size="small" aria-label="go-back" onClick={() => props.history.goBack() }>
							<ArrowBackIcon />
						</IconButton>
						<PlayCircleFilledIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">{pageTitle}</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={saveVideo} disabled={loading || checkErrors()}>Save Video</Button>
				</div>
			</div>
			<Paper className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<form className={classes.form}>
					<TextField
						className={classes.textField}
						fullWidth
						label="Title"
						name="title"
						onChange={(event) => handleChange('title', event.target.value)}
						type="text"
						value={videoForm.title}
						variant="outlined"
						required
						error={videoForm.errors.title ? true : false}
						helperText={videoForm.errors.title}
					/>
					<TextField
						className={classes.textField}
						fullWidth
						label="Link"
						name="link"
						onChange={(event) => handleChange('link', event.target.value)}
						type="text"
						value={videoForm.link}
						variant="outlined"
						required
						error={videoForm.errors.link ? true : false}
						helperText={videoForm.errors.link || "Example: Enter 'SD4Z8dlZPd8' from 'https://www.youtube.com/watch?v=SD4Z8dlZPd8'"}
					/>

					<FormControl className={classes.textField} fullWidth variant="outlined">
						<InputLabel id="select-label">Status</InputLabel>
						<Select
							labelId="select-label"
							id="demo-simple-select-outlined"
							name="status"
							fullWidth
							value={videoForm.is_active}
							onChange={(event) => handleChange('is_active', event.target.value)}
							labelWidth={40}
						>
							<MenuItem value={true}>Active</MenuItem>
							<MenuItem value={false}>Inactive</MenuItem>
						</Select>
					</FormControl>
					<FormControl className={classes.textField} fullWidth variant="outlined">
						<InputLabel id="select-label1">Type</InputLabel>
						<Select
							labelId="select-label1"
							id="demo-simple-select-outlined"
							name="type"
							fullWidth
							value={videoForm.category}
							onChange={(event) => handleChange('category', event.target.value)}
							labelWidth={40}
						>
							<MenuItem value={0}>Tutorial Video</MenuItem>
							<MenuItem value={1}>Dashboard Video</MenuItem>
						</Select>
					</FormControl>
					<TextField
						className={classes.textField}
						fullWidth
						label="Description"
						name="description"
						onChange={(event) => handleChange('description', event.target.value)}
						type="text"
						value={videoForm.description}
						variant="outlined"
						multiline={true}
						rows={3}
					/>
				</form>
			</Paper>

		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={snack.open}
			onClose={() => handleSnackToogle()}
			message={snack.message}
			autoHideDuration={3000}
		/>
		</div>
	);
};

export default VideoDetails;