import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

import useStyles from './PlanStyle';
import useCommonStyles from '../../../common/style';
import useDebounce from '../../../hooks/useDebounce';
import EnhancedTableToolbar from '../../../components/EnhancedTableToolbar';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import API from '../../../axios/axiosApi';
import { formatDate, formatUnderscore } from '../../../utils/formatter';

const headCells = [
	{ key: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
	{ key: 'plan_type', numeric: false, disablePadding: false, label: 'Plan Type', sortable: true },
	{ key: 'price', numeric: false, disablePadding: false, label: 'Price (In USD)', sortable: true },
	{ key: 'validity', numeric: false, disablePadding: false, label: 'Validity (In days)', sortable: true },
	{ key: 'is_active', numeric: false, disablePadding: false, label: 'Status', sortable: true },
	{ key: 'createdAt', numeric: false, disablePadding: false, label: 'Created At', sortable: true },
];


const Plans = (props) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [records, setRecords] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const debouncedSearch = useDebounce(search, 500);

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [selected, setSelected] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [refreshRecords, setRefreshRecords] = useState(false);
	

	useEffect(() => {
		const fetchPlans = async () => {
			setLoading(true);
			try {
				let postData = {
					order,
					orderBy,
					pageOffset: page * pageSize,
					pageSize,
					searchText: debouncedSearch,
				};
				const response = await API.post('plans', postData);
				if (response.data.success) {
					setRecords(response.data.data.rows);
					setTotalRecords(response.data.data.count);
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchPlans : ", error);
				setLoading(false);
			}
		};
		fetchPlans();
	}, [debouncedSearch, page, pageSize, order, orderBy, refreshRecords]);

	/**
	 * Handle on search change
	 * 
	 * @param {*} event 
	 */
	const handleSearch = (event) => {
		setSearch(event.target.value)
	};

	/**
	 * Handle sorting change
	 * 
	 * @param {*} event 
	 * @param {*} property 
	 */
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	/**
	 * Toogle select all
	 * 
	 * @param {*} event 
	 */
	const handleSelectAll = event => {
		let newSelected = [];
		if (event.target.checked) {
			newSelected = records.map((item, index) => index);
		}
		setSelected(newSelected);
	};

	/**
	 * Toogle checkbox selection
	 * 
	 * @param {*} event 
	 * @param {*} index 
	 */
	const handleCheck = (event, index) => {
		const selectedIndex = selected.indexOf(index);
		let newSelected = [ ...selected];

		if (event.target.checked && selectedIndex === -1) {
			newSelected = [ ...newSelected, index ];

		} else if (selectedIndex > -1) {
			newSelected.splice(selectedIndex, 1);
		}
		// console.log("newSelected : ",newSelected);
		setSelected(newSelected);
	};

	/**
	 * Handle page change and refeacth data
	 * 
	 * @param {*} event 
	 * @param {*} newPage 
	 */
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	/**
	 * On change page size
	 * 
	 * @param {*} event 
	 */
	const handleChangeRowsPerPage = event => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleDelete = async () => {
		if (selected.length > 0) {
			let deleteIds = selected.map(item => records[item].id);
			console.log("deleteIds : ",deleteIds);
			try {
				setLoading(true);
				const response = await API.post('delete_plans', { deleteIds });
				if (response.data.success) {
					// console.log("response asdsadsadsa ==> ",response.data);
					handleSnackToogle(response.data.message)
					setSelected([]);
					setRefreshRecords(refreshRecords => (!refreshRecords));
				} else {
					console.log("response ==> ",response.data);
					handleSnackToogle(response.data.message)
				}
			setLoading(false);
			} catch (error) {
				console.log("ERROR in handleDelete : ", error);
				setLoading(false);
			}
		}
	};

	const openAddPlan = () => {
		// console.log(props);
		props.history.push('/manage/plans/add');
	};

	const openDetailPage = (index) => {
		// console.log("records[index]: ",records[index]);
		if (records[index]) {
			props.history.push('/manage/plans/' + records[index].id);
		}
	}

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	// const emptyRows = rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);
	const emptyRows = pageSize - records.length;

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<MonetizationOnOutlinedIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">Plans</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={openAddPlan}>Add Plan</Button>
				</div>
			</div>
			<div className={classes.content}>
				<Paper className={clsx(classes.paper, commonClasses.paperContainer)}>
					{ loading ? <LinearProgress className={commonClasses.progressBar} /> : null }
					<EnhancedTableToolbar
						numSelected={selected.length}
						onSearchChange={handleSearch}
						handleDelete={handleDelete}
						searchComment="Search Plan"
					/>
					<TableContainer>
						<Table className={classes.table} size='medium'>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAll={handleSelectAll}
								onRequestSort={handleRequestSort}
								rowCount={records.length}
								headCells={headCells}
							/>
							<TableBody>
							{ records.length === 0 ? (
								<TableRow>
									<TableCell colSpan={headCells.length}>No records found</TableCell>
								</TableRow> ): null }
							{ records.map((row, index) => {
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={selected.includes(index)}
										key={index}
										selected={selected.includes(index)}
										onClick={() => openDetailPage(index)}
										className={classes.rowHover}
									>
										<TableCell padding="checkbox">
											<Checkbox
												checked={selected.includes(index)}
												onClick={event => event.stopPropagation()}
												onChange={event => handleCheck(event, index)}
											/>
										</TableCell>
										<TableCell>{row.name}</TableCell	>
										<TableCell>{formatUnderscore(row.plan_type.name)}</TableCell>
										<TableCell>{row.plan_type.name === 'free' ? '-' : row.price}</TableCell>
										<TableCell>{row.plan_type.name === 'free' ? '-' : row.validity}</TableCell>
										<TableCell>{row.is_active ? 'Active' : 'Inactive'}</TableCell>
										<TableCell>{formatDate(row.createdAt)}</TableCell>
										{/* <TableCell align="right">{row.createdAt}</TableCell> */}
									</TableRow>
								);
							}) }
							{emptyRows > 0 && (
								<TableRow style={{ height:  53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 100]}
						component="div"
						count={totalRecords}
						rowsPerPage={pageSize}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>

		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={snack.open}
			onClose={() => handleSnackToogle()}
			message={snack.message}
			autoHideDuration={3000}
		/>
		</div>
	);
};

export default Plans;