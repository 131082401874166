import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import API from '../../axios/axiosApi';
import useStyles from './VocabDashboardStyle';

const VocabDashboard = (props) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [vocabLanguages, setVocabLanguges] = useState([]);
	

	useEffect(() => {
		const fetchVocabLanguages = async () => {
			setLoading(true);
			try {
				const response = await API.get('vocab');
				if (response.data.success) {
					setVocabLanguges(response.data.data.vocab_languages)
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in handleSubmit : ", error);
				setLoading(false);
			}
		};
		fetchVocabLanguages();
	}, []);

	/**
	 * Go to vocabulary listing
	 * 
	 * @param {Object} language 
	 */
	const gotoVocabList = (language) => {
		props.history.push({ pathname: 'vocabulary/' + language.id, state: { languageName: language.name } });
	};
	
	return (
		<div className={classes.root}>
			<div className={classes.content}>
				{/* { loading ? <LinearProgress /> : null } */}
				<Backdrop className={classes.backdrop} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				{!loading ? (
					<Grid
						container
						spacing={3}
					>
					{(vocabLanguages.length > 0) ? (
						vocabLanguages.map((language, index) => (
							<Grid item key={index} lg={4} md={6} xs={12}>
								<Card>
									<CardContent>
										<Typography
											align="center"
											gutterBottom
											variant="h1"
											className={classes.languageLabel}
											color="primary"
										>
											{language.label}
										</Typography>
									</CardContent>
									<Divider />
									<CardActions>
										<Grid container justify="space-between" alignItems="center">
											<Button size="small" onClick={() => gotoVocabList(language)}>View All</Button>
											<Typography color="textSecondary" className={classes.cardActionGrid}>
												Total: {language.vocab_count}
											</Typography>
										</Grid>
									</CardActions>
								</Card>
							</Grid>
						))
					) : (
						<Grid direction="column" container justify="center" alignItems="center">
							<Typography
								align="center"
								variant="h2"
								className={classes.languageLabel}
							>
								Please create a language first
							</Typography>
							<Link to="/manage/languages">Go to language settings</Link>
						</Grid>
					)}
					</Grid>
				) : null}
			</div>
		</div>
	);
};

export default VocabDashboard;
		