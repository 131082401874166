import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(2)
	},
	languageLabel: {
		padding: theme.spacing(2),
		fontSize: 40
	},
	cardActionGrid: {
		paddingRight: theme.spacing(1)
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	gridContainer : {
		
	}
}));