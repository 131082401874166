import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';

import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useStyles from './FaqDetailStyle';
import API from '../../../axios/axiosApi';
import useCommonStyles from '../../../common/style';


const FaqDetails = (props) => {
	const { match: { params }, history } = props;

	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [faqForm, setFaqForm] = useState({		
		question: '',
		answer: '',
		errors: {
			question: '',
			answer: '',
		}
	});
	const [snack, setSnack] = useState({ open: false, message: '' });


	const type = (params.id && params.id === 'add') ? 'add' : 'edit';
	const pageTitle = (type === 'add') ? 'Add FAQ' : 'Update FAQ';

	useEffect(() => {
		// console.log(params);
		if (params.id && params.id !== 'add') {
			const fetchFaqDetails = async () => {
				setLoading(true);
				try {
					const response = await API.get('faqs/' + params.id);
					if (response.data.success) {
						if (!response.data.data.faq_details) {
							handleSnackToogle("Record for this ID don't exists!");
							return history.goBack();
						}
						const videoDetails = (response.data.data.faq_details) ? response.data.data.faq_details : {};
						setFaqForm((faqForm) => ({
							...faqForm,
							question: videoDetails.question,
							answer: videoDetails.answer,
						}));
					} else {
						console.log("response ==> ",response.data);
					}
					setLoading(false);
				} catch (error) {
					console.log("ERROR in fetchFaqDetails : ", error);
					setLoading(false);
				}
			};
			fetchFaqDetails();
		}
	}, [params, history]);


	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = (fieldName, value) => {
		// if (fieldName === 'title' || fieldName === 'answer') {
			validate(fieldName, value);
		// }
		setFaqForm(video => ({ ...video, [fieldName]: value }));
	};

	const validate = (fieldName, value) => {
		let form = { ...faqForm };
		value = value.trim();
		form.errors[fieldName] = (value === '') ? fieldName.charAt(0).toUpperCase() + fieldName.slice(1)  + ' is required' : '';
		setFaqForm(form);
	};

	const checkErrors = () => {
		let form = { ...faqForm }, haveError = false;
		for (const errors in form.errors) {
			if (form.errors.hasOwnProperty(errors)) {
				if (form.errors[errors]) { haveError = true; }
			}
		}
		return haveError;
	};

	/**
	 * Add and update video vimeo answer
	 */
	const saveFaq = async () => {
		validate('question', faqForm.question);
		validate('answer', faqForm.answer);
		const { question, answer } = faqForm;
		let postData = { question, answer, type };
		// console.log("postData : ",postData);
		if (!checkErrors()) {
			setLoading(true);
			try {
				if (type === 'edit') {
					postData = { ...postData, id: params.id };
				}
				const response = await API.post('save_faq', postData);
				if (response.data.success) {
					// console.log("response.data ==> ",response.data);
					handleSnackToogle(response.data.message);
					if (type === 'add') { props.history.goBack(); }
				} else {
					console.log("response ==> ",response.data);
					handleSnackToogle(response.data.message)
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in saveLanguage : ", error);
				setLoading(false);
			}
		}
	};


	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};


	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<IconButton size="small" aria-label="go-back" onClick={() => props.history.goBack() }>
							<ArrowBackIcon />
						</IconButton>
						<LiveHelpIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">{pageTitle}</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={saveFaq} disabled={loading || checkErrors()}>Save Faq</Button>
				</div>
			</div>
			<Paper className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<form className={classes.form}>
					<TextField
						className={classes.textField}
						fullWidth
						label="Question"
						name="question"
						onChange={(event) => handleChange('question', event.target.value)}
						type="text"
						value={faqForm.question}
						variant="outlined"
						required
						error={faqForm.errors.question ? true : false}
						helperText={faqForm.errors.question}
					/>
					<TextField
						className={classes.textField}
						fullWidth
						label="Answer"
						name="answer"
						onChange={(event) => handleChange('answer', event.target.value)}
						type="text"
						value={faqForm.answer}
						variant="outlined"
						required
						multiline={true}
						rows={4}
						error={faqForm.errors.answer ? true : false}
						helperText={faqForm.errors.answer}
					/>
				</form>
			</Paper>

		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={snack.open}
			onClose={() => handleSnackToogle()}
			message={snack.message}
			autoHideDuration={3000}
		/>
		</div>
	);
};

export default FaqDetails;