import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
// import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import useStyles from './MockTestDetailStyle';
import useCommonStyles from '../../common/style';
import API from '../../axios/axiosApi';
import Dialogue from '../../components/Dialogue';
import UploadingProgress from '../../components/UploadingProgress';
import { SERVER_PATH, MOCK_AUDIO_PATH } from '../../config';


const MockTestDetails = (props) => {
	const { match: { params }, history, location: { state } } = props;

	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const languageId = (state && state.languageId) ? state.languageId : 0;
	const [loading, setLoading] = useState(false);
	const [mockForm, setMockForm] = useState({
		title: '',
		test_duration: 20,
		mock_test_dialogues: [],
		errors: {
			title: '',
			test_duration: '',
		}
	});
	const mockTestId = params && params.id ? params.id : null;
	const [snack, setSnack] = useState({ open: false, message: '' });
	const type = (params.id && params.id === 'add') ? 'add' : 'edit';
	const [pageTitle, setPageTitle] = useState((type === 'add') ? 'Add Mock Test' : 'Update Mock Test');
	const [openDialogueModal, setOpenDialogueModal] = useState(false);
	const [selectedDialogue, setSelectedDialogue] = useState({});
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [openProgressDialogue, setOpenProgressDialogue] = useState(false);
	const [progress, setProgress] = useState(0);
	const [refresh, setRefresh] = useState(false);

	const audioAssetPath = SERVER_PATH + MOCK_AUDIO_PATH;

	useEffect(() => {
		if (!languageId) { return history.push('/mock-tests'); }
		if (params.id && params.id !== 'add') {
			const fetchMockDetails = async () => {
				setLoading(true);
				try {
					const response = await API.get('mock_tests/' + params.id);
					if (response.data.success) {
						if (!response.data.data.mock_details) {
							handleSnackToogle("Record for this ID don't exists!");
							return history.goBack();
						}
						const mockDetails = (response.data.data.mock_details) ? response.data.data.mock_details : {};						
						setMockForm((mockForm) => ({
							...mockForm,
							title: mockDetails.title,
							test_duration: mockDetails.test_duration,
							mock_test_dialogues: mockDetails.mock_test_dialogues,
						}));
						setPageTitle(mockDetails.title);
					} else {
						console.log("response ==> ",response.data);
					}
					setLoading(false);
				} catch (error) {
					console.log("ERROR in fetchMockDetails : ", error);
					setLoading(false);
				}
			};
			fetchMockDetails();
		}
	}, [params, history, languageId, audioAssetPath, refresh]);


	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = (fieldName, value) => {
		validate(fieldName, value);
		// console.log(value);
		setMockForm(mockForm => ({ ...mockForm, [fieldName]: value }));
	};

	const validate = (fieldName, value) => {
		let form = { ...mockForm };

		switch (fieldName) {
			case 'test_duration':
				form.errors.test_duration = (!value || value <= 0) ? 'Test duration should be grater than 0' : ''; 
				break;		
			default:
				value = value.trim();
				form.errors[fieldName] = (value === '') ? fieldName.charAt(0).toUpperCase() + fieldName.slice(1)  + ' is required' : '';
				break;
		}
		setMockForm(form);
	};

	const checkErrors = () => {
		let form = { ...mockForm }, haveError = false;
		for (const errors in form.errors) {
			if (form.errors.hasOwnProperty(errors)) {
				if (form.errors[errors]) { haveError = true; }
			}
		}
		return haveError;
	};

	/**
	 * Add and update mock test
	 */
	const saveMockTest = async () => {
		if (type === 'add') {
			const { title, test_duration, mock_test_dialogues } = mockForm;
			validate('title', title);
			validate('test_duration', test_duration);
			if (!mockForm.mock_test_dialogues.length) {
				return handleSnackToogle('Please add atleast one dialogue');
			}
			
			if (!checkErrors()) {
				createMockTest(mock_test_dialogues[0], false, { title, test_duration });
			}
		} else {
			updateMockTest();
		}
	};

	/**
	 * Create new mock test with single dialogue
	 */
	const createMockTest = async (currentDialogue, dialogue_only, mockDetails) => {
		const formData = new FormData();
		if (!dialogue_only) {
			formData.append('title', mockDetails.title);
			formData.append('test_duration', mockDetails.test_duration);
		}
		if (!currentDialogue.start_audio || !currentDialogue.end_audio) {
			return handleSnackToogle('Start and end description audios are mandatory.');
		}
		if (currentDialogue.start_audio instanceof File) {
			formData.append('start_audio', currentDialogue.start_audio, currentDialogue.start_audio.name);
		}
		if (currentDialogue.end_audio instanceof File) {
			formData.append('end_audio', currentDialogue.end_audio, currentDialogue.start_audio.name);
		}

		let data = {
			language_id: languageId,
			type: type,
			dialogue_title: currentDialogue.title,
			dialogue_description: currentDialogue.description,
			start_transcript: currentDialogue.start_transcript,
			end_transcript: currentDialogue.end_transcript,
			dialogue_only: dialogue_only,
		};
		if (!dialogue_only) {
			data = {
				...data,
				title: mockDetails.title,
				test_duration: mockDetails.test_duration,
			};
		}
		if (type === 'edit') {
			data = { ...data, mockTestId };
		}
		formData.append('data', JSON.stringify(data));

		let audioSegments = [];
		currentDialogue.mock_dialogue_segments.forEach((segment, index) => {
			formData.append(`audioFile_${index}`, segment.audioFile, segment.audioFile.name);
			if (segment.responseFile) {
				formData.append(`responseFile_${index}`, segment.responseFile, segment.responseFile.name);
			}
			audioSegments.push({
				name: segment.name,
				transcript: segment.transcript,
				audio_name: segment.audioFile.name,
				response_name: (segment.responseFile) ? segment.responseFile.name : null,
				sample_transcript: segment.sample_transcript
			});
		});
		formData.append('audio_segments', JSON.stringify(audioSegments));
		setLoading(true);
		setOpenProgressDialogue(true);
		try {
			const response = await API.post('mock_test', formData, {
				headers: {'Content-Type': 'multipart/form-data'},
				onUploadProgress: (progressEvent) => {
					const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
					setProgress(progressPercentage);
				},
			});
			setOpenProgressDialogue(false);
			setProgress(0);
			if (response.data.success) {
				// console.log("response.data ==> ",response.data);
				handleSnackToogle(response.data.message);
				setPageTitle(currentDialogue.title);
				if (type === 'add') {
					props.history.goBack();
				} else {
					setRefresh(refresh => !refresh);
				}
			} else {
				console.log("response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			setOpenProgressDialogue(false);
			console.log("ERROR in createAudioDialogue : ", error);
			let msg = 'Something went wrong. Please try again later.';
			if (error.response && error.response.data &&  error.response.data.error_message) {
				msg = error.response.data.error_message;
			} else if (error.response && error.response.data &&  error.response.data.message) {
				msg = error.response.data.message;
			} else if (error.message) {
				msg = error.message;
			}
			setLoading(false);
			handleSnackToogle(msg);
			setProgress(0);
		}
	};

	/**
	 * Update mock test details like title and time duration
	 */
	const updateMockTest = async () => {
		const { title, test_duration } = mockForm;
		validate('title', title);
		validate('test_duration', test_duration);
		// console.log(mockForm);
		if (!mockForm.mock_test_dialogues.length) {
			return handleSnackToogle('Please add atleast one dialogue');
		}
		console.log(mockTestId);
		if (!checkErrors()) {
			setLoading(true);
			const formData = new FormData();
			formData.append('title', title);
			formData.append('test_duration', test_duration);
			formData.append('languageId', languageId);

			try {
				const response = await API.put('mock_test/' + mockTestId, formData, {
				headers: {'Content-Type': 'multipart/form-data'},
				onUploadProgress: (progressEvent) => {
					const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
					setProgress(progressPercentage);
				},
			});
				if (response.data.success) {
					handleSnackToogle(response.data.message);
				} else {
					console.log("response ==> ",response.data);
					handleSnackToogle(response.data.message)
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in createAudioDialogue : ", error);
				setLoading(false);
			}
		}
	};

	const handleDialogueToggle = (dialogue, index, data) => {
		if (data) {
			let dialogues = [...mockForm.mock_test_dialogues];
			if (selectedIndex !== undefined && selectedIndex !== null) {
				dialogues[selectedIndex] = { ...dialogues[selectedIndex], ...data };
				if (type === 'edit') {
					updateDialogueDetails(dialogues[selectedIndex]);
				}
			} else {
				if (type === 'edit') {
					console.log("data", data);
					createMockTest(data, true);
				}
				dialogues.push(data);
			}
			setMockForm(mockForm => ({ ...mockForm, mock_test_dialogues: dialogues }));			
		}
		index = index !== undefined ? index : null;
		setSelectedIndex(index);
		dialogue = dialogue || {};
		setSelectedDialogue(dialogue);
		setOpenDialogueModal(openDialogueModal => !openDialogueModal);
	};

	/**
	 * Update dialogue and upload new audio segments
	 */
	const updateDialogueDetails = async (currentDialogue) => {
		// return console.log("currentDialogue : ",currentDialogue);
		const { title, description, id, start_audio, end_audio, start_transcript, end_transcript, original_start_audio, original_end_audio } = currentDialogue;
		if (!start_audio || !end_audio) { return handleSnackToogle('Start and end description audios are mandatory.'); }

		const formData = new FormData();
		if (start_audio instanceof File) { formData.append("start_audio", start_audio, start_audio.name); }
		if (end_audio instanceof File) { formData.append("end_audio", end_audio, end_audio.name); }
		let audioSegments = [], newSegments = [];
		currentDialogue.mock_dialogue_segments.forEach((segment, index) => {
			if (!segment.id) {
				formData.append(`audioFile_${index}`, segment.audioFile, segment.audioFile.name);
				if (segment.responseFile) {
					formData.append(`responseFile_${index}`, segment.responseFile, segment.responseFile.name);
				}
				newSegments.push({
					name: segment.name,
					transcript: segment.transcript,
					audio_name: segment.audioFile.name,
					response_name: (segment.responseFile) ? segment.responseFile.name : null,
					sort_index: index,
					sample_transcript: segment.sample_transcript
				});
			} else {
				audioSegments.push({
					id: segment.id,
					name: segment.name,
					transcript: segment.transcript,
					sort_index: index,
					sample_transcript: segment.sample_transcript
				});
			}
		});
		const data = {
			title,
			description,
			mockTestId,
			id,
			audioSegments,
			newSegments,
			start_transcript,
			end_transcript,
			original_start_audio,
			original_end_audio
		};
		// console.log("data : ",data);
		formData.append('data', JSON.stringify(data));
		try {
			setLoading(true);
			setOpenProgressDialogue(true);
			const response = await API.post('mock_test/dialogue', formData, {
				headers: {'Content-Type': 'multipart/form-data'},
				onUploadProgress: (progressEvent) => {
					const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
					setProgress(progressPercentage);
				},
			});			
			setOpenProgressDialogue(false);
			setProgress(0);
			if (response.data.success) {
				// console.log("response.data ==> ",response.data);
				handleSnackToogle(response.data.message);
				setRefresh(refresh => !refresh);
			} else {
				console.log("response updateDialogueDetails ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			setOpenProgressDialogue(false);
			console.log("ERROR in updateDialogueDetails : ", error.response);
			setLoading(false);
			let msg = 'Something went wrong. Please try again later.';
			if (error.response && error.response.data &&  error.response.data.error_message) {
				msg = error.response.data.error_message;
			} else if (error.response && error.response.data &&  error.response.data.message) {
				msg = error.response.data.message;
			} else if (error.message) {
				msg = error.message;
			}
			handleSnackToogle(msg);
			setProgress(0);
		}
	};

	const handleAudioUpload = async (file, segment, audioType, index) => {
		if (!file) {
			return handleSnackToogle("Please add an audio file first.");
		}
		const data = {
			audioType,
			segmentId: segment.id,
			old_audio_path: segment.audio_path,
			old_sample_response: segment.sample_response,
		};
		const formData = new FormData();
		formData.append('file', file, file.name);
		formData.append('data', JSON.stringify(data));
		try {
			setOpenProgressDialogue(true);
			setLoading(true);
			const response = await API.put('mock_test/dialogue', formData, {
				headers: {'Content-Type': 'multipart/form-data'},
				onUploadProgress: (progressEvent) => {
					const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
					setProgress(progressPercentage);
				},
			});
			setLoading(false);
			setOpenProgressDialogue(false);
			setProgress(0);
			if (response.data.success) {
				setRefresh(refresh => !refresh);
				handleSnackToogle(response.data.message);

				let selected = { ...selectedDialogue };
				const name = (audioType === 'audio') ? 'audio_path' : 'sample_response';
				selected.mock_dialogue_segments[index] = {
					...selected.mock_dialogue_segments[index],
					[name]: response.data.data.filename,
				};
				setSelectedDialogue(selected);			
			} else {
				console.log("ERROR response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
		} catch (error) {
			setOpenProgressDialogue(false);
			console.log("ERROR in uploadAudio : ", error.response);
			setLoading(false);
			let msg = 'Something went wrong. Please try again later.';
			if (error.response && error.response.data &&  error.response.data.error_message) {
				msg = error.response.data.error_message;
			} else if (error.response && error.response.data &&  error.response.data.message) {
				msg = error.response.data.message;
			} else if (error.message) {
				msg = error.message;
			}
			handleSnackToogle(msg);
			setProgress(0);
		}
	};

	const deleteDialogue = (event, index) => {
		event.stopPropagation()
		let dialogues = [...mockForm.mock_test_dialogues];
		if (dialogues.length === 1 && type === 'edit') {
			return handleSnackToogle('Atleast one dialogue is required');
		}
		if (type === 'edit') {
			handleDialogueDelete(dialogues[index]);
		}
		dialogues.splice(index, 1);
		setMockForm(mockForm => ({ ...mockForm, mock_test_dialogues: dialogues }));
	};

	const handleDialogueDelete = async (dialogue) => {
		try {
			setLoading(true);
			const response = await API.delete('delete_mock_dialogue/' + dialogue.id);
			if (response.data.success) {
				// console.log("response asdsadsadsa ==> ",response.data);
				handleSnackToogle(response.data.message)
				setRefresh(refresh => (!refresh));
			} else {
				console.log("response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in handleDialogueDelete : ", error);
			setLoading(false);
		}
	};

	/**
	 * Delete audio segment
	 * 
	 * @param {*} audioSegment 
	 */
	const handleAudioSegmentDelete = async (audioSegment) => {
		try {
			setLoading(true);
			const response = await API.delete('delete_mock_tests/' + audioSegment.id);
			setLoading(false);
			if (response.data.success) {
				// console.log("response.data ==> ",response.data);
				handleSnackToogle(response.data.message);
				setRefresh(refresh => !refresh);
			} else {
				console.log("response deleteAudioSegment ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
		} catch (error) {
			setLoading(false);			
			console.log("ERROR in deleteAudioSegment : ", error);
		}
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<IconButton size="small" aria-label="go-back" onClick={() => props.history.goBack() }>
							<ArrowBackIcon />
						</IconButton>
						<QuestionAnswerIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">{pageTitle}</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={saveMockTest} disabled={loading || checkErrors()}>Save Mock Test</Button>
				</div>
			</div>
			<Paper className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<form className={classes.form}>
					<TextField
						className={classes.textField}
						fullWidth
						label="Title"
						name="title"
						onChange={(event) => handleChange('title', event.target.value)}
						type="text"
						value={mockForm.title}
						variant="outlined"
						required
						error={mockForm.errors.title ? true : false}
						helperText={mockForm.errors.title}
						inputProps={{ maxLength: 255 }}
					/>
					<TextField
						className={classes.textField}
						fullWidth
						label="Test Duration (in minutes)"
						name="test_duration"
						onChange={(event) => handleChange('test_duration', event.target.value)}
						type="number"
						value={mockForm.test_duration}
						variant="outlined"
						required
						error={mockForm.errors.test_duration ? true : false}
						helperText={mockForm.errors.test_duration}
					/>

					<div className={classes.dialogueTableContainer}>
						<div className={classes.dialogueTableHeaderConatiner}>
							<div className={classes.dialogueTableHeader}>
								<SpeakerNotesIcon color="disabled" />
								<Typography className={classes.dialogueTableTitle} variant="h4" color="textSecondary">Dialogues</Typography>
							</div>
							<Button
								startIcon={<AddIcon />}
								color="primary"
								variant="contained"
								onClick={() => handleDialogueToggle()}
								disabled={loading || (type === 'add' && mockForm.mock_test_dialogues.length ? true : false)}
							>
								Add Dialogue
							</Button>
						</div>
						{mockForm.mock_test_dialogues.length ? (
							<TableContainer component={Paper}>
								<Table className={classes.table} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Diaogue Title</TableCell>
											<TableCell>No. of segments</TableCell>
											<TableCell>Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{mockForm.mock_test_dialogues.map((dialogue, index) => (
										<TableRow
											key={index}
											hover
											onClick={() => handleDialogueToggle(dialogue, index)}
											className={classes.rowHover}
										>
											<TableCell component="th" scope="row">
												{dialogue.title}
											</TableCell>
											<TableCell>{dialogue.mock_dialogue_segments ? dialogue.mock_dialogue_segments.length : 0}</TableCell>
											<TableCell>
												<IconButton size="small" onClick={(event) => deleteDialogue(event, index) }>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
									</TableBody>
								</Table>
							</TableContainer>
					) : (
						<Typography color="textSecondary" variant="h5" className={classes.noDialogues}>
							No dialgoues added yet
						</Typography>
					)}
					</div>
					{type === 'add' && mockForm.mock_test_dialogues.length ? (
						<Typography color="textSecondary" className={classes.infoMessage} variant="h5">You can add only one dialgoue at the time of creating a new mock test. Please fell free to add more dialogues while editing this mock test.</Typography>
					) : null}
				</form>
			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={2000}
			/>
			<Dialogue
				open={openDialogueModal}
				onClose={handleDialogueToggle}
				type={type}
				handleSnackToogle={handleSnackToogle}
				dialogueData={selectedDialogue}
				uploadAudio={handleAudioUpload}
				deleteAudioSegment={handleAudioSegmentDelete}
			/>

			<UploadingProgress
				open={openProgressDialogue}
				// onClose={progressOpen}
				progressPercentage={progress}
			/>
		</div>
	);
};

export default MockTestDetails;