import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';

import { SERVER_PATH, PROFILE_IMAGE_PATH } from '../../../../../../config';
import { handleProfileChange } from '../../../../../../services/handlerService';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: 'fit-content'
	},
	avatar: {
		width: 60,
		height: 60
	},
	name: {
		marginTop: theme.spacing(1)
	}
}));

const Profile = props => {
	const { className, ...rest } = props;
	const classes = useStyles();
	const userDataLocal = (localStorage.getItem('user_data') !== null && localStorage.getItem('user_data') !== undefined) ? JSON.parse(localStorage.getItem('user_data')) : {};
	const [userData, setUserData] = useState(userDataLocal);
	const imageAssetPath = SERVER_PATH + PROFILE_IMAGE_PATH;
	// const imageSource = imageAssetPath + userData.profile_image;
	const [imageSource, setImageSource] = useState(imageAssetPath + userData.profile_image);

	useEffect(() => {
		handleProfileChange.receiveData().subscribe(data => {
			if (data) {
				if (data.path) { setImageSource(data.path); }
				if (data.first_name)  {
					let full_name = data.first_name + " " + data.last_name;
					setUserData(userData => ({ ...userData, full_name }));
				}
			}
		});
	}, []);
	
	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Avatar
				alt={userData.full_name}
				className={classes.avatar}
				component={RouterLink}
				src={imageSource}
				to="/profile"
			/>
				<Typography className={classes.name} variant="h4">
					{userData.full_name}
				</Typography>
			<Typography variant="body2">{userData.email}</Typography>
		</div>
	);
};

Profile.propTypes = {
	className: PropTypes.string
};

export default Profile;
