import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import validate from 'validate.js';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useStyles from './UserDetailStyle';
import useCommonStyles from '../../common/style';
import API from '../../axios/axiosApi';
import { SERVER_PATH, PROFILE_IMAGE_PATH } from '../../config';
import { handleProfileChange } from '../../services/handlerService';
import { formatDate, formatUnderscore } from '../../utils/formatter';

let schema = {
	first_name: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
			maximum: 64
		}
	},
	mobile: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	city: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	state: {
		presence: { allowEmpty: false, message: 'is required' },
	},	
	language_id: {
		presence: { allowEmpty: false, message: '^Language is required' },
	},
};


const UserDetails = (props) => {
	const { match: { params }, history } = props;
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [languages, setLanguages] = useState([]);
	const [showPassword, setShowPassword] = useState(false);

	const type = (params.id && params.id === 'add') ? 'add' : 'edit';
	const [pageTitle, setPageTitle] = useState((type === 'add') ? 'Add User' : 'Update User');

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {}
	});
	const [imageSource, setImageSource] = useState('');
	const [imageFile, setImageFile] = useState(null);

	const imageAssetPath = SERVER_PATH + PROFILE_IMAGE_PATH;

	useEffect(() => {
		const fetchLanguages = async () => {
			try {
				const response = await API.get('languages');
				if (response.data.success && response.data.data.languages) {
					setLanguages(response.data.data.languages)
				} else {
					console.log("response fetchLanguages ==> ",response.data);
				}
			} catch (error) {
				console.log("ERROR in fetchLanguages : ", error);
			}
		};
		fetchLanguages();
		if (params.id && params.id !== 'add') {
			const fetchUserDetails = async () => {
				setLoading(true);
				try {
					const response = await API.get('users/' + params.id);
					if (response.data.success) {
						if (!response.data.data.user_details) {
							handleSnackToogle("Record for this ID don't exists!");
							return history.goBack();
						}
						const userDetails = (response.data.data.user_details) ? response.data.data.user_details : {};

						setFormState(formState => ({
							...formState,
							values: {
								first_name 		: userDetails.first_name,
								last_name 		: userDetails.last_name,
								email 			: userDetails.email,
								mobile 			: userDetails.mobile,
								city 			: userDetails.city,
								state 			: userDetails.state,
								language_id		: userDetails.language_id,
								profile_image	: userDetails.profile_image,
								user_paid_plans	: userDetails.user_paid_plans,
								exam_date		: userDetails.exam_date,
							}
						}));
						setImageSource(imageAssetPath + userDetails.profile_image);
						const full_name = (userDetails.first_name + " " + userDetails.last_name).trim();
						setPageTitle(full_name);
					} else {
						handleSnackToogle(response.data.message);
					}
					setLoading(false);
				} catch (error) {
					console.log("ERROR in fetchUserDetails : ", error);
					setLoading(false);
				}
			};
			fetchUserDetails();
		}
	}, [params, imageAssetPath, history]);

	useEffect(() => {
		if (type === 'add') {
			schema = { 
				...schema,
				password: {
					presence: { allowEmpty: false, message: 'is required' },
					length: {
						maximum: 128,
						minimum: 6
					}
				}
			};
		}
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		}));
	}, [formState.values, type]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
		...formState,
		values: {
			...formState.values,
			[event.target.name]:
			event.target.type === 'checkbox'
				? event.target.checked
				: event.target.value
		},
		touched: {
			...formState.touched,
			[event.target.name]: true
		}
		}));
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	

	/**
	 * Save user
	 * 
	 * @param {*} event 
	 */
	const saveUser = async (event) => {
		event.preventDefault();
		// console.log(formState.values);
		const formData = new FormData();
		formData.append('type', type);
		if (imageFile) {
			formData.append('file', imageFile, imageFile.name);
			formData.append('size', imageFile.size);
		}
		for (const key in formState.values) {
			if (formState.values.hasOwnProperty(key)) {
				formData.append(key, formState.values[key]);
			}
		}
		try {
			setLoading(true);
			if (type === 'edit') {
				formData.append('id', params.id);
			}
			const response = await API.post('save_user', formData, { headers: {'Content-Type': 'multipart/form-data'} });
			if (response.data.success) {
				// console.log("response.data ==> ",response.data);
				handleSnackToogle(response.data.message);
				// setPageTitle(title);
				if (type === 'add') { props.history.goBack(); }
			} else {
				console.log("response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in saveUser : ", error);
			setLoading(false);
		}
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	/**
	 * Handle file change
	 * 
	 * @param {*} e 
	 */
	const handleFileChange = (e) => {
		const maxUploadSize = 20; // In MB
		const byteToMb = 1000000; //  1MB = 1000000 Bytes = 1000 * 1000 (in decimal format)
		const file = e.target.files[0];

		// setImageSource('');
		if ( !file ) { return; }
		const fileSize = parseInt(file.size);

		if(fileSize > maxUploadSize * byteToMb) { // Number of MegaBytes;
			return handleSnackToogle("Image Size Shouldn't Exceed " + maxUploadSize + "MB");
		}
		
		setImageFile(file);
		const reader = new FileReader();
		// reader.readAsBinaryString(file);
		reader.readAsDataURL(file);
		reader.onload = () => {
			setImageSource(reader.result)
			// console.log(`data:${file.type};base64,${btoa(reader.result)}`);
			if (type === 'edit') {
				uploadProfileImage(file);
			}
		};
		reader.onerror = function () {
			handleSnackToogle("Error in loading audio");
		};
	};
	
	/**
	 * Upload profile image
	 * 
	 * @param {*} file 
	 */
	const uploadProfileImage = async (file) => {
		if (!file) {
			return handleSnackToogle("Please add an audio file first.");
		}
		const formData = new FormData();
		formData.append('file', file, file.name);
		formData.append('old_image_path', formState.values.profile_image);
		setLoading(true);
		try {
			const response = await API.post('profile/image', formData, { headers: {'Content-Type': 'multipart/form-data'} });
			if (response.data.success) {
				setFormState(formState => ({
					...formState,
					values: {
						...formState.values,
						profile_image : response.data.data.filename,
					}
				}));
				setImageSource(imageAssetPath + response.data.data.filename);
				handleSnackToogle(response.data.message);
				handleProfileChange.sendData({ path: imageAssetPath + response.data.data.filename });
				let userData = (localStorage.getItem('user_data') !== null && localStorage.getItem('user_data') !== undefined) ? JSON.parse(localStorage.getItem('user_data')) : {};
				userData = { ...userData, profile_image: response.data.data.filename }
				localStorage.setItem('user_data', JSON.stringify(userData));
			} else {
				console.log("response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in saveDialogue : ", error);
			setLoading(false);
		}
	};

	/**
	 * Toogle password visiblity
	 */
	const handlePasswordVisibility = () => {
		setShowPassword(showPassword => !showPassword);
	};

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<IconButton size="small" aria-label="go-back" onClick={() => props.history.goBack() }>
							<ArrowBackIcon />
						</IconButton>
						{type === 'add' ? (
							<div>
								<input
									accept="image/*"
									className={classes.input}
									id="button-file"
									type="file"
									onChange={handleFileChange}
								/>
								<Badge
									overlap="circle"
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
									badgeContent={
										<label htmlFor="button-file">
											<Fab size="small" color="primary" component="span">
												<EditIcon fontSize="small" />
											</Fab>
										</label>
									}
								>
									<Avatar alt={pageTitle} src={imageSource} className={classes.avatar} />
								</Badge>
							</div>
						) : (
							<Avatar alt={pageTitle} src={imageSource} className={classes.avatar} />
						)}						
						<Typography variant="h4" className={classes.profileName}>{pageTitle}</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={saveUser} disabled={!formState.isValid}>Save</Button>
				</div>
			</div>
			<Paper className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<form className={classes.form}>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								error={hasError('first_name')}
								fullWidth
								helperText={
									hasError('first_name') ? formState.errors.first_name[0] : null
								}
								label="First Name"
								name="first_name"
								onChange={handleChange}
								type="text"
								value={formState.values.first_name || ''}
								variant="outlined"
								required
							/>
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								fullWidth
								label="Last Name"
								name="last_name"
								onChange={handleChange}
								type="text"
								value={formState.values.last_name || ''}
								variant="outlined"
							/>
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								error={hasError('email')}
								fullWidth
								helperText={
									hasError('email') ? formState.errors.email[0] : null
								}
								label="Email address"
								autoComplete="off"
								name="email"
								onChange={handleChange}
								type="text"
								value={formState.values.email || ''}
								variant="outlined"
								required
								disabled={type === 'edit'}
							/>
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								error={hasError('mobile')}
								fullWidth
								helperText={
									hasError('mobile') ? formState.errors.mobile[0] : null
								}
								label="Mobile"
								name="mobile"
								onChange={handleChange}
								type="text"
								value={formState.values.mobile || ''}
								variant="outlined"
								required
							/>
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								error={hasError('city')}
								fullWidth
								helperText={
									hasError('city') ? formState.errors.city[0] : null
								}
								label="City"
								name="city"
								onChange={handleChange}
								type="text"
								value={formState.values.city || ''}
								variant="outlined"
								required
							/>
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								error={hasError('state')}
								fullWidth
								helperText={
									hasError('state') ? formState.errors.state[0] : null
								}
								label="State"
								name="state"
								onChange={handleChange}
								type="text"
								value={formState.values.state || ''}
								variant="outlined"
								required
							/>
						</Grid>
						{ type === 'add' ? (
							<Grid
								item
								md={6}
								xs={12}
							>
								<TextField
									className={classes.textField}
									error={hasError('password')}
									fullWidth
									helperText={
										hasError('password') ? formState.errors.password[0] : null
									}
									label="Password"
									name="password"
									onChange={handleChange}
									type={showPassword ? "text" : "password" }
									value={formState.values.password || ''}
									variant="outlined"
									autoComplete="off"
									required
									InputProps={{
										endAdornment: <InputAdornment position="end"><IconButton
											aria-label="toggle password visibility"
											edge="end"
											onClick={handlePasswordVisibility}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton></InputAdornment>,
									}}
								/>
							</Grid>
						) : null }
						<Grid
							item
							md={6}
							xs={12}
						>
							<FormControl
								required
								className={classes.textField}
								fullWidth
								variant="outlined"
								error={hasError('language_id')}
							>
								<InputLabel id="language-label">Language</InputLabel>
								<Select
									labelId="language-label"
									id="language-outlined"
									name="language_id"
									fullWidth
									value={formState.values.language_id || ''}
									onChange={handleChange}
									labelWidth={72}
								>
								{
									languages.map(category => (
										<MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
									))
								}
								</Select>
								<FormHelperText>{hasError('language_id') ? formState.errors.language_id[0] : null}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								fullWidth
								label="Exam Date"
								autoComplete="off"
								name="exam_date"
								onChange={handleChange}
								type="text"
								value={formState.values.exam_date || ''}
								variant="outlined"
								disabled={true}
							/>
						</Grid>
					</Grid>
				{/* <Button className={classes.changePwd} color="primary" onClick={handleClickOpen}>Change Password</Button> */}
				</form>
			</Paper>

			<Paper className={classes.userPlanInfo}>
				{(formState.values.user_paid_plans && formState.values.user_paid_plans.name) ? (
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							md={6}
							xs={12}
						>
							Plan Name: {formState.values.user_paid_plans.name}
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							Price: ${formState.values.user_paid_plans.price}
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							Validity: {formState.values.user_paid_plans.validity}
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							Source: {formatUnderscore(formState.values.user_paid_plans.source)}
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							Start Date: {formatDate(formState.values.user_paid_plans.start_date)}
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							End Date: {formatDate(formState.values.user_paid_plans.end_date)}
						</Grid>
					</Grid>
				) : <b>No associated paid plan</b>}
			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>
		</div>
	);
};

export default UserDetails;