import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	pageHeader: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	row: {
		height: '42px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		}
	},
	spacer: {
		flexGrow: 1
	},
	pageTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2)
		}
	},
	titleIcon: {
		marginRight: theme.spacing(1),
	},
	content: {
		width: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	form: {
		width: '100%',
	},
	textField: {
		marginTop: theme.spacing(3)
	},
	input: {
		display: 'none',
	},
	images: {
		marginTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
	uploadButton: {
		padding: '11px 20px',
		borderRadius: 30,
		marginRight: theme.spacing(3)
	},
	audioComponent: {
		outline: 'none'
	},
	responses: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	title: {
		padding: theme.spacing(2),
	},
	actions: {
		padding: theme.spacing(2),
		justifyContent: 'center'
	},
	rowHover: {
		cursor: "pointer"
	},
	cardContentStyle: {
		padding: theme.spacing(4),
	},
	imgsContainer: {
		paddingTop: theme.spacing(),
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	},
	imgBox: {
		height: theme.spacing(15),
		width: theme.spacing(15),
		border: '1px solid gray',
		margin: theme.spacing(2),
		position: 'relative',
	},
	img: {
		height: theme.spacing(15),
		width: theme.spacing(15),
	},
	deleteIcon: {
		position: 'absolute',
		top: theme.spacing(-3),
		right: theme.spacing(-3),
		color: theme.palette.warning.dark,
	},
	notFound: {
		width: '100%',
		textAlign: 'center',
	},
	underline:{
		//color:'#3f51b5',
		textDecorationLine: 'underline'
	}
}));