import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import useCommonStyles from '../common/style';
// import { formatCurrency } from '../utils/formatter';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(3),
		justifyContent: 'space-around',
		alignItems: 'center'
	},
	percent: {
		paddingTop: theme.spacing(2),
		textAlign: 'center',
	},
}));

const UploadingProgress = (props) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const { open, progressPercentage } = props;


	return (
		<Dialog
			// onClose={() => onClose()}
			open={open}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			TransitionComponent={Transition}
			classes={{ paper: commonClasses.dialogueFullwidth }}
		>
			<DialogTitle id="simple-dialog-title">Uploading Dialogue</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				{progressPercentage < 100 ? (
					<div>
						<CircularProgress
							variant="static"
							value={progressPercentage}
							size={80}
						/>
						<Typography variant="h5" className={classes.percent}>
							{Math.ceil(progressPercentage)} %
						</Typography>
					</div>
				) : 'Processing...'}
				
			</DialogContent>
		</Dialog>
	);
};

UploadingProgress.propTypes = {
	open: PropTypes.bool.isRequired,
	// onClose: PropTypes.func.isRequired,
	progressPercentage: PropTypes.number.isRequired,
};

export default UploadingProgress;