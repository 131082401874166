import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import validate from 'validate.js';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
// import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';

import useStyles from './ProfileStyle';
import useCommonStyles from '../../common/style';
import API from '../../axios/axiosApi';
import ChangePasswordDialog from './ChangePasswordDialog';
import { SERVER_PATH, PROFILE_IMAGE_PATH } from '../../config';
import { handleProfileChange } from '../../services/handlerService';

const schema = {
	first_name: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
			maximum: 64
		}
	}
};


const Profile = (props) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const imageAssetPath = SERVER_PATH + PROFILE_IMAGE_PATH;

	const userData = (localStorage.getItem('user_data') !== null && localStorage.getItem('user_data') !== undefined) ? JSON.parse(localStorage.getItem('user_data')) : {};
	const [pageTitle, setPageTitle] = useState(userData.full_name);

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {}
	});
	const [openDialog, setOpenDialog] = React.useState(false);
	const [imageSource, setImageSource] = useState('');

	useEffect(() => {
		const fetchProfile = async () => {
			setLoading(true);
			try {
				const response = await API.get('profile');
				if (response.data.success) {
					const profileDetails = (response.data.data.profile_details) ? response.data.data.profile_details : {};
					setFormState(formState => ({
						...formState,
						values: {
							first_name 		: profileDetails.first_name,
							last_name 		: profileDetails.last_name,
							email 			: profileDetails.email,
							profile_image	: profileDetails.profile_image,
						}
					}));
					setImageSource(imageAssetPath + profileDetails.profile_image);
					setPageTitle(profileDetails.full_name);
				} else {
					handleSnackToogle(response.data.message);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchVideoDetails : ", error);
				setLoading(false);
			}
		};
		fetchProfile();
	}, [imageAssetPath]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		}));
	}, [formState.values]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
		...formState,
		values: {
			...formState.values,
			[event.target.name]:
			event.target.type === 'checkbox'
				? event.target.checked
				: event.target.value
		},
		touched: {
			...formState.touched,
			[event.target.name]: true
		}
		}));
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	/**
	 * Handle Login request
	 * 
	 * @param {*} event 
	 */
	const saveProfile = async (event) => {
		event.preventDefault();
		// console.log(formState.values);
		try {
			setLoading(true);
			const response = await API.post('profile', formState.values);
			handleSnackToogle(response.data.message);
			if (response.data.success) {
				let userData = (localStorage.getItem('user_data') !== null && localStorage.getItem('user_data') !== undefined) ? JSON.parse(localStorage.getItem('user_data')) : {};
				formState.values.first_name = formState.values.first_name ? formState.values.first_name.trim() : formState.values.first_name;
				formState.values.last_name = formState.values.last_name ? formState.values.last_name.trim() : '';
				let fullName = formState.values.first_name + ' ' + formState.values.last_name;
				fullName = fullName.trim();
				setPageTitle(fullName);
				userData = { ...userData, full_name: fullName, email: formState.values.email }
				localStorage.setItem('user_data', JSON.stringify(userData));
				handleProfileChange.sendData(formState.values);
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in handleSubmit : ", error);
			setLoading(false);
		}
	};

	const handleClickOpen = () => {
		setOpenDialog(true);
	};
	const handleClose = (data) => {
		setOpenDialog(false);
		if (data) {
			updatePassword(data);
		}
	};
	
	/**
	 * Update account password
	 * 
	 * @param {*} data 
	 */
	const updatePassword = async (data) => {
		try {
			const response = await API.put('profile', { password: data.password });
			handleSnackToogle(response.data.message);
		} catch (error) {
			console.log("ERROR in handleSubmit : ", error);
		}
	}

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	/**
	 * Handle file change
	 * 
	 * @param {*} e 
	 */
	const handleFileChange = (e) => {
		const maxUploadSize = 20; // In MB
		const byteToMb = 1000000; //  1MB = 1000000 Bytes = 1000 * 1000 (in decimal format)

		const file = e.target.files[0];

		if ( !file ) { return; }
		const fileSize = parseInt(file.size);

		if(fileSize > maxUploadSize * byteToMb) { // Number of MegaBytes;
			return handleSnackToogle("Image Size Shouldn't Exceed " + maxUploadSize + "MB");
		}
		uploadProfileImage(file);
		
       /*  const reader = new FileReader();
        // reader.readAsBinaryString(file);
        reader.readAsDataURL(file);
        reader.onload = () => {
			setAudioSource(reader.result)
			// console.log(`data:${file.type};base64,${btoa(reader.result)}`);
			if (type === 'edit') {
				uploadAudio(file);
			}
        };
        reader.onerror = function () {
            handleSnackToogle("Error in loading audio");
		}; */
	}
	
	/**
	 * Upload profile image
	 * 
	 * @param {*} file 
	 */
	const uploadProfileImage = async (file) => {
		if (!file) {
			return handleSnackToogle("Please add an audio file first.");
		}
		const formData = new FormData();
		formData.append('file', file, file.name);
		formData.append('old_image_path', formState.values.profile_image);
		setLoading(true);
		try {
			const response = await API.post('profile/image', formData, { headers: {'Content-Type': 'multipart/form-data'} });
			if (response.data.success) {
				setFormState(formState => ({
					...formState,
					values: {
						...formState.values,
						profile_image : response.data.data.filename,
					}
				}));
				setImageSource(imageAssetPath + response.data.data.filename);
				handleSnackToogle(response.data.message);
				handleProfileChange.sendData({ path: imageAssetPath + response.data.data.filename, ...formState.values });
				let userData = (localStorage.getItem('user_data') !== null && localStorage.getItem('user_data') !== undefined) ? JSON.parse(localStorage.getItem('user_data')) : {};
				userData = { ...userData, profile_image: response.data.data.filename }
				localStorage.setItem('user_data', JSON.stringify(userData));
			} else {
				console.log("response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in saveDialogue : ", error);
			setLoading(false);
		}
	};


	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<input
							accept="image/*"
							className={classes.input}
							id="button-file"
							type="file"
							onChange={handleFileChange}
						/>
						<Badge
							overlap="circle"
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							badgeContent={
								<label htmlFor="button-file">
									<Fab size="small" color="primary" component="span">
										<EditIcon fontSize="small" />
									</Fab>
								</label>
							}
						>
							<Avatar alt={pageTitle} src={imageSource} className={classes.avatar} />
						</Badge>
						<Typography variant="h4" className={classes.profileName}>{pageTitle}</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={saveProfile} disabled={!formState.isValid}>Save Profile</Button>
				</div>
			</div>
			<Paper className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<form className={classes.form}>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								error={hasError('first_name')}
								fullWidth
								helperText={
									hasError('first_name') ? formState.errors.first_name[0] : null
								}
								label="First Name"
								name="first_name"
								onChange={handleChange}
								type="text"
								value={formState.values.first_name || ''}
								variant="outlined"
								required
							/>
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								fullWidth
								label="Last Name"
								name="last_name"
								onChange={handleChange}
								type="text"
								value={formState.values.last_name || ''}
								variant="outlined"
							/>
						</Grid>
						<Grid
							item
							md={6}
							xs={12}
						>
							<TextField
								className={classes.textField}
								error={hasError('email')}
								fullWidth
								helperText={
									hasError('email') ? formState.errors.email[0] : null
								}
								label="Email address"
								name="email"
								onChange={handleChange}
								type="text"
								value={formState.values.email || ''}
								variant="outlined"
								required
							/>
						</Grid>
					</Grid>
				<Button className={classes.changePwd} color="primary" onClick={handleClickOpen}>Change Password</Button>
				</form>
			</Paper>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>

			<ChangePasswordDialog 
				open={openDialog}
				onClose={handleClose}
			/>
		</div>
	);
};

export default Profile;