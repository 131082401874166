import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
// import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useStyles from './AccessCodeDetailStyle';
import useCommonStyles from '../../../common/style';
import API from '../../../axios/axiosApi';


const AccessCodeDetails = (props) => {
	const { match: { params }, history } = props;

	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [codeForm, setCodeForm] = useState({
		plan_id: '',
		user_id: '',
		// is_active: true,
		errors: {
			plan_id: '',
			user_id: '',
		}
	});
	const [plans, setPlans] = useState([]);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [email, setEmail] = useState('');
	const [searchButton, setSearchButton] = useState(true);
	const [user, setUser] = useState({ id: null });
	const [userNotFound, setUserNotFound] = useState(false);

	const type = (params.id && params.id === 'add') ? 'add' : 'edit';
	const pageTitle = (type === 'add') ? 'Generate Code' : 'Update Code';

	useEffect(() => {
		
		const fetchAllPaidPlans = async () => {
			setLoading(true);
			try {
				const response = await API.get('plans');
				if (response.data.success) {
					setPlans(response.data.data.plans);
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchAllPaidPlans : ", error);
				setLoading(false);
			}
		};
		fetchAllPaidPlans();
	}, [params, history]);


	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = (fieldName, value) => {
		// if (fieldName === 'title' || fieldName === 'link') {
			// }
		validate(fieldName, value);
		setCodeForm(video => ({ ...video, [fieldName]: value }));
	};

	const handleInputChange = event => {
		setEmail(event.target.value);
		setSearchButton(event.target.value !== "" ? false : true);
	}

	const validate = (fieldName, value) => {
		let form = { ...codeForm };
		let showName = (fieldName === 'user_id') ? 'User' : 'Plan';

		form.errors[fieldName] = (!value) ? showName  + ' is required' : '';
		setCodeForm(form);
	};

	const checkErrors = () => {
		let form = { ...codeForm }, haveError = false;
		for (const errors in form.errors) {
			if (form.errors.hasOwnProperty(errors)) {
				if (form.errors[errors]) { haveError = true; }
			}
		}
		return haveError;
	};

	/**
	 * Add and update video vimeo link
	 */
	const generateCode = async () => {
		const { plan_id, user_id } = codeForm;
		validate('user_id', user_id);
		validate('plan_id', plan_id);

		if (!checkErrors()) {
			const selectedPlan = plans.filter(singlePlan => singlePlan.id === plan_id).shift();
			// const selectedUser = users.filter(singleUser => singleUser.id === parseInt(user_id)).shift();

			const postData = { selectedPlan, selectedUser: user };
			setLoading(true);
			try {
				const response = await API.post('save_access_code', postData);
				if (response.data.success) {
					// console.log("response.data ==> ",response.data);
					handleSnackToogle(response.data.message);
					if (type === 'add') { props.history.goBack(); }
				} else {
					console.log("response ==> ",response.data);
					handleSnackToogle(response.data.message)
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in saveLanguage : ", error);
				setLoading(false);
			}
		}
	};


	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};


	const searchUserByEmail = async () => {
		setUserNotFound(false);
		if (email !== "") {

			setLoading(true);
			const response = await API.post(`user/email`, { email });

			setLoading(false);
			if (response.data.success) {				
				
				setUser(response.data.user);
			} else {
				setUser({ id: null });
				setUserNotFound(true);
			}
		}
	};


	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<IconButton size="small" aria-label="go-back" onClick={() => props.history.goBack() }>
							<ArrowBackIcon />
						</IconButton>
						<VpnKeyIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">{pageTitle}</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<Button color="primary" variant="contained" onClick={generateCode} disabled={loading || checkErrors()}>Generate</Button>
				</div>
			</div>
			<Paper className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				<form className={classes.form}>
					<FormControl
						className={classes.textField}
						fullWidth
						variant="outlined"
						error={codeForm.errors.plan_id ? true : false}
						required
					>
						<InputLabel id="plan-label">Plan</InputLabel>
						<Select
							labelId="plan-label"
							id="plan-id-outlined"
							name="plan_id"
							fullWidth
							value={codeForm.plan_id}
							onChange={(event) => handleChange('plan_id', event.target.value)}
							labelWidth={40}
						>
							{plans.map(paidPlan => (
								<MenuItem key={paidPlan.id} value={paidPlan.id}>
									{paidPlan.name} (${paidPlan.price})
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{codeForm.errors.plan_id} </FormHelperText>
					</FormControl>
					<FormControl
						className={classes.textField}
						fullWidth
						variant="outlined"
						error={codeForm.errors.user_id ? true : false}
						required
					>
						<TextField
							className={classes.textField}
							
							label="Search Email"
							name="email"
							onChange={handleInputChange}
							type="text"
							value={email}
							variant="outlined"
							required
						/>

						<FormHelperText>{codeForm.errors.user_id}</FormHelperText>
					</FormControl>
					<Button color="primary" variant="contained" onClick={searchUserByEmail} disabled={searchButton} >Search</Button>

					{user.id !== null &&
						<FormControl
							className={classes.textField}
							fullWidth
							variant="outlined"
							error={codeForm.errors.user_id ? true : false}
							required
						>
							<RadioGroup
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="user_id"
								value={parseInt(codeForm.user_id)}
								onChange={(event) => handleChange('user_id', event.target.value)}
							>
								<FormControlLabel value={user.id} control={<Radio />} label={`${user.first_name} ${user.last_name} - (${user.email})` } />
							</RadioGroup>

							<FormHelperText>{codeForm.errors.user_id}</FormHelperText>
						</FormControl>
					}

					{userNotFound &&
						<FormControl
							className={classes.textField}
							fullWidth
							variant="outlined"
							error={codeForm.errors.user_id ? true : false}
							required
						>
							<FormHelperText><b>User not Found.</b></FormHelperText>
						</FormControl>
					}
				</form>
			</Paper>

		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={snack.open}
			onClose={() => handleSnackToogle()}
			message={snack.message}
			autoHideDuration={3000}
		/>
		</div>
	);
};

export default AccessCodeDetails;