import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import validate from 'validate.js';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import useStyles from './MobileSettingDetailStyle';
import useCommonStyles from '../../common/style';

const schema = {
	name: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	label: {
		presence: { allowEmpty: false, message: 'is required' },
	},
    value: {
        presence: { allowEmpty: false, message: 'is required' },
    }
};

const MobileSettingDetails = (props) => {
    const classes = useStyles();
	const commonClasses = useCommonStyles();
    const { open, onClose, dialogData } = props;
    const [formState, setFormState] = useState({
		isValid: false,
		values: {
            name: (dialogData && dialogData.name) ? dialogData.name : '',
            label: (dialogData && dialogData.label) ? dialogData.label : '',
            value: (dialogData && ((dialogData.value === false) || (dialogData.value === true))) ? dialogData.value : '',
        },
		touched: {},
		errors: {}
    });
    
    useEffect(() => {
        setFormState(formState => ({
            ...formState,
            values: {
                name: (dialogData && dialogData.name) ? dialogData.name : '',
                label: (dialogData && dialogData.label) ? dialogData.label : '',
                value: (dialogData && ((dialogData.value === false) || (dialogData.value === true))) ? dialogData.value : '',
            },
            touched: {}
        }));
	}, [open, dialogData]);

	useEffect(() => {
		const errors = validate(formState.values, schema);
		setFormState(formState => ({
		...formState,
		isValid: errors ? false : true,
		errors: errors || {}
		}));
	}, [formState.values]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
		...formState,
		values: {
			...formState.values,
			[event.target.name]:
			event.target.type === 'checkbox'
				? event.target.checked
				: event.target.value
		},
		touched: {
			...formState.touched,
			[event.target.name]: true
		}
		}));
    };

    /**
     * Handle select change
     * 
     * @param {*} event 
     */
    const handleSelectChange = (fieldName, value) => {
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [fieldName]:value,
            }
        }));
    };
    
    const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

    const handleSave = () => {
        onClose(formState.values);  
    };

	return (
        <Dialog classes={{ paper: commonClasses.dialogueFullwidth }} onClose={() => onClose()} aria-labelledby="customized-dialog-title" open={open}>
            <AppBar position="static" className={classes.dialogHeader} elevation={2}>
                <Typography variant="h5" color="inherit" className={classes.dialogTitle}>
                    {dialogData.type} Application Setting
                </Typography>
                <IconButton aria-label="delete" onClick={() => onClose()} color="inherit">
                    <CloseIcon />
                </IconButton>
            </AppBar>
            <DialogContent className={classes.dialogContent}>
                <form className={classes.form} onSubmit={handleSave}>
                    <TextField
                        className={classes.inputField}
                        error={hasError('name')}
                        fullWidth
                        helperText={
                            hasError('name') ? formState.errors.name[0] : null
                        }
                        label="Name"
                        name="name"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.name || ''}
                        variant="outlined"
                        required
                    />
                    <TextField
                        className={classes.inputField}
                        error={hasError('label')}
                        fullWidth
                        helperText={
                            hasError('label') ? formState.errors.label[0] : null
                        }
                        label="Label"
                        name="label"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.label || ''}
                        variant="outlined"
                        disabled={true}
                        required
                    />
                    <FormControl className={classes.textField} fullWidth variant="outlined">
                        <InputLabel id="select-label">Value</InputLabel>
                        <Select
                            labelId="select-label"
                            id="demo-simple-select-outlined"
                            name="value"
                            fullWidth
                            value={formState.values.value}
                            onChange={(event) => handleSelectChange('value', event.target.value)}
                            labelWidth={40}
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </FormControl>
                    {/*<TextField
                        className={classes.inputField}
                        error={hasError('value')}
                        fullWidth
                        helperText={
                            hasError('value') ? formState.errors.value[0] : null
                        }
                        label="Value"
                        name="value"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.value || ''}
                        variant="outlined"
                        required
                    />*/}
                </form>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button autoFocus onClick={handleSave} color="primary" disabled={!formState.isValid}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
	);
};

MobileSettingDetails.propTypes = {
	open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dialogData: PropTypes.object.isRequired,
};

export default MobileSettingDetails;