import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './DialogueStyle';
import { formatUnderscore } from '../../utils/formatter';
import { SERVER_PATH, MOCK_AUDIO_PATH } from '../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Dialogue = (props) => {
	const classes = useStyles();
	const { open, onClose, type, handleSnackToogle, dialogueData, uploadAudio, deleteAudioSegment } = props;
	const [pageTitle, setPageTitle] = useState('Add Dialogue');

	const defaultSegment = {
		name: '',
		audio_path: '',
		transcript: '',
		sample_response: '',
		sample_transcript: '',
	};
	const [audioForm, setAudioForm] = useState({
		title: '',
		description: '',
		mock_dialogue_segments: [defaultSegment],
		errors: {
			title: '',
			description: '',
		}
	});
	const maximumSegments = 20, audioAssetPath = SERVER_PATH + MOCK_AUDIO_PATH;

	useEffect(() => {
		let segments = [];
		if (dialogueData && dialogueData.mock_dialogue_segments) {
			segments = dialogueData.mock_dialogue_segments.map(segment => {
				return {
					id: segment.id,
					name: segment.name,
					transcript: segment.transcript,
					audio_path: segment.audio_path,
					sample_response: segment.sample_response,
					audioSource: audioAssetPath + segment.audio_path,
					responseSource: segment.sample_response ? audioAssetPath + segment.sample_response : null,
					sample_transcript: segment.sample_transcript
				};
			});
		} else {
			segments = [{
				name: '',
				audio_path: '',
				transcript: '',
				sample_response: '',
				sample_transcript: '',
			}]
		}

		setAudioForm((audioForm) => ({
			...audioForm,
			title					: dialogueData.title ? dialogueData.title : '',
			description				: dialogueData.description ? dialogueData.description : '',
			start_transcript		: dialogueData.start_transcript ? dialogueData.start_transcript : '',
			end_transcript			: dialogueData.end_transcript ? dialogueData.end_transcript : '',
			start_audio				: dialogueData.start_audio,
			original_start_audio	: dialogueData.start_audio,
			start_audioFile			: dialogueData.start_audio ? audioAssetPath + dialogueData.start_audio : null,
			end_audio				: dialogueData.end_audio,
			original_end_audio		: dialogueData.end_audio,
			end_audioFile			: dialogueData.end_audio ? audioAssetPath + dialogueData.end_audio : null,
			mock_dialogue_segments	: segments,
		}));
		setPageTitle((dialogueData.title) ? dialogueData.title : 'Add Dialogue');
	}, [dialogueData, audioAssetPath]);


	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = (fieldName, value) => {
		validate(fieldName, value);
		setAudioForm(audioForm => ({ ...audioForm, [fieldName]: value }));
	};

	const validate = (fieldName, value) => {
		let form = { ...audioForm };
		if (typeof value === 'string') {
			value = value.trim();
		}
		form.errors[fieldName] = (value === undefined || value === '' || value === null) ? formatUnderscore(fieldName)  + ' is required' : '';
		setAudioForm(form);
	};

	/**
	 * Handle segment field change
	 * 
	 * @param {*} event 
	 */
	const handleSegmentChange = (fieldName, index, value) => {
		// console.log(fieldName + " === " + value) ;
		if (fieldName === 'transcript') {
			validateSegment(fieldName, index, value);
		}
		let form = { ...audioForm };
		form.mock_dialogue_segments[index] = { ...form.mock_dialogue_segments[index], [fieldName]: value };
		setAudioForm(form);
	};

	const validateSegment = (fieldName, index, value) => {
		let form = { ...audioForm };
		if (typeof value === 'string') {
			value = value.trim();
		}
		if (!form.mock_dialogue_segments[index].errors) { form.mock_dialogue_segments[index].errors = []; }
		form.mock_dialogue_segments[index].errors[fieldName] = (value === undefined || value === '' || value === null) ? formatUnderscore(fieldName) + ' is required' : '';
		setAudioForm(form);
	};

	/**
	 * Check form errors
	 */
	const checkErrors = () => {
		let form = { ...audioForm }, haveError = false;
		for (const errors in form.errors) {
			if (form.errors.hasOwnProperty(errors)) {
				if (form.errors[errors]) { haveError = true; }
			}
		}
		return haveError;
	};

	/**
	 * Validate all audio segments
	 */
	const validateAllSegments = async () => {
		// console.log(audioForm.segments);
		let form = { ...audioForm }, haveError = false;
		const endAudio = form.end_audio;
		const startAudio = form.start_audio;
		if (!startAudio) {
			haveError = true;
			form.errors.start_audio = 'Start Audio is required';
		} else { form.errors.start_audio = ''; }

		if (!endAudio) {
			haveError = true;
			form.errors.end_audio = 'End Audio is required';
		} else { form.errors.end_audio = ''; }

		form.mock_dialogue_segments.forEach((segment, index) => {
			let transcript = segment.transcript, audioFile = segment.audioFile, audioPath = segment.audio_path;
			if (typeof transcript === 'string') {
				transcript = transcript.trim();
			}
			if (!form.mock_dialogue_segments[index].errors) { form.mock_dialogue_segments[index].errors = []; }
			if (transcript === '' || transcript === null) {
				haveError = true;
				form.mock_dialogue_segments[index].errors.transcript = 'Transcript is required';
			} else {
				form.mock_dialogue_segments[index].errors.transcript = '';				
			}
			if (!audioFile && !audioPath) {
				haveError = true;
				form.mock_dialogue_segments[index].errors.audioFile = 'Audio is required';
			} else {
				form.mock_dialogue_segments[index].errors.audioFile = '';				
			}
		});
		setAudioForm(form);
		return haveError;
	};

	const handleFileChange = (e, index, audioType, descriptionKey) => {
		const maxUploadSize = 10; // In MB
		const byteToMb = 1000000; //  1MB = 1000000 Bytes = 1000 * 1000 (in decimal format)
		let form = { ...audioForm };
		const file = e.target.files[0];

		if ( !file ) { return; }
		const fileSize = parseInt(file.size);

		if(fileSize > maxUploadSize * byteToMb) { // Number of MegaBytes;
			return handleSnackToogle("Image Size Shouldn't Exceed " + maxUploadSize + "MB");
		}
		
        const reader = new FileReader();
        // reader.readAsBinaryString(file);
        reader.readAsDataURL(file);
        reader.onload = () => {
			let fileName = '', sourceName = '';
			if (audioType === 'audio') {
				fileName = 'audioFile';
				sourceName = 'audioSource';
			} else {
				fileName = 'responseFile';
				sourceName = 'responseSource';
			}
			if (index !== false && !descriptionKey) {
				form.mock_dialogue_segments[index] = {
					...form.mock_dialogue_segments[index],
					[fileName]: file,
					[sourceName]: reader.result,
					errors: {
						...form.mock_dialogue_segments[index].errors,
						audioFile: '',
					}
				};
			} else if (descriptionKey) {
				form = {
					...form,
					[descriptionKey]: file,
					[`${descriptionKey}File`]: reader.result,
					errors: {
						...form.errors,
						[descriptionKey]: '',
					}
				};
			}

			setAudioForm(form);
			// console.log(`data:${file.type};base64,${btoa(reader.result)}`);
			if (type === 'edit' && index !== false && !descriptionKey && form.mock_dialogue_segments[index].id) {
				uploadAudio(file, form.mock_dialogue_segments[index], audioType, index);
			}
        };
        reader.onerror = function () {
            handleSnackToogle("Error in loading audio");
		};
		e.target.value = null;
    };

	/**
	 * Add a new audio segment
	 */
	const addSegment = (index) => {
		if (audioForm.mock_dialogue_segments.length < maximumSegments) {
			let audioSegments = audioForm.mock_dialogue_segments;
			audioSegments.splice(index + 1, 0, { ...defaultSegment });
			setAudioForm(audioForm => ({
				...audioForm,
				mock_dialogue_segments: audioSegments
			}));
		} else {
			handleSnackToogle('Maximum of ' + maximumSegments + ' audio segments are per dialogue');
		}
	};

	/**
	 * Delete audio segment
	 * 
	 * @param {*} index 
	 */
	const deleteSegment = (index) => {
		// console.log(audioForm.segments[index]);
		if (audioForm.mock_dialogue_segments.length > 1) {
			if (type === 'edit' && audioForm.mock_dialogue_segments[index].id) {
				deleteAudioSegment(audioForm.mock_dialogue_segments[index]);
			}
			let form = { ...audioForm };
			form.mock_dialogue_segments.splice(index, 1);
			setAudioForm(form);
		} else {
			handleSnackToogle('Minimum 1 audio segments is required per dialogue');
		}
	};

	const handleDialogueComplete = async () => {
		const { title, start_transcript, end_transcript } = audioForm;
		validate('title', title);
		validate('start_transcript', start_transcript);
		validate('end_transcript', end_transcript);

		const segmentsInvalid = await validateAllSegments();
		if (segmentsInvalid) {
			return handleSnackToogle("Please add required fields in audio segments.");
		}

		if (!checkErrors()) {
			onClose({}, null, audioForm);
		}
	};

	return (
		<Dialog fullScreen open={open} onClose={() => onClose()} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography color="inherit" variant="h5" className={classes.title}>
						{ pageTitle } 
					</Typography>
					<Button autoFocus disabled={checkErrors()} color="inherit" onClick={handleDialogueComplete}>
						{ type === 'add' ? 'Done' : 'Save' }
					</Button>
				</Toolbar>
			</AppBar>
			<div className={classes.root}>
				<Paper className={classes.content}>
					<form className={classes.form}>
						<TextField
							className={classes.textField}
							fullWidth
							label="Title"
							name="title"
							onChange={(event) => handleChange('title', event.target.value)}
							type="text"
							value={audioForm.title}
							variant="outlined"
							required
							error={audioForm.errors.title ? true : false}
							helperText={audioForm.errors.title}
							inputProps={{ maxLength: 255 }}
							/>
						<TextField
							className={classes.textField}
							fullWidth
							label="Description"
							name="description"
							onChange={(event) => handleChange('description', event.target.value)}
							type="text"
							value={audioForm.description || ''}
							variant="outlined"
							multiline
							rows={3}
							inputProps={{ maxLength: 499 }}
						/>

						{/* START DESCRIPTION */}
						<TextField
							className={classes.textField}
							fullWidth
							label="Start Audio Transcript"
							name="start_transcript"
							onChange={(event) => handleChange('start_transcript', event.target.value)}
							type="text"
							value={audioForm.start_transcript || ''}
							variant="outlined"
							required
							error={audioForm.errors.start_transcript ? true : false}
							helperText={audioForm.errors.start_transcript}
							inputProps={{ maxLength: 255 }}
						/>
						<div className={classes.audio} >
							<input
								accept="audio/*"
								className={classes.input}
								id={"button-audio-file-start"}
								type="file"
								multiple={false}
								onChange={(event) => handleFileChange(event, false, 'description', 'start_audio')}
							/>
							<label htmlFor={"button-audio-file-start"}>
								<Button variant="outlined" color="primary" size="large" className={classes.uploadButton} component="span" startIcon={<CloudUploadIcon />}>
									{ type === 'add' ? 'Add Start Audio' : 'Upload Start Audio'}
								</Button>
							</label>
							{ audioForm.start_audioFile ? (
								<audio controls src={audioForm.start_audioFile} controlsList="nodownload" className={classes.audioComponent} />
							) : null }
							{audioForm.errors && audioForm.errors.start_audio ? (
								<Typography className={classes.emptyAudioError} variant="caption">Please upload audio file</Typography>
							) : null}
						</div>
						{/* START DESCRIPTION */}
						{/* END DESCRIPTION */}
						<TextField
							className={classes.textField}
							fullWidth
							label="End Audio Transcript"
							name="end_transcript"
							onChange={(event) => handleChange('end_transcript', event.target.value)}
							type="text"
							value={audioForm.end_transcript || ''}
							variant="outlined"
							required
							error={audioForm.errors.end_transcript ? true : false}
							helperText={audioForm.errors.end_transcript}
							inputProps={{ maxLength: 255 }}
						/>
						<div className={classes.audio} >
							<input
								accept="audio/*"
								className={classes.input}
								id={"button-audio-file-end"}
								type="file"
								multiple={false}
								onChange={(event) => handleFileChange(event, false, 'description', 'end_audio')}
							/>
							<label htmlFor={"button-audio-file-end"}>
								<Button variant="outlined" color="primary" size="large" className={classes.uploadButton} component="span" startIcon={<CloudUploadIcon />}>
									{ type === 'add' ? 'Add End Audio' : 'Upload End Audio'}
								</Button>
							</label>
							{ audioForm.end_audioFile ? (
								<audio controls src={audioForm.end_audioFile} controlsList="nodownload" className={classes.audioComponent} />
							) : null }
							{audioForm.errors && audioForm.errors.end_audio ? (
								<Typography className={classes.emptyAudioError} variant="caption">Please upload audio file</Typography>
							) : null}
						</div>
						{/* END DESCRIPTION */}
						
						{audioForm.mock_dialogue_segments.map((audioSegment, index) => (
							<div className={classes.segmentContainer} key={index}>
								<div className={classes.segment}>
									<TextField
										fullWidth
										label="Name (Recommended)"
										name={"name_" + index}
										onChange={(event) => handleSegmentChange('name', index, event.target.value)}
										type="text"
										value={audioSegment.name}
										variant="outlined"
										inputProps={{ maxLength: 255 }}
									/>

									<div className={classes.audio} >
										<input
											accept="audio/*"
											className={classes.input}
											id={"button-audio-file-" + index}
											type="file"
											multiple={false}
											onChange={(event) => handleFileChange(event, index, 'audio')}
										/>
										<label htmlFor={"button-audio-file-" + index}>
											<Button variant="outlined" color="primary" size="large" className={classes.uploadButton} component="span" startIcon={<CloudUploadIcon />}>
												{ type === 'add' ? 'Add Audio' : 'Upload Audio'}
											</Button>
										</label>
										{ audioSegment.audioSource ? (
											<audio controls src={audioSegment.audioSource} controlsList="nodownload" className={classes.audioComponent} />
										) : null }
										{/* <audio controls>
											<source src="horse.ogg" type="audio/ogg">
											<source src="horse.mp3" type="audio/mpeg">
											Your browser does not support the audio element.
										</audio> */}
										{audioSegment.errors && audioSegment.errors.audioFile ? (
											<Typography className={classes.emptyAudioError} variant="caption">Please upload audio file</Typography>
										) : null}
									</div>
									<TextField
										className={classes.textField}
										fullWidth
										label="Transcript"
										name={"transcript_" + index}
										onChange={(event) => handleSegmentChange('transcript', index, event.target.value)}
										type="text"
										value={audioSegment.transcript || ''}
										variant="outlined"
										multiline={true}
										rows={3}
										required
										error={audioSegment.errors && audioSegment.errors.transcript ? true : false}
										helperText={audioSegment.errors && audioSegment.errors.transcript}
									/>
									<div className={classes.audio} >
										<input
											accept="audio/*"
											className={classes.input}
											id={"button-response-file-" + index}
											multiple={false}
											type="file"
											onChange={(event) => handleFileChange(event, index, 'sample_response')}
										/>
										<label htmlFor={"button-response-file-" + index}>
											<Button
												variant="outlined"
												color="primary"
												size="large"
												className={classes.uploadButton}
												component="span"
												startIcon={<CloudUploadIcon />}
											>
												{ type === 'add' ? 'Add Sample Response' : 'Upload Sample Response'}
											</Button>
										</label>
										{ audioSegment.responseSource ? (
											<audio controls src={audioSegment.responseSource} controlsList="nodownload" className={classes.audioComponent} />
										) : null }									
									</div>
									<TextField
										className={classes.textField}
										fullWidth
										label="Sample Response Transcript"
										name={"sample_transcript_" + index}
										onChange={(event) => handleSegmentChange('sample_transcript', index, event.target.value)}
										type="text"
										value={audioSegment.sample_transcript || ''}
										variant="outlined"
										multiline={true}
										rows={3}
									/>
								</div>
								<div className={classes.actionBtns}>
									<IconButton aria-label="delete" onClick={() => deleteSegment(index)}>
										<DeleteIcon />
									</IconButton>
									<Tooltip title="Add an audio segment after this one">
										<IconButton color="primary" aria-label="add" onClick={() => addSegment(index)}>
											<AddIcon />
										</IconButton>
									</Tooltip>
								</div>
							</div>
						))}
					</form>
				</Paper>
			</div>
		</Dialog>
	);
};

Dialogue.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	handleSnackToogle: PropTypes.func.isRequired,
	dialogueData: PropTypes.object.isRequired,
	uploadAudio: PropTypes.func.isRequired,
	deleteAudioSegment: PropTypes.func.isRequired,
};

export default Dialogue;