import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';

import TranslateIcon from '@material-ui/icons/Translate';

import useStyles from './VocabSavedStyle';
import useCommonStyles from '../../common/style';
import useDebounce from '../../hooks/useDebounce';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import API from '../../axios/axiosApi';
import VocabDetails from '../VocabDetailsDialog';
import { VOCAB_AUDIO_SERVER_PATH, VOCAB_AUDIO_PATH } from '../../config';

const headCells = [
	{ key: 'vocab.word', numeric: false, disablePadding: false, label: 'Word', sortable: true },
	{ key: 'vocab.translation', numeric: false, disablePadding: false, label: 'Translation', sortable: true },
	{ key: 'language.id', numeric: false, disablePadding: false, label: 'Language', sortable: false },
	{ key: 'vocab.vocabulary_category', numeric: false, disablePadding: false, label: 'Category', sortable: false },
	{ key: 'user.id', numeric: false, disablePadding: false, label: 'User', sortable: false },
	{ key: 'audio', numeric: false, disablePadding: false, label: 'Voice', sortable: false },
	//{ key: 'action', numeric: false, disablePadding: false, label: 'Action', sortable: false },
];

const VocabSaved = (props) => {
	const { match: { params } } = props;
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	//const languageName = state.languageName || '';
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [records, setRecords] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const debouncedSearch = useDebounce(search, 500);

	const [dialogData, setDialogData] = useState({});
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('vocab.word');
	const [selected, setSelected] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [refreshRecords, setRefreshRecords] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const vocabAudioAssetPath = VOCAB_AUDIO_SERVER_PATH + VOCAB_AUDIO_PATH;


	useEffect(() => {
		const fetchSavedVocab = async () => {
			setLoading(true);
			try {
				let postData = {
					order,
					orderBy,
					pageOffset: page * pageSize,
					pageSize,
					searchText: debouncedSearch,
				};
				const response = await API.post('saved_vocab', postData);
				console.log("Saved vocab data ==> ",response.data.data);
				if (response.data.success) {
					setRecords(response.data.data.rows);
					setTotalRecords(response.data.data.count);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchVocab : ", error);
				setLoading(false);
			}
		};
		fetchSavedVocab();
	}, [params, debouncedSearch, page, pageSize, order, orderBy, refreshRecords]);

	/**
	 * Handle on search change
	 * 
	 * @param {*} event 
	 */
	const handleSearch = (event) => {
		setSearch(event.target.value)
	};

	/**
	 * Handle sorting change
	 * 
	 * @param {*} event 
	 * @param {*} property 
	 */
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
		setSelected([]);
	};

	/**
	 * Toogle select all
	 * 
	 * @param {*} event 
	 */
	const handleSelectAll = event => {
		let newSelected = [];
		if (event.target.checked) {
			newSelected = records.map((item, index) => index);
		}
		setSelected(newSelected);
	};

	/**
	 * Toogle checkbox selection
	 * 
	 * @param {*} event 
	 * @param {*} index 
	 */
	const handleCheck = (event, index) => {
		const selectedIndex = selected.indexOf(index);
		let newSelected = [ ...selected];

		if (event.target.checked && selectedIndex === -1) {
			newSelected = [ ...newSelected, index ];

		} else if (selectedIndex > -1) {
			newSelected.splice(selectedIndex, 1);
		}
		// console.log("newSelected : ",newSelected);
		setSelected(newSelected);
	};

	/**
	 * Handle page change and refeacth data
	 * 
	 * @param {*} event 
	 * @param {*} newPage 
	 */
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setSelected([]);
	};

	/**
	 * On change page size
	 * 
	 * @param {*} event 
	 */
	const handleChangeRowsPerPage = event => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
		setSelected([]);
	};

	const handleDelete = async () => {
		if (selected.length > 0) {
			let deleteIds = selected.map(item => records[item].id);
			try {
				setLoading(true);
				const response = await API.post('delete_saved_vocab', { deleteIds });
				//console.log("response handle saved vocab delete ==> ",response.data);
				handleSnackToogle(response.data.message)
				if (response.data.success) {
					setSelected([]);
					setRefreshRecords(refreshRecords => (!refreshRecords));
				}
			setLoading(false);
			} catch (error) {
				console.log("ERROR in handleDelete : ", error);
				setLoading(false);
			}
		}
	};

	const handleClose = (data) => {
		setOpenDialog(false);
		setDialogData({});
		if (data) {
			saveVocab(data);
		}
	};

	/**
	 * Save language
	 * 
	 * @param {*} data 
	 */
	const saveVocab = async (data) => {
		setLoading(true);
		//console.log("data ==> ",data);
		try {
			let postData = { ...data, type: dialogData.type, languageId: params.langId };
			if (dialogData.type === 'edit') {
				postData = { ...postData, id: dialogData.id };
			}
			const response = await API.post('save_vocab', postData);
			if (response.data.success) {
				// console.log("response asdsadsadsa ==> ",response.data);
				handleSnackToogle(response.data.message)
				setRefreshRecords(refreshRecords => (!refreshRecords));
			} else {
				console.log("response ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in saveLanguage : ", error);
			setLoading(false);
		}
	};

	const openDetailDialog = (index) => {
		if (records[index]) {
			setDialogData({ type: 'edit', ...records[index] });
			setOpenDialog(true);
		}
	}

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	const openUserDetailPage = (userObj) => {
		let userId = (userObj)?userObj.id:'';
		if (userId)
			props.history.push('/users/' + userId);
	}
	// const emptyRows = rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);
	const emptyRows = pageSize - records.length;

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<TranslateIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">Saved Vocabulary</Typography>
					</div>
				</div>
			</div>
			<div className={classes.content}>
				<Paper className={clsx(classes.paper, commonClasses.paperContainer)}>
					{ loading ? <LinearProgress className={commonClasses.progressBar} /> : null }
					<EnhancedTableToolbar
						numSelected={selected.length}
						onSearchChange={handleSearch}
						handleDelete={handleDelete}
						searchComment="Search Saved Vocabulary"
					/>
					<TableContainer>
						<Table className={classes.table} size='medium'>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAll={handleSelectAll}
								onRequestSort={handleRequestSort}
								rowCount={records.length}
								headCells={headCells}
							/>
							<TableBody>
							{ records.length === 0 ? (
								<TableRow>
									<TableCell colSpan={headCells.length}>No records found</TableCell>
								</TableRow> ): null }
							{ records.map((row, index) => {
								// const labelId = `enhanced-table-checkbox-${index}`;
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={selected.includes(index)}
										key={index}
										selected={selected.includes(index)}
										className={classes.rowHover}
									>
										<TableCell padding="checkbox" onClick={() => openDetailDialog(index)}>
											<Checkbox
												checked={selected.includes(index)}
												onClick={event => event.stopPropagation()}
												onChange={event => handleCheck(event, index)}
											/>
										</TableCell>
										{/* <TableCell component="th" id={labelId} scope="row" padding="none">
											{row.word}
										</TableCell> */}
										<TableCell >{row.vocab.word}</TableCell>
										<TableCell >{row.vocab.translation}</TableCell>
										<TableCell onClick={() => props.history.push('manage/languages')} className={classes.underline} >{row.vocab.language ? row.vocab.language.name : '-'}</TableCell>
										<TableCell onClick={() => props.history.push('manage/vocabulary-categories')} className={classes.underline}>{row.vocab.vocabulary_category ? row.vocab.vocabulary_category.name : '-'}</TableCell>
										<TableCell onClick={() => openUserDetailPage(row.user)} className={classes.underline}>{row.user ? row.user.first_name + " " + row.user.last_name : '-'}</TableCell>
										<TableCell>
											<audio controls src={vocabAudioAssetPath +row.vocab.id+".mp3"} controlsList="nodownload" className={classes.audioComponent} />
										</TableCell>
									</TableRow>
								);
							}) }
							{emptyRows > 0 && (
								<TableRow style={{ height:  53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 100]}
						component="div"
						count={totalRecords}
						rowsPerPage={pageSize}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>

			<VocabDetails
				open={openDialog}
				onClose={handleClose}
				dialogData={dialogData}
			/>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>
		</div>
	);
};

export default VocabSaved;