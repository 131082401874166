import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		justifyContent: 'center',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	root: {
		padding: theme.spacing(3),
		position: 'relative',
	},
	content: {
		width: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	form: {
		width: '100%',
		paddingTop: theme.spacing(2),
		padding: theme.spacing(4),
	},
	audioComponent: {
		outline: 'none'
	},
	segment: {
		borderRadius: theme.spacing(1),
		border: '1px gray solid',
		padding: theme.spacing(3),
		paddingTop: theme.spacing(1),
		marginTop: theme.spacing(4),
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	emptyAudioError: {
		color: theme.palette.error.main
	},
	fieldTitle: {
		marginTop: theme.spacing(2),
	}
}));