import history from "../utils/history";
import * as jwt_decode from 'jwt-decode';
import API from '../axios/axiosApi';

const TOKEN_NAME = 'access_token';

const getAuth = () => {
    return (localStorage.getItem('access_token')) ? true : false;
};

const logout = () => {
    localStorage.clear();
    history.push("/login");
}

const getToken = (storageName) => {
    return (localStorage.getItem(storageName) !== undefined) ? localStorage.getItem(storageName) : false;
}

const getTokenExpirationDate = (token) => {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) return null;

    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);
    return date;
}

const isTokenExpired = (token) => { 
    if(!token) token = this.getToken(TOKEN_NAME);
    if(!token) return true;

    const date = this.getTokenExpirationDate(token);
    // console.log("date : ",date);
    if(date === undefined) return true;
    return !(date.valueOf() > new Date().valueOf());
}

const checkToken = () => {
    let token = getToken(TOKEN_NAME);
    if (!token) { return false; }
    let userData = getToken('user_data');
    if (!userData || userData === undefined) { return false; }
    userData = JSON.parse(userData);
    let date = getTokenExpirationDate(token);
    let current = new Date();

    if (date.valueOf() < current.valueOf()) {
        console.log("expired");
        logout();
        return false;
    }

    if (current.valueOf() >= date.valueOf() - (60 * 60000) ) {
        console.log("Time to refresh token");
        refreshToken(token, userData);
        return false;
    } else {
        // console.log("Token is valid for more than 2 min");
        return true;
    }
}

const refreshToken = async (token, userData) => {
    try {
        const data = { token, userData };
        await API.post('refresh_token', data);
        // console.log("response ==> ",response);
    } catch (error) {
        console.log("ERROR in refreshToken : ", error);
    }
}

export default { getAuth, logout, checkToken, isTokenExpired };