import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
// import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
// import Divider from '@material-ui/core/Divider';
// import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import DeleteIcon from '@material-ui/icons/Delete';
import AssessmentIcon from '@material-ui/icons/Assessment';

import useStyles from './RecentResultStyle';
import useCommonStyles from '../../common/style';
import API from '../../axios/axiosApi';
import { SERVER_PATH, RESULT_PATH } from '../../config';
import UploadingProgress from '../../components/UploadingProgress';

const RecentResults = (props) => {
	const { match: { params }, history } = props;
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);

	const [snack, setSnack] = useState({ open: false, message: '' });
	const [resultImgs, setResultImgs] = useState([]);
	const [progress, setProgress] = useState(0);
	const [openProgressDialogue, setOpenProgressDialogue] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const imageAssetPath = SERVER_PATH + RESULT_PATH;

	useEffect(() => {
		const fetchRecentResults = async () => {
			setLoading(true);
			try {
				const response = await API.get('recent_results');
				if (response.data.success) {
					const recentResults = (response.data.data.recent_results) ? response.data.data.recent_results : [];
					const images = recentResults.map(image => {
						return { ...image, imgSource: imageAssetPath + image.path }
					});
					setResultImgs(images);
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchRecentResults : ", error);
				setLoading(false);
			}
		};
		fetchRecentResults();
	}, [params, history, imageAssetPath, refresh]);


	/**
	 * Save feedback
	 */
	const uploadImage = async (file) => {
		const formData = new FormData();
		if (file instanceof File) { formData.append(`image`, file, file.name); }
		
		// formData.append('data', JSON.stringify(data));
		try {
			setLoading(true);
			setOpenProgressDialogue(true);
			const response = await API.post('recent_results', formData, {
				headers: {'Content-Type': 'multipart/form-data'},
				onUploadProgress: (progressEvent) => {
					const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
					setProgress(progressPercentage);
				},
			});
			setOpenProgressDialogue(false);
			setProgress(0);
			if (response.data.success) {
				// console.log("response.data ==> ",response.data);
				handleSnackToogle(response.data.message);
				setRefresh(refresh => !refresh);
			} else {
				console.log("response saveFeedback ==> ",response.data);
				handleSnackToogle(response.data.message)
			}
			setLoading(false);
		} catch (error) {
			setOpenProgressDialogue(false);
			console.log("ERROR in saveFeedback : ", error.response);
			setLoading(false);
			let msg = 'Something went wrong. Please try again later.';
			if (error.response && error.response.data &&  error.response.data.error_message) {
				msg = error.response.data.error_message;
			} else if (error.response && error.response.data &&  error.response.data.message) {
				msg = error.response.data.message;
			} else if (error.message) {
				msg = error.message;
			}
			handleSnackToogle(msg);
			setProgress(0);
		}
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	/**
	 * Handle file change
	 * 
	 * @param {*} e 
	 */
	const handleFileChange = (e) => {
		const maxUploadSize = 10; // In MB
		const byteToMb = 1000000; //  1MB = 1000000 Bytes = 1000 * 1000 (in decimal format)
		const file = e.target.files[0];
		const fileSize = parseInt(file.size);

		const fileType = file.type;
		const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

		if (!validImageTypes.includes(fileType)) {
			return handleSnackToogle("Invalid image format");
		}

		if (fileSize > maxUploadSize * byteToMb) { // Number of MegaBytes;
			e.target.value = null;
			return this.setState({ fileErrorMessage: "Image size shouldn't exceed " + maxUploadSize + " MB" });
		}
		
		const reader = new FileReader();
		// reader.readAsBinaryString(file);
		reader.readAsDataURL(file);
		reader.onload = () => {
			setResultImgs(resultImgs => [ ...resultImgs, reader.result]);
			uploadImage(file);
			// setFeedbackForm(feedbackForm => ({ ...feedbackForm, feedbackImages: [ ...feedbackForm.feedbackImages, file] }));
			// console.log(reader.result);
			// console.log(`data:${file.type};base64,${btoa(reader.result)}`);
		};
		reader.onerror = (error) => {
			console.log(error);
		};
		e.target.value = null;
		

		// uploadProfileImage(file);
		
       /*  const reader = new FileReader();
        // reader.readAsBinaryString(file);
        reader.readAsDataURL(file);
        reader.onload = () => {
			setAudioSource(reader.result)
			// console.log(`data:${file.type};base64,${btoa(reader.result)}`);
			if (type === 'edit') {
				uploadAudio(file);
			}
        };
        reader.onerror = function () {
            handleSnackToogle("Error in loading audio");
		}; */
	};

	const removeImage = (index) => {
		// let feedBackFormImages = [ ...feedbackForm.feedbackImages ];
		let feedbackImgArray = [ ...resultImgs ];
		if (feedbackImgArray[index].id) {
			deleteResultImage(feedbackImgArray[index]);
		}

		feedbackImgArray.splice(index, 1);
		// feedBackFormImages.splice(index, 1);
		setResultImgs(feedbackImgArray);
		// setFeedbackForm(feedbackForm => ({ ...feedbackForm, feedbackImages: feedBackFormImages }));
	};

	const deleteResultImage = async (imageDetails) => {
		try {
			setLoading(true);
			const response = await API.delete('recent_results/' + imageDetails.id + '/' + imageDetails.path);
			if (response.data.success) {
				handleSnackToogle(response.data.message);
			}
			setLoading(false);
		} catch (error) {
			console.log("ERROR in deleteResultImage : ", error);
			setLoading(false);
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<IconButton size="small" aria-label="go-back" onClick={() => props.history.goBack() }>
							<ArrowBackIcon />
						</IconButton>
						<AssessmentIcon fontSize="large" className={classes.titleIcon} />						
						<Typography variant="h4" className={classes.profileName}>Recent Results</Typography>
					</div>
					{/* <span className={classes.spacer} /> */}
					<input
						accept="image/*"
						className={classes.input}
						id={"feedback-images"}
						type="file"
						multiple={false}
						onChange={(event) => handleFileChange(event, false, 'description', 'start_audio')}
					/>
					<label htmlFor={"feedback-images"}>
						<Button variant="contained" color="primary" size="large" component="span" startIcon={<AddPhotoAlternateIcon />}>
							Add Recent Results
						</Button>
					</label>
				</div>
			</div>
			<Card className={clsx(classes.content, commonClasses.paperContainer)}>
				{ loading ? <LinearProgress className={commonClasses.progressBar}/> : null }
				{/* <CardHeader title="Response Details"/>
				<Divider /> */}
				<CardContent className={classes.cardContentStyle}>
					<div className={classes.images} >
						<div className={classes.imgsContainer}>
							{resultImgs.map((image, index) => (
								<div className={classes.imgBox} key={index} >
									<img src={image.imgSource || image} alt={index} className={classes.img} />
									<IconButton aria-label="delete" onClick={() => removeImage(index)} className={classes.deleteIcon}>
										<DeleteIcon />
									</IconButton>
								</div>
							))}							
						</div>
						{resultImgs.length === 0 ? (
							<Typography variant="h5" className={classes.notFound}>No result images added yet!</Typography>
						) : null}
					</div>
				</CardContent>
				{/* <CardActions className={classes.actions}>
					<Button
						color="primary"
						variant="contained"
						onClick={saveFeedback}
						disabled={feedbackForm.errors.feedback ? true : false}
					>
						Save Feedback
					</Button>
				</CardActions> */}
			</Card>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={2000}
			/>
			<UploadingProgress
				open={openProgressDialogue}
				// onClose={progressOpen}
				progressPercentage={progress}
			/>
		</div>
	);
};

export default RecentResults;