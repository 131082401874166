import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';

import AccessTimeIcon from '@material-ui/icons/AccessTime';

import useStyles from './UserMockResponseStyle';
import useCommonStyles from '../../common/style';
import useDebounce from '../../hooks/useDebounce';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import API from '../../axios/axiosApi';
import { formatDate } from '../../utils/formatter';

const headCells = [
	{ key: 'user', numeric: false, disablePadding: false, label: 'User', sortable: true },
	{ key: 'title', numeric: false, disablePadding: false, label: 'Mock Test Title', sortable: true },
	{ key: 'language', numeric: false, disablePadding: false, label: 'Language', sortable: true },
	{ key: 'feedback', numeric: false, disablePadding: false, label: 'Feedback Given', sortable: true },
	{ key: 'createdAt', numeric: false, disablePadding: false, label: 'Submitted At', sortable: true },
];

const UserMockResponses = (props) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');
	const [records, setRecords] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const debouncedSearch = useDebounce(search, 500);

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('createdAt');
	const [selected, setSelected] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [snack, setSnack] = useState({ open: false, message: '' });


	useEffect(() => {
		const fetchMockTest = async () => {
			setLoading(true);
			try {
				let postData = {
					order,
					orderBy,
					pageOffset: page * pageSize,
					pageSize,
					searchText: debouncedSearch,
				};
				const response = await API.post('mock_test_reponses', postData);
				if (response.data.success) {
					// console.log("videos data ==> ",response.data.data);
					setRecords(response.data.data.rows);
					setTotalRecords(response.data.data.count);
				} else {
					console.log("response ==> ",response.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchMockTest : ", error);
				setLoading(false);
			}
		};
		fetchMockTest();
	}, [debouncedSearch, page, pageSize, order, orderBy]);

	/**
	 * Handle on search change
	 * 
	 * @param {*} event 
	 */
	const handleSearch = (event) => {
		setSearch(event.target.value)
	};

	/**
	 * Handle sorting change
	 * 
	 * @param {*} event 
	 * @param {*} property 
	 */
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
		setSelected([]);
	};

	/**
	 * Handle page change and refeacth data
	 * 
	 * @param {*} event 
	 * @param {*} newPage 
	 */
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setSelected([]);
	};

	/**
	 * On change page size
	 * 
	 * @param {*} event 
	 */
	const handleChangeRowsPerPage = event => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
		setSelected([]);
	};

	const openDetailPage = (index) => {
		// console.log("records[index]: ",records[index]);
		if (records[index]) {
			props.history.push({ pathname: '/mock-responses/' + records[index].id });
		}
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	// const emptyRows = rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);
	const emptyRows = pageSize - records.length;

	return (
		<div className={classes.root}>
			<div className={classes.pageHeader} >
				<div className={classes.row}>
					<div className={classes.pageTitle}>
						<AccessTimeIcon fontSize="large" className={classes.titleIcon} />
						<Typography variant="h4">Mock Test Responses</Typography>
					</div>
				</div>
			</div>
			<div className={classes.content}>
				<Paper className={clsx(classes.paper, commonClasses.paperContainer)}>
					{ loading ? <LinearProgress className={commonClasses.progressBar} /> : null }
					<EnhancedTableToolbar
						numSelected={selected.length}
						onSearchChange={handleSearch}
						handleDelete={() => {}}
						searchComment="Search Mock Test"
					/>
					<TableContainer>
						<Table className={classes.table} size='medium'>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAll={() => {}}
								onRequestSort={handleRequestSort}
								rowCount={records.length}
								headCells={headCells}
								haveMultiSelect={false}
							/>
							<TableBody>
							{ records.length === 0 ? (
								<TableRow>
									<TableCell colSpan={headCells.length}>No records found</TableCell>
								</TableRow> ): null }
							{ records.map((row, index) => {
								// const labelId = `enhanced-table-checkbox-${index}`;
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={selected.includes(index)}
										key={index}
										selected={selected.includes(index)}
										onClick={() => openDetailPage(index)}
										className={classes.rowHover}
									>
										{/* <TableCell component="th" id={labelId} scope="row" padding="none">
											{row.word}
										</TableCell> */}
										<TableCell>{row.user.first_name + " " + row.user.last_name}</TableCell>
										<TableCell>{row.title}</TableCell>
										<TableCell>{row.language.name}</TableCell>
										<TableCell>
											<span className={clsx({
												[classes.feedback]: true,
												[classes.feedbackGiven]: row.feedback
											})}>{row.feedback ? 'Yes' : 'No'}</span>
										</TableCell>
										<TableCell>{formatDate(row.createdAt)}</TableCell>
									</TableRow>
								);
							}) }
							{emptyRows > 0 && (
								<TableRow style={{ height:  53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 100]}
						component="div"
						count={totalRecords}
						rowsPerPage={pageSize}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>
		</div>
	);
};

export default UserMockResponses;